import React, {useState, useEffect, Suspense} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "./layouts/components/Sidenav";
import Configurator from "./layouts/components/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import {useSoftUIController, setMiniSidenav} from "context";

import enMessages from "assets/i18n/devextreme/en.json";
import trMessages from "assets/i18n/devextreme/tr.json";

import {locale, loadMessages} from "devextreme/localization";

// Images
import brand from "assets/images/logo.png";

// css
import "react-perfect-scrollbar/dist/css/styles.css";

import {filterTreeList} from "./providers/FilterProvider";
import themes from "devextreme/ui/themes";
import AuthProvider from "./providers/AuthProvider";
import {getLangLocalStorage, setLangLocalStorage} from "./providers/LanguageProvider";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import LangService from "./pages/share/langs/lang/LangService";
import DataGrid from "devextreme/ui/data_grid";
import TreeList from "devextreme/ui/tree_list";

function Page() {
    const {i18n} = useTranslation();
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, direction, layout, sidenavColor} = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const authProvider = AuthProvider();
    const langService = LangService();
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        // console.log("useEffect direction");
        document.body.setAttribute("dir", direction);
        getLangLocalStorage().then((lng) => {
            setLocale(lng);
        });
    }, [direction]);

    i18n.on("languageChanged", (lng) => {
        setLocale(lng);
    });

    useEffect(() => {
        // console.log("useEffect []");
        themes.current("material.blue.light");
        setLangLocalStorage();
        initMessages();
        langService.findAll({}).then((r) => {
            localStorage.setItem("langs", JSON.stringify(r.items));
        });

        devextremeGlobalOptions();
    }, []);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        if (
            authProvider.getAuthData() == null &&
            !(pathname === "/authentication/login" || pathname === "/")
        ) {
            navigate("/");
        }
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });

    const getSideRoutes = (allRoutes) => {
        return filterTreeList(allRoutes);
    };

    const initMessages = () => {
        loadMessages(trMessages);
        loadMessages(enMessages);
    };

    const setLocale = (lng) => {
        locale(lng);
    };
    const devextremeGlobalOptions = () => {
        // console.log("devextremeGlobalOptions");
        DataGrid.defaultOptions({
            options: {
                showRowLines: true,
                rowAlternationEnabled: true,
                bindingOptions: {},
                onRowUpdating: (e) => {
                    if (e.oldData.xmin) {
                        e.newData.xmin = e.oldData.xmin;
                    }
                },
                onEditorPreparing(e) {
                    if (e.editorName === "dxDateBox") {
                        e.editorOptions.openOnFieldClick = true;
                    }
                },
                onInitialized(e) {
                    e.component.option("syncLookupFilterValues", false);
                },
            },
        });

        TreeList.defaultOptions({
            options: {
                onInitialized(e) {
                    e.component.option("syncLookupFilterValues", false);
                },
            },
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Helmet
                htmlAttributes={{
                    lang: i18n.language,
                }}
            />
            <CssBaseline />
            {layout === "dashboard" && authProvider.getAuthData() !== null && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={brand}
                        routes={getSideRoutes(routes)}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                    <Configurator />
                </>
            )}
            <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/authentication/login" />} />
            </Routes>
        </ThemeProvider>
    );
}

const Loader = () => (
    <div className="divLoader">
        <svg className="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
            <path
                ng-attr-d="{{config.pathCmd}}"
                ng-attr-fill="{{config.color}}"
                stroke="none"
                d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
                fill="#51CACC"
                transform="rotate(179.719 50 51)">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 51;360 50 51"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"></animateTransform>
            </path>
        </svg>
    </div>
    // <div className="App">
    //   <div>loading language files...</div>
    // </div>
);

export default function App() {
    return (
        <Suspense fallback={<Loader />}>
            <Page />
        </Suspense>
    );
}
