import React, {useCallback, useEffect, useReducer, useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Editing,
    FilterRow,
    Form,
    Item,
    Lookup,
    Pager,
    Paging,
    RequiredRule,
    Toolbar,
} from "devextreme-react/data-grid";

import DeviceService from "./DeviceService";
import {useTranslation} from "react-i18next";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import ProcessorService from "../processor/ProcessorService";
import ProductService from "../product/ProductService";
import {GroupItem, Tab, TabbedItem, TabPanelOptions} from "devextreme-react/form";
import reducer from "./reducer.js";
import {setChanges} from "./actions.js";
import Button from "devextreme-react/button";
import ArrayStore from "devextreme/data/array_store";
import notify from "devextreme/ui/notify";
import AuthProvider from "../../../providers/AuthProvider";

const deviceService = DeviceService();
const processorService = ProcessorService();
const productService = ProductService();
const authProvider = AuthProvider();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return deviceService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return deviceService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            values.personType = "Person";
            return deviceService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            console.log("key=" + key, "value=" + values);
            values.id = key;
            return deviceService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return deviceService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

const processorDataSource = new CustomStore({
    key: "id",

    load(loadOptions: any) {
        return processorService.findAll(loadOptions).then(
            (data) => ({
                data: data.items,
                totalCount: data.totalCount,
            }),
            (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            }
        );
    },
    byKey: (key: any) => {
        return processorService.findOne(key).then((response) => {
            return response;
        });
    },
});

const productDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
        return productService.findAll(loadOptions).then(
            (response) => ({
                data: response.items,
                totalCount: response.totalCount,
            }),
            (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            }
        );
    },
    byKey: (key: any) => {
        return productService.findOne(key).then((response) => {
            return response;
        });
    },
});

const createAntennaDataSource = (processorId) => {
    //console.log(processorId);
    return new CustomStore({
        key: "id",
        load(loadOptions: any) {
            if (!processorId) {
                return null;
            }
            loadOptions.filter = ["processor.id", "=", processorId ? processorId : null];
            return processorService.findAllAntenna(loadOptions).then(
                (data) => ({
                    data: data,
                    totalCount: data.length,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return processorService.findOneAntenna(key).then((response) => {
                return response;
            });
        },
    });
};

function DevicePage() {
    const {t} = useTranslation();

    const [dataGrid, setDataGrid] = useState();
    const [processorId, setProcessorId] = useState();
    const [antennaDataSource, setAntennaDataSource] = useState();
    const [currentProductId, setCurrentProductId] = useState();
    // const [dispatch] = useReducer(reducer, initialState);

    const onChangesChange = useCallback((changes) => {
        if (changes?.length > 0 && changes[0].data?.product !== undefined) {
            setCurrentProductId(changes[0].data.product.id);
        }
        //setChanges(dispatch, changes);
    }, []);

    const readerDataSource = new CustomStore({
        key: "id",
        load(loadOptions: any) {
            if (!processorId) {
                return null;
            }
            loadOptions.filter = ["processor.id", "=", processorId ? processorId : null];
            return processorService.findAllReader(loadOptions).then(
                (data) => ({
                    data: data,
                    totalCount: data.length,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return processorService.findOneReader(key).then((response) => {
                return response;
            });
        },
    });

    useEffect(() => {
        console.log(processorId);
        setAntennaDataSource(createAntennaDataSource(processorId));
    }, [processorId]);

    const onAntennaUpdated = (d, val) => {
        if (!d.value) {
            d.setValue([val.data]);
        } else {
            d.setValue(d.value);
        }
    };
    const onAntennaInserted = (d, val) => {
        if (!editing) {
            if (!d.value) {
                d.setValue([val.data]);
            } else {
                const array = d.value.filter((item) => item.antenna.id === val.data.antenna.id);
                if (array.length !== 0) {
                    d.setValue(d.value);
                } else {
                    d.setValue([...d.value, val.data]);
                }
            }
        } else {
            d.setValue(d.value);
        }
    };
    let editing = false;
    const onInitNewRow = (value) => {
        editing = value;
    };
    const onAntennaDeleted = (d, val) => {
        const updatedList = d.value.filter((item) => item.antenna.id !== val.data.antenna.id);
        d.setValue(updatedList);
    };
    const editGridDeviceAntennaCell = (d) => {
        // const value = d.value ? d.value : [];
        const value = new DataSource({
            store: new ArrayStore({
                data: d.value ? d.value : [],
                key: "antenna.id",
                errorHandler: (err) => {
                    if (err.__id === "E4008") {
                        err.message = t("antenna.already.has.been");
                        err.url = "";
                        return err;
                    }
                },
            }),
        });
        return (
            <DataGrid
                id="gridContainer"
                dataSource={value}
                onInitNewRow={() => onInitNewRow(false)}
                onEditingStart={() => onInitNewRow(true)}
                onEditCanceled={() => () => onInitNewRow(false)}
                onRowInserting={(val) => {
                    onAntennaInserted(d, val);
                }}
                onRowUpdated={(val) => {
                    onAntennaUpdated(d, val);
                }}
                onRowRemoved={(val) => {
                    onAntennaDeleted(d, val);
                }}>
                <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true} />
                <Column dataField="antenna.id" dataType="number">
                    <Lookup dataSource={antennaDataSource} displayExpr="name" valueExpr="id" />
                    <RequiredRule />
                </Column>
                <Column
                    caption={t("{{key, capitalize}}", {key: "min.rssi"})}
                    dataField="minRssi"
                    dataType="number">
                    <RequiredRule />
                </Column>
            </DataGrid>
        );
    };

    useEffect(() => {}, [currentProductId]);

    const rowKeyUpdate = (d) => {
        setCurrentProductId(d.data.product.id);
        setProcessorId(d.data.processor.id);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("device") + " " + t("lists")}
                icon="devices"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    onEditingStart={rowKeyUpdate}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    <FilterRow visible={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="searchPanel" />
                        <Item name="addRowButton" />
                    </Toolbar>
                    <Editing
                        mode="popup"
                        allowAdding
                        allowDeleting
                        allowUpdating
                        onChangesChange={onChangesChange}>
                        <Form colCount={1}>
                            <GroupItem
                                colCount={3}
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "general.information"})}>
                                <Item dataField="name" />
                                <Item dataField="product.id" />
                                <Item dataField="processor.id" />
                            </GroupItem>
                            <TabbedItem colSpan={3}>
                                <TabPanelOptions deferRendering={true} />
                                <Tab title={t("{{key, capitalize}}", {key: "read.point"})}>
                                    <Item dataField="deviceAntennaList" />
                                </Tab>
                                <Tab
                                    title={t("{{key, capitalize}}", {key: "visitor.sensor"})}
                                    disabled={currentProductId !== 2}>
                                    <Item dataField="visitorReader.id" />
                                    <Item dataField="visitorEnterPort" />
                                    <Item dataField="visitorExitPort" />
                                </Tab>
                            </TabbedItem>
                        </Form>
                    </Editing>

                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption=""
                        dataField=""
                        width={70}
                        allowFiltering={false}
                        allowSorting={false}
                        allowEditing={false}
                        cellRender={(item) => (
                            <Button
                                type="default"
                                icon="download"
                                disabled={
                                    item.data.product.name !== "Self Check" ||
                                    !authProvider.isAuth("createCertificate")
                                }
                                onClick={() => {
                                    return deviceService.downloadApp(item.data.id).then((r) => {});
                                }}
                            />
                        )}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "id"})}
                        dataField="id"
                        width={70}
                        sortOrder="asc"
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "name"})}
                        dataField="name"
                        dataType="string"></Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "product"})}
                        dataField="product.id">
                        <Lookup dataSource={productDataSource} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "processor"})}
                        dataField="processor.id"
                        setCellValue={(newData, value, currentRowData) => {
                            newData.processor = {id: value};
                            newData.deviceAntennaList = null;
                            setProcessorId(value);
                        }}>
                        <Lookup
                            dataSource={processorDataSource}
                            displayExpr={(e) => e.department.name + "-" + e.code}
                            valueExpr="id"
                            searchExpr={["code", "department.name"]}
                        />
                        <RequiredRule />
                    </Column>

                    <Column
                        caption=" "
                        visible={false}
                        allowSearch={false}
                        allowFiltering={false}
                        dataField="deviceAntennaList"
                        editCellRender={editGridDeviceAntennaCell}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "reader"})}
                        dataField="visitorReader.id"
                        visible={false}>
                        <Lookup
                            dataSource={readerDataSource}
                            displayExpr="serialNumber"
                            valueExpr="id"
                        />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "visitor.enter.port"})}
                        dataField="visitorEnterPort"
                        visible={false}></Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "visitor.exit.port"})}
                        dataField="visitorExitPort"
                        visible={false}></Column>
                </DataGrid>
            </SuiBox>
        </DashboardLayout>
    );
}

export default DevicePage;
