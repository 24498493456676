import React, {useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Form,
    Toolbar,
    Item,
    ColumnChooser,
    LoadPanel,
    RequiredRule,
    Lookup,
    Popup,
    FilterRow,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import UserService from "./UserService";
import RoleService from "../role/RoleService";
import DepartmentTreeList from "../../departments/departmentTreeList";
import {useTranslation} from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const userService = UserService();
const roleService = RoleService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return userService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return userService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            values.personType = "Person";
            return userService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return userService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return userService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

const roleDataSource = new CustomStore({
    key: "id",
    load: () => {
        return roleService.findAll().then((response: any) => {
            return {
                data: response,
                totalCount: response.length,
            };
        });
    },
    byKey: (key: any) => {
        return roleService.findOne(key).then((response: any) => {
            return response;
        });
    },
});

function UserPage() {
    const {t} = useTranslation();
    const [dataGrid, setDataGrid] = useState();

    const onGridInsertedUpdated = (d, val) => {
        if (!d.value) {
            d.setValue([val.data]);
        } else {
            d.setValue(d.value);
        }
    };
    const editGridDepartmentRolesCell = (d) => {
        return (
            <DataGrid
                dataSource={d.value ? d.value : []}
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
                onRowInserted={(val) => {
                    onGridInsertedUpdated(d, val);
                }}
                onRowUpdated={(val) => {
                    onGridInsertedUpdated(d, val);
                }}
                onRowRemoved={(val) => {
                    onGridInsertedUpdated(d, val);
                }}>
                <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true} />
                <Paging defaultPageSize={10} />
                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
                <Column caption={t("{{key, capitalize}}", {key: "role"})} dataField="role.id">
                    <Lookup dataSource={roleDataSource} displayExpr="description" valueExpr="id" />
                    <RequiredRule />
                </Column>

                <Column
                    caption={t("{{key, capitalize}}", {key: "department"})}
                    dataField="department"
                    cellRender={(cr) => {
                        return <div>{cr.data.department.name}</div>;
                    }}
                    editCellRender={(ecr) => {
                        return (
                            <DepartmentTreeList
                                value={ecr.value && ecr.value.id ? ecr.value.id : null}
                                onValueChanged={(e) => {
                                    ecr.setValue(e);
                                    // console.log('afterHandleChange', e, ecr);
                                }}
                            />
                        );
                    }}>
                    <RequiredRule />
                </Column>
            </DataGrid>
        );
    };

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("user") + " " + t("lists")}
                icon="supervisor_account"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    showBorders={false}
                    remoteOperations={true}
                    rowAlternationEnabled={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Editing
                        mode="popup"
                        allowUpdating={authProvider.isAuth("userUpdate")}
                        allowAdding={authProvider.isAuth("userSave")}
                        allowDeleting={authProvider.isAuth("userDelete")}>
                        <Popup
                            title={t("{{key, capitalize}}", {key: "user.information"})}
                            showTitle={true}
                        />
                        <Form>
                            <Item
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "general.information"})}
                                colCount={7}>
                                <Item dataField="username" colSpan={3} />
                                <Item dataField="password" colSpan={3} />
                                <Item dataField="enabled" colSpan={1} />
                            </Item>
                            <Item
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "person.information"})}
                                colCount={6}>
                                <Item dataField="person.identityNumber" colSpan={2} />
                                <Item dataField="person.name" colSpan={2} />
                                <Item dataField="person.surName" colSpan={2} />
                            </Item>
                            <Item
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "role.information"})}
                                colSpan={2}>
                                <Item dataField="userDepartmentRoles" />
                            </Item>
                        </Form>
                    </Editing>
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "active"})}
                        dataField="enabled"
                        dataType="boolean"
                        width={120}>
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "username"})}
                        dataField="username"
                        dataType="string"
                        visible={true}>
                        <RequiredRule />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "password"})}
                        dataField="password"
                        dataType="string"
                        visible={false}
                        allowSearch={false}
                        allowFiltering={false}>
                        {/*<RequiredRule />*/}
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "identity.number"})}
                        dataField="person.identityNumber"
                        dataType="number"
                        sortOrder="asc">
                        <RequiredRule />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "name"})}
                        dataField="person.name"
                        dataType="string">
                        <RequiredRule />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "surname"})}
                        dataField="person.surName"
                        dataType="string">
                        <RequiredRule />
                    </Column>

                    <Column
                        caption=" "
                        dataField="userDepartmentRoles"
                        visible={false}
                        allowSearch={false}
                        allowFiltering={false}
                        editCellRender={editGridDepartmentRolesCell}
                    />
                </DataGrid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default UserPage;
