// react-router-dom components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import {Breadcrumbs as MuiBreadcrumbs} from "@mui/material";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import AuthProvider from "../../providers/AuthProvider";

function Breadcrumbs({icon, title, route, light}) {
    const routes = route.slice(0, -1);
    const authProvider = AuthProvider();

    return (
        <SuiBox mr={{xs: 0, xl: 8}}>
            {/*<MuiBreadcrumbs*/}
            {/*    sx={{*/}
            {/*        "& .MuiBreadcrumbs-separator": {*/}
            {/*            color: ({palette: {white, grey}}) => (light ? white.main : grey[600]),*/}
            {/*        },*/}
            {/*    }}>*/}
            {/*    <Link to="/dashboards">*/}
            {/*        <SuiTypography*/}
            {/*            component="span"*/}
            {/*            variant="body2"*/}
            {/*            color={light ? "white" : "dark"}*/}
            {/*            opacity={light ? 0.8 : 0.5}*/}
            {/*            sx={{lineHeight: 0}}>*/}
            {/*            <Icon>{icon}</Icon>*/}
            {/*        </SuiTypography>*/}
            {/*    </Link>*/}
            {/*    {routes.map((el) => (*/}
            {/*        <SuiTypography*/}
            {/*            key={el}*/}
            {/*            component="span"*/}
            {/*            variant="button"*/}
            {/*            fontWeight="regular"*/}
            {/*            textTransform="capitalize"*/}
            {/*            color={light ? "white" : "dark"}*/}
            {/*            opacity={light ? 0.8 : 0.5}*/}
            {/*            sx={{lineHeight: 0}}>*/}
            {/*            {el}*/}
            {/*        </SuiTypography>*/}
            {/*    ))}*/}
            {/*    <SuiTypography*/}
            {/*        variant="button"*/}
            {/*        fontWeight="regular"*/}
            {/*        textTransform="capitalize"*/}
            {/*        color={light ? "white" : "dark"}*/}
            {/*        sx={{lineHeight: 0}}>*/}
            {/*        {title.replace("-", " ")}*/}
            {/*    </SuiTypography>*/}
            {/*</MuiBreadcrumbs>*/}
            <SuiTypography
                fontWeight="bold"
                textTransform="capitalize"
                variant="h6"
                color={light ? "white" : "dark"}
                noWrap>
                {authProvider.getAuthData() && authProvider.getAuthData().department.contentId ? (
                    <SuiBox
                        component="img"
                        src={
                            `${process.env.REACT_APP_API_URL}binaryContents/getPublicImage/` +
                            authProvider.getAuthData().department.contentId
                        }
                        alt="Libref UI Logo"
                        maxHeight={"40px"}
                    />
                ) : (
                    authProvider.getAuthData().department.name
                )}
                {/*{authProvider.getAuthData() ? authProvider.getAuthData().department.name : ""}*/}
                {/*{title.replace("-", " ")}*/}
            </SuiTypography>
        </SuiBox>
    );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
    light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    light: PropTypes.bool,
};

export default Breadcrumbs;
