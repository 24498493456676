// Soft UI Dashboard PRO React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

// Soft UI Dashboard PRO helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {dark, white, grey, inputColors} = colors;
const {size, fontWeightRegular} = typography;
const {borderWidth, borderRadius} = borders;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            display: "grid !important",
            placeItems: "center !important",
            width: "100% !important",
            height: "auto !important",
            padding: `${pxToRem(8)} ${pxToRem(12)}`,
            fontSize: `${size.sm} !important`,
            fontWeight: `${fontWeightRegular} !important`,
            lineHeight: "1.4 !important",
            color: `${grey[700]} !important`,
            backgroundColor: `${white.main} !important`,
            backgroundClip: "padding-box !important",
            border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
            appearance: "none !important",
            borderRadius: borderRadius.md,
            transition:
                "box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important",
        },

        input: {
            width: "100% !important",
            height: `${pxToRem(22)}`,
            padding: "0 !important",

            "&::-webkit-input-placeholder": {
                color: `${dark.main} !important`,
            },
        },
    },
};
