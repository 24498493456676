import {useEffect, useState} from "react";

// react-router-dom components
import {NavLink, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React context
import {setMiniSidenav, useSoftUIController} from "context";
import SidenavItem from "./SidenavItem";
import SidenavList from "./SidenavList";
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";
import {useTranslation} from "react-i18next";

import PerfectScrollbar from "react-perfect-scrollbar";
import AuthProvider from "../../../providers/AuthProvider";

function Sidenav({color, brand, brandName, routes, ...rest}) {
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, transparentSidenav} = controller;
    const location = useLocation();
    const {pathname} = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const itemName = pathname.split("/").slice(1)[1];
    const authProvider = AuthProvider();

    const {t} = useTranslation();

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse) => {
        return collapse.map(({name, route, key, href}) =>
            href ? (
                <Link
                    key={key}
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    sx={{textDecoration: "none"}}>
                    <SidenavItem name={t(name)} nested />
                </Link>
            ) : (
                <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
                    <SidenavItem name={t(name)} active={route === pathname} nested />
                </NavLink>
            )
        );
    };

    // Render the all the collpases from the routes.js
    const renderCollapse = (collapses) =>
        collapses.map(({name, collapse, route, href, key}) => {
            name = t("{{key, capitalize}}", {key: name});
            let returnValue;

            if (collapse) {
                returnValue = (
                    <SidenavItem
                        key={key}
                        name={name}
                        active={key === itemName}
                        open={openNestedCollapse === name}
                        onClick={() =>
                            openNestedCollapse === name
                                ? setOpenNestedCollapse(false)
                                : setOpenNestedCollapse(name)
                        }>
                        {renderNestedCollapse(collapse)}
                    </SidenavItem>
                );
            } else {
                returnValue = href ? (
                    <Link
                        href={href}
                        key={key}
                        target="_blank"
                        rel="noreferrer"
                        sx={{textDecoration: "none"}}>
                        <SidenavItem name={name} active={key === itemName} />
                    </Link>
                ) : (
                    <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
                        <SidenavItem name={name} active={key === itemName} />
                    </NavLink>
                );
            }
            return <SidenavList key={key}>{returnValue}</SidenavList>;
        });

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(
        ({type, name, icon, title, collapse, noCollapse, key, href, route}) => {
            name = t("{{key, capitalize}}", {key: name});
            let returnValue;
            if (type === "collapse") {
                returnValue = href ? (
                    <Link
                        href={href}
                        key={key}
                        target="_blank"
                        rel="noreferrer"
                        sx={{textDecoration: "none"}}>
                        <SidenavCollapse
                            color={color}
                            name={name}
                            icon={icon}
                            active={key === collapseName}
                            noCollapse={noCollapse}
                        />
                    </Link>
                ) : (
                    <SidenavCollapse
                        color={color}
                        key={key}
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        open={openCollapse === name}
                        onClick={() =>
                            openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name)
                        }>
                        {collapse ? renderCollapse(collapse) : null}
                    </SidenavCollapse>
                );
            } else if (type === "title") {
                returnValue = (
                    <SuiTypography
                        key={key}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}>
                        {t(title)}
                    </SuiTypography>
                );
            } else if (type === "divider") {
                returnValue = <Divider key={key} />;
            } else if (type === "link") {
                returnValue = (
                    <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
                        <SidenavCollapse
                            color={color}
                            name={name}
                            icon={icon}
                            active={key === collapseName}
                            noCollapse={noCollapse}
                        />
                    </NavLink>
                );
            }

            return returnValue;
        }
    );

    return (
        <SidenavRoot {...rest} variant="permanent" ownerState={{transparentSidenav, miniSidenav}}>
            <SuiBox pt={3} pb={1} px={4} textAlign="center">
                <SuiBox
                    display={{xs: "block", xl: "none"}}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{cursor: "pointer"}}>
                    <SuiTypography variant="h6" color="secondary">
                        <Icon sx={{fontWeight: "bold"}}>close</Icon>
                    </SuiTypography>
                </SuiBox>
                {miniSidenav && (
                    <SuiBox
                        component={NavLink}
                        to="/dashboards/main-dashboard"
                        display="flex"
                        alignItems="center">
                        {brand && (
                            <SuiBox
                                mx={-2}
                                component="img"
                                src={brand}
                                alt="Libref UI Logo"
                                minWidth={"70px"}
                            />
                        )}
                    </SuiBox>
                )}
                {!miniSidenav && (
                    <SuiBox
                        component={NavLink}
                        to="/dashboards/main-dashboard"
                        display="flex"
                        alignItems="center">
                        {brand && (
                            <SuiBox
                                pt={0.25}
                                pb={1}
                                mx={2}
                                component="img"
                                src={brand}
                                // src={
                                //     authProvider.getAuthData().department.contentId
                                //         ? `${process.env.REACT_APP_API_URL}binaryContents/getPublicImage/` +
                                //           authProvider.getAuthData().department.contentId
                                //         : brand
                                // }
                                alt="Libref UI Logo"
                                minWidth={"70px"}
                            />
                        )}
                    </SuiBox>
                )}
            </SuiBox>
            {/*<Divider />*/}
            <PerfectScrollbar style={{height: "auto"}}>
                <List>{renderRoutes}</List>
            </PerfectScrollbar>

            {/*<SuiBox pt={2} my={2} mx={2}>*/}
            {/*    <SidenavCard />*/}
            {/*</SuiBox>*/}
        </SidenavRoot>
    );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brand: PropTypes.string,
    brandName: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
