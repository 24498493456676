import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Colors
import colors from "assets/theme/base/colors";

// Card:
import Card from "@mui/material/Card";

// Soft UI Dashboard Chart, Data Set and Counter Card components
import ThinBarChart from "examples/Charts/BarCharts/ThinBarChart";
// import Table from "examples/Tables/Table";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

// Footer
import Footer from "../../../../examples/Footer";

// Data
import controllerCardIcons from "pages/share/dashboards/main-dashboard/data/controllerCardIcons";
import ControllerCard from "examples/Cards/ControllerCard";

// Images
import DashboardLayout from "../../../../layouts/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

// Link
import {NavLink} from "react-router-dom";
import StaService from "../../sta/StaService";
import {useEffect, useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import {useTranslation} from "react-i18next";
import {Chart, PieChart} from "devextreme-react";
import {
    Aggregation,
    ArgumentAxis,
    CommonSeriesSettings,
    Format,
    Label,
    Legend,
    Series,
    Title,
    Tooltip,
} from "devextreme-react/chart";
import {initWebsocket} from "../../../../providers/WebSocketProvider";
import {CardActions, CardHeader} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {red} from "@mui/material/colors";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Connector, Export} from "devextreme-react/pie-chart";
import ProductService from "../../../app/product/ProductService";
import DataSource from "devextreme/data/data_source";

const staService = StaService();

let dashboardItemCountList = [];
let dashboardPermanentLocationlist = [];
let dashboardDailyVisitorCountList = [];
let dashboardDailyVisitorCountDaysList = [];
let dashboardDailyPairingCount = 0;
let dashboardDailyLoanCount = 0;
let dashboardDailyReturnCount = 0;

const readerDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
        return staService.result("vw_sta_media_info").then(
            (data) => ({
                data: data,
                totalCount: data.totalCount,
            }),
            (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            }
        );
    },
});
const productService = ProductService();
const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return productService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

function MainDashboardPage() {
    const {light, dark} = colors;
    const [sum, setSum] = useState(0);
    const {t} = useTranslation();
    const [dashboardItemCount, setDashboardItemCount] = useState([]);
    const [dashboardPermanentLocation, setDashboardPermanentLocation] = useState([]);
    const [dashboardDailyVisitorCount, setDashboardDailyVisitorCount] = useState([]);
    const [dashboardDailyVisitorDays, setDashboardDailyVisitorDays] = useState([]);
    const [dashboardPairingCount, setDashboardPairingCount] = useState(0);
    const [dashboardLoanCount, setDashboardLoanCount] = useState(0);
    const [dashboardReturnCount, setDashboardReturnCount] = useState(0);

    const readerDataSource2 = new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return staService
                .result("vw_sta_visitor_info")
                .then((data) => {
                    for (var i in data) {
                        data[i].day_name = t("{{key, capitalize}}", {
                            key: data[i].day_name,
                        });
                    }
                    return data;
                })
                .then(
                    (data) => ({
                        data: data,
                    }),

                    (err) => {
                        return err.json().then((r) => {
                            throw r.errorMessage ? r.errorMessage : r.warningMessage;
                        });
                    }
                );
        },
    });

    // staService.result("vw_sta_media_info").then((result) => {
    //     console.log(result);
    //     for (var i in result) {
    //         dashboardDataList.push(result[i].count);
    //     }
    //
    //     setDashboardData(dashboardDataList);
    // });

    useEffect(() => {
        // sum = readerDataSource.data[0].count.reduce((partialSum, a) => partialSum + a, 0);

        dashboardItemCountList = [];
        dashboardPermanentLocationlist = [];
        staService.result("vw_sta_media_info").then((result) => {
            let temp = 0;
            for (var i in result) {
                dashboardPermanentLocationlist.push(result[i].permanent_location);
                dashboardItemCountList.push(result[i].count);
                if (result[i].permanent_location != null) {
                    temp = temp + result[i].count;
                }
            }
            setSum(temp);
            setDashboardPermanentLocation(dashboardPermanentLocationlist);
            setDashboardItemCount(dashboardItemCountList);
        });

        dashboardDailyVisitorCountList = [];
        dashboardDailyVisitorCountDaysList = [];
        staService.result("vw_sta_visitor_info").then((result) => {
            result.forEach((data) => dashboardDailyVisitorCountList.push(data.visitor_count));
            result.forEach((data) =>
                dashboardDailyVisitorCountDaysList.push(
                    t("{{key, capitalize}}", {
                        key: data.day_name,
                    })
                )
            );

            setDashboardDailyVisitorCount(dashboardDailyVisitorCount);
            setDashboardDailyVisitorDays(dashboardDailyVisitorDays);
        });

        staService.result("vw_sta_circulation_info").then((result) => {
            dashboardDailyPairingCount = result[0].eslestirme;
            dashboardDailyLoanCount = result[0].odunc;
            dashboardDailyReturnCount = result[0].iade;

            setDashboardPairingCount(dashboardDailyPairingCount);
            setDashboardLoanCount(dashboardDailyLoanCount);
            setDashboardReturnCount(dashboardDailyReturnCount);
        });
    }, []);

    // Icons for Cards
    const {
        eslestirmeIslemiDark,
        // eslestirmeIslemiLight,
        kontrolNoktasiDark,
        // kontrolNoktasiLight,
        oduncIadeDark,
        // oduncIadeLight,
        sayimIslemiDark,
        // selfCheckLight,
    } = controllerCardIcons;

    const dayData = () => {
        let dayList = [];
        for (var i in dashboardDailyVisitorCountDaysList) {
            dayList.push(
                t("{{key, capitalize}}", {
                    key: dashboardDailyVisitorCountDaysList[i],
                })
            );
        }

        return dayList;
    };

    const inDayFunctionCount = (d) => {
        if (d.index === 6) {
            return {backgroundColor: "#ff7c7c"};
        }
    };

    const inDayFunctionChart = (d) => {
        if (d.index === 6) {
            return {color: "#ff7c7c"};
        }
    };

    const pieChartData = (d) => {
        console.log(d);
        return {
            text:
                t("{{key, capitalize}}", {
                    key: "dashboard.collection",
                }) +
                ": " +
                d.argumentText +
                "<br/>" +
                t("{{key, capitalize}}", {
                    key: "dashboard.fixture.count",
                }) +
                ": " +
                d.valueText,
        };
    };
    // Controller cards states
    // const [humidityState, setHumidityState] = useState(false);
    // const [temperatureState, setTemperatureState] = useState(true);
    // const [airConditionerState, setAirConditionerState] = useState(false);
    // const [lightsStata, setLightsStata] = useState(false);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={3}>
                <SuiBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Card>
                                <SuiBox bgColor={light.main}>
                                    <SuiBox
                                        mb={1}
                                        color={light.main}
                                        bgColor={dark.main}
                                        pt={2.25}
                                        pb={2.25}
                                        pl={3}>
                                        <SuiTypography color={"light"} variant="h6">
                                            {t("{{key, capitalize}}", {
                                                key: "dashboard.table.header",
                                            })}
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox pl={1}>
                                        <PieChart
                                            id="pie"
                                            dataSource={readerDataSource}
                                            palette="Bright">
                                            <Title
                                                text={
                                                    t("{{key, capitalize}}", {
                                                        key: "dashboard.book.count",
                                                    }) +
                                                    " : " +
                                                    sum
                                                }
                                                alignment="center"
                                                color=""
                                            />
                                            <CommonSeriesSettings argumentField="permanent_location" />
                                            <Series
                                                valueField="count"
                                                argumentField="permanent_location"
                                                hoverMode="onlyPoint"
                                                selectionMode="onlyPoint">
                                                {/*<Label visible={false}>*/}
                                                {/*    <Connector visible={false} width={1} />*/}
                                                {/*</Label>*/}
                                                {/*    // wordWrap="breakWord"*/}
                                                {/*    // overlappingBehavior="stagger"*/}
                                                {/*    // textOverflow="normal"*/}
                                                {/*/>*/}
                                            </Series>
                                            <Legend visible={false} />
                                            <Export enabled={false} />

                                            <Tooltip
                                                enabled={true}
                                                location="edge"
                                                customizeTooltip={pieChartData}
                                            />
                                            {/*<Legend hoverMode="excludePoints" visible={false} />*/}
                                        </PieChart>

                                        {/*<Chart dataSource={readerDataSource}>*/}
                                        {/*    <Title*/}
                                        {/*        text={*/}
                                        {/*            t("{{key, capitalize}}", {*/}
                                        {/*                key: "dashboard.book.count",*/}
                                        {/*            }) +*/}
                                        {/*            " : " +*/}
                                        {/*            sum*/}
                                        {/*        }*/}
                                        {/*        // subtitle={*/}
                                        {/*        // t("{{key, capitalize}}", {*/}
                                        {/*        //     key: "dashboard.book.count",*/}
                                        {/*        // }) +*/}
                                        {/*        // " : " +*/}
                                        {/*        // sum*/}
                                        {/*        // }*/}
                                        {/*        horizontalAlignment="center"*/}
                                        {/*        color=""*/}
                                        {/*    />*/}
                                        {/*    <CommonSeriesSettings*/}
                                        {/*        argumentField="permanent_location"*/}
                                        {/*        type="bar"*/}
                                        {/*        hoverMode="none"*/}
                                        {/*        selectionMode="none">*/}
                                        {/*        /!* // barPadding={0.1}> // barWidth={10}> *!/*/}
                                        {/*        <Label visible={true}>*/}
                                        {/*            <Format type="fixedPoint" />*/}
                                        {/*        </Label>*/}
                                        {/*    </CommonSeriesSettings>*/}
                                        {/*    <Series*/}
                                        {/*        valueField="count"*/}
                                        {/*        argumentField="permanent_location"*/}
                                        {/*        name="My oranges"*/}
                                        {/*        type="bar"*/}
                                        {/*        color="#082342"></Series>*/}
                                        {/*    <ArgumentAxis>*/}
                                        {/*        <Label*/}
                                        {/*            wordWrap="breakWord"*/}
                                        {/*            overlappingBehavior="stagger"*/}
                                        {/*            textOverflow="normal"*/}
                                        {/*        />*/}
                                        {/*    </ArgumentAxis>*/}
                                        {/*    <Legend visible={false} />*/}
                                        {/*</Chart>*/}
                                    </SuiBox>
                                </SuiBox>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card>
                                <SuiBox bgColor={light.main}>
                                    <SuiBox
                                        mb={1}
                                        color={light.main}
                                        bgColor={dark.main}
                                        pt={2.25}
                                        pb={2.25}
                                        pl={3}>
                                        <SuiTypography color={"light"} variant="h6">
                                            {t("{{key, capitalize}}", {
                                                key: "dashboard.daily.visitor",
                                            })}
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox pl={1}>
                                        <Chart
                                            dataSource={readerDataSource2}
                                            customizeLabel={inDayFunctionCount}
                                            customizePoint={inDayFunctionChart}>
                                            <CommonSeriesSettings
                                                // argumentField={t("{{key, capitalize}}", {
                                                //     key: readerDataSource2.day_name,
                                                // })}
                                                type="bar"
                                                hoverMode="none"
                                                selectionMode="none">
                                                <Label visible={true}>
                                                    <Format type="fixedPoint" />
                                                </Label>
                                            </CommonSeriesSettings>
                                            <Series
                                                valueField="visitor_count"
                                                argumentField="day_name"
                                                type="bar"
                                                color="#082342"></Series>

                                            <ArgumentAxis>
                                                <Label
                                                    wordWrap="breakWord"
                                                    overlappingBehavior="rotate"
                                                    textOverflow="normal"
                                                />
                                            </ArgumentAxis>
                                            <Legend visible={false} />
                                        </Chart>
                                    </SuiBox>
                                </SuiBox>
                            </Card>
                        </Grid>

                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <ThinBarChart*/}
                        {/*        // height="17.62rem"*/}
                        {/*        color="dark"*/}
                        {/*        title={t("{{key, capitalize}}", {key: "dashboard.daily.visitor"})}*/}
                        {/*        chart={{*/}
                        {/*            labels: dayData(),*/}
                        {/*            datasets: {*/}
                        {/*                label: t("{{key, capitalize}}", {*/}
                        {/*                    key: "dashboard.statistics",*/}
                        {/*                }),*/}
                        {/*                data: dashboardDailyVisitorCountList,*/}
                        {/*            },*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={6} lg={3}>
                            <DefaultCounterCard
                                // countPairing={333}
                                countPairing={dashboardDailyPairingCount}
                                countLoan={dashboardDailyLoanCount}
                                countReturn={dashboardDailyReturnCount}
                                title={t("{{key, capitalize}}", {key: "dashboard.statistics"})}
                                description={t("{{key, capitalize}}", {
                                    key: "dashboard.book.count",
                                })}
                                titlePairing={t("{{key, capitalize}}", {
                                    key: "dashboard.paired.up",
                                })}
                                titleLoan={t("{{key, capitalize}}", {key: "dashboard.borrowed"})}
                                titleReturn={t("{{key, capitalize}}", {
                                    key: "dashboard.returned",
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <ControllerCard
                                icon={9}
                                title={t("{{key, capitalize}}", {key: "dashboard.match"})}
                                description={t("{{key, capitalize}}", {
                                    key: "dashboard.match.explanation",
                                })}
                                to={"/transactions/match"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <ControllerCard
                                icon={11}
                                title={t("{{key, capitalize}}", {key: "dashboard.loan.return"})}
                                description={t("{{key, capitalize}}", {
                                    key: "dashboard.loan.return.explanation",
                                })}
                                to={"/transactions/loanReturn"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <ControllerCard
                                icon={10}
                                title={t("{{key, capitalize}}", {
                                    key: "dashboard.controlpoint",
                                })}
                                description={t("{{key, capitalize}}", {
                                    key: "dashboard.controlpoint.explanation",
                                })}
                                to={"/transactions/controlPoint"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <ControllerCard
                                icon={36}
                                title={t("{{key, capitalize}}", {key: "dashboard.counting"})}
                                description={t("{{key, capitalize}}", {
                                    key: "dashboard.counting.explanation",
                                })}
                                to={"/transactions/itemCounting"}
                            />
                        </Grid>
                    </Grid>
                </SuiBox>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default MainDashboardPage;
