// Soft UI Dashboard PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

const {transparent} = colors;
const {lg} = boxShadows;
const {borderRadius} = borders;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        paper: {
            backgroundColor: transparent.main,
            boxShadow: lg,
            padding: pxToRem(8),
            borderRadius: borderRadius.lg,
        },
    },
};
