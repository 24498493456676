import React, {useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Form,
    Toolbar,
    Item,
    ColumnChooser,
    LoadPanel,
    Lookup,
    RequiredRule,
    FilterRow,
    MasterDetail,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import {useTranslation} from "react-i18next";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import AuthProvider from "../../../providers/AuthProvider";
import ReaderService from "./ReaderService";
import {EReaderType} from "../../../providers/AppConstantProvider";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const readerService = ReaderService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return readerService.findAll(loadOptions).then(
                (data) => ({
                    data: data.items,
                    totalCount: data.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return readerService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            return readerService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return readerService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return readerService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

function ReaderPage() {
    const [dataGrid, setDataGrid] = useState();
    const {t} = useTranslation();

    const onAntennaUpdated = (d, val) => {
        if (!d.value) {
            d.setValue([val.data]);
        } else {
            d.setValue(d.value);
        }
    };
    const onAntennaInserted = (d, val) => {
        if (!editing) {
            if (!d.value) {
                d.setValue([val.data]);
            } else {
                const array = d.value.filter((item) => item.__KEY__ === val.data.__KEY__);
                if (array.length !== 0) {
                    d.setValue(d.value);
                } else {
                    d.setValue([...d.value, val.data]);
                }
            }
        } else {
            d.setValue(d.value);
        }
    };
    const onAntennaDeleted = (d, val) => {
        const updatedList = d.value.filter((item) => item.antennaPort !== val.data.antennaPort);
        d.setValue(updatedList);
    };
    let editing = false;
    const onInitNewRow = (value) => {
        editing = value;
    };

    const editGridAntennaCell = (d) => {
        const value = d.value ? d.value : [];
        return (
            <DataGrid
                dataSource={value}
                allowSearch={false}
                allowFiltering={false}
                allowSorting={false}
                onInitNewRow={() => onInitNewRow(false)}
                onEditingStart={() => onInitNewRow(true)}
                onEditCanceled={() => () => onInitNewRow(false)}
                onRowInserted={(val) => {
                    onAntennaInserted(d, val);
                }}
                onRowUpdated={(val) => {
                    onAntennaUpdated(d, val);
                }}
                onRowRemoved={(val) => {
                    onAntennaDeleted(d, val);
                }}>
                <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true} />
                <Column
                    caption={t("{{key, capitalize}}", {key: "port"})}
                    dataField="antennaPort"
                    dataType="number"
                    sortOrder="asc"
                />
                <Column
                    caption={t("{{key, capitalize}}", {key: "power"})}
                    dataField="txPowerDbm"
                    dataType="number"
                />
            </DataGrid>
        );
    };

    const detailTemplate = (d) => {
        return (
            <DataGrid
                dataSource={d.data.data.antennaList}
                allowSearch={false}
                allowFiltering={false}
                allowSorting={false}>
                <Toolbar>
                    <Item location="before">
                        {t("{{key, capitalize}}", {key: "antenna.information"})}
                    </Item>
                </Toolbar>
                <Column
                    caption={t("{{key, capitalize}}", {key: "port"})}
                    dataField="antennaPort"
                    dataType="number"
                    width={150}
                    sortOrder="asc"
                />
                <Column
                    caption={t("{{key, capitalize}}", {key: "power"})}
                    dataField="txPowerDbm"
                    dataType="number"
                />
            </DataGrid>
        );
    };

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("reader") + " " + t("lists")}
                icon="corporate_fare"
                fontSize={"medium"}
            />
            <SuiBox mt={2} p={3} color="red" shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    showBorders={false}
                    remoteOperations={true}
                    rowAlternationEnabled={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    {/*<RemoteOperations filtering={true} sorting={true}/>*/}
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Editing
                        mode="form"
                        allowUpdating={authProvider.isAuth("readerUpdate")}
                        allowAdding={authProvider.isAuth("readerSave")}
                        allowDeleting={authProvider.isAuth("readerSave")}>
                        <Form>
                            <Item
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "general.information"})}>
                                <Item dataField="readerType" />
                                <Item dataField="serialNumber" />
                                <Item dataField="readerIpAddress" />
                            </Item>
                            <Item
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "antenna.information"})}>
                                <Item dataField="antennaList" />
                            </Item>
                        </Form>
                    </Editing>
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "reader.type"})}
                        dataField="readerType"
                        width={200}>
                        <Lookup
                            dataSource={EReaderType}
                            displayExpr={(row) => {
                                return t("{{key, capitalize}}", {
                                    key: row.value,
                                });
                            }}
                            valueExpr="value"
                        />
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "serial.number"})}
                        dataField="serialNumber"
                        dataType="string">
                        <RequiredRule />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "ip.address"})}
                        dataField="readerIpAddress"
                        dataType="string">
                        <RequiredRule />
                    </Column>
                    <Column
                        caption=" "
                        visible={false}
                        allowSearch={false}
                        allowFiltering={false}
                        dataField="antennaList"
                        editCellRender={editGridAntennaCell}
                    />
                    <MasterDetail enabled={true} component={detailTemplate} />
                </DataGrid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ReaderPage;
