import LoginPage from "./pages/share/authentication/login";
import DocumentPage from "./pages/share/document/manager";
import ReportListPage from "./pages/share/reports/list";
import MediaPage from "./pages/share/media";
import RealPersonPage from "./pages/share/person/real-person";
import LegalEntityPage from "./pages/share/person/legal-entity";
import UserPage from "./pages/share/users/user";
import RolePage from "./pages/share/users/role";
import DepartmentPage from "./pages/share/departments/department";
import DepartmentTypePage from "./pages/share/departments/departmentType";
import LocationPage from "./pages/share/locations/location";
import LocationTypePage from "./pages/share/locations/locationType";
import ReportManagerPage from "./pages/share/reports/manager";
import ReportDesignerPage from "./pages/share/reports/designer";
import DocumentListPage from "./pages/share/document/list";
import LangPage from "./pages/share/langs/lang";
import LangMessagePage from "./pages/share/langs/message";
import DevicePage from "./pages/app/device";
import ProfilePage from "./pages/share/profile";
import ReaderPage from "./pages/app/reader";
import ProcessorPage from "./pages/app/processor";
import ProductPage from "./pages/app/product";
import MatchPage from "./pages/app/transactions/match";
import ControlPointPage from "./pages/app/transactions/control-point";
import LoanReturnPage from "./pages/app/transactions/loan-return";
import ItemCountingPage from "./pages/app/item/item-counting";
import MainDashboardPage from "./pages/share/dashboards/main-dashboard";
import AnnouncementPage from "./pages/share/announcement";
import GeneralSettingsPage from "./pages/share/generalsettings";

const routes = [
    {
        type: "collapse",
        name: "dashboard",
        key: "dashboards",
        icon: "dashboard",
        authorities: ["dashboardFindAll"],
        collapse: [
            {
                name: "mainDashboard",
                key: "mainDashboard",
                route: "/dashboards/main-dashboard",
                authorities: ["dashboardFindAll"],
                component: <MainDashboardPage />,
            },
        ],
    },
    {
        type: "collapse",
        name: "transactions",
        key: "transactions",
        icon: "developer_board",
        authorities: ["deviceFindOne"],
        collapse: [
            {
                name: "match",
                key: "match",
                route: "/transactions/match",
                authorities: ["deviceFindOne"],
                component: <MatchPage />,
            },
            {
                name: "control.point",
                key: "controlPoint",
                route: "/transactions/controlPoint",
                authorities: ["deviceFindOne"],
                component: <ControlPointPage />,
            },
            {
                name: "loan.return",
                key: "loanReturn",
                route: "/transactions/loanReturn",
                authorities: ["deviceFindOne"],
                component: <LoanReturnPage />,
            },
            {
                name: "media.item.counting",
                key: "itemCounting",
                route: "/transactions/itemCounting",
                authorities: ["deviceFindOne"],
                component: <ItemCountingPage />,
            },
        ],
    },
    {
        type: "link",
        name: "report",
        key: "report-list",
        icon: "assessment",
        route: "/report-list",
        authorities: ["reportFindAll"],
        noCollapse: true,
        component: <ReportListPage />,
    },
    {
        type: "link",
        name: "document",
        key: "document-list",
        icon: "filter_none",
        route: "/document-list",
        authorities: ["documentFindAll"],
        noCollapse: true,
        component: <DocumentListPage />,
    },
    {
        type: "link",
        name: "announcement",
        key: "announcement",
        icon: "campaign';",
        route: "/announcement",
        authorities: ["announcementSave"],
        noCollapse: true,
        component: <AnnouncementPage />,
    },
    {
        type: "link",
        name: "file",
        key: "media",
        icon: "file_copy",
        route: "/media",
        authorities: ["mediaGetItems"],
        noCollapse: true,
        component: <MediaPage />,
    },
    {
        type: "title",
        title: "manager",
        key: "title-pages",
        authorities: [
            "personSave",
            "roleSave",
            "userSave",
            "locationSave",
            "locationTypeSave",
            "departmentSave",
            "departmentTypeSave",
            "reportSave",
            "mediaGetItems",
            "deviceSave",
            "processorSave",
            "productSave",
        ],
    },
    {
        type: "collapse",
        name: "system",
        key: "system",
        icon: "device_hub",
        authorities: ["deviceSave", "productSave", "processorSave", "readerSave"],
        collapse: [
            {
                name: "device",
                key: "device",
                icon: "devices",
                route: "/system/device",
                authorities: ["deviceSave"],
                noCollapse: true,
                component: <DevicePage />,
            },
            {
                name: "product",
                key: "product",
                icon: "category",
                route: "/system/product",
                authorities: ["productSave"],
                noCollapse: true,
                component: <ProductPage />,
            },
            {
                name: "processor",
                key: "processor",
                icon: "device_hub",
                route: "/system/processor",
                authorities: ["processorSave"],
                noCollapse: true,
                component: <ProcessorPage />,
            },
            {
                name: "reader",
                key: "reader",
                icon: "settings_input_antenna",
                route: "/system/reader",
                authorities: ["readerSave"],
                noCollapse: true,
                component: <ReaderPage />,
            },
        ],
    },
    {
        type: "link",
        name: "profile",
        key: "profile",
        route: "/profile",
        authorities: ["none"],
        noCollapse: true,
        component: <ProfilePage />,
    },
    {
        type: "link",
        name: "document",
        key: "document",
        icon: "filter_none",
        route: "/document",
        authorities: ["documentSave"],
        noCollapse: true,
        component: <DocumentPage />,
    },
    {
        type: "collapse",
        name: "Authentication",
        key: "authentication",
        icon: "user",
        authorities: ["none"],
        collapse: [
            {
                name: "Basic",
                key: "basic",
                route: "/authentication/login",
                authorities: ["none"],
                component: <LoginPage />,
            },
        ],
    },
    {
        type: "collapse",
        name: "person",
        key: "person",
        icon: "person",
        authorities: ["personSave"],
        collapse: [
            {
                name: "realPerson",
                key: "real-person",
                route: "/person/real-person",
                authorities: ["personSave"],
                component: <RealPersonPage />,
            },
            {
                name: "legalEntity",
                key: "legal-entity",
                route: "/person/legal-entity",
                authorities: ["personSave"],
                component: <LegalEntityPage />,
            },
        ],
    },
    {
        type: "collapse",
        name: "user",
        key: "users",
        icon: "verified_user",
        authorities: ["roleSave", "userSave"],
        collapse: [
            {
                name: "user",
                key: "user",
                route: "/users/user",
                authorities: ["userSave"],
                component: <UserPage />,
            },
            {
                name: "role",
                key: "role",
                route: "/users/role",
                authorities: ["roleSave"],
                component: <RolePage />,
            },
        ],
    },
    // { type: "divider", key: "divider-1", authorities: [] },
    {
        type: "collapse",
        name: "department",
        key: "department",
        icon: "widgets",
        authorities: ["departmentSave", "departmentTypeSave"],
        collapse: [
            {
                name: "department",
                key: "department",
                route: "/department/department",
                authorities: ["departmentSave"],
                component: <DepartmentPage />,
            },
            {
                name: "type",
                key: "department-type",
                route: "/department/department-type",
                authorities: ["departmentTypeSave"],
                component: <DepartmentTypePage />,
            },
        ],
    },
    {
        type: "collapse",
        name: "location",
        key: "location",
        icon: "edit_location",
        authorities: ["locationSave", "locationTypeSave"],
        collapse: [
            {
                name: "location",
                key: "location",
                route: "/location/location",
                authorities: ["locationSave"],
                component: <LocationPage />,
            },
            {
                name: "type",
                key: "location-type",
                route: "/location/location-type",
                authorities: ["locationTypeSave"],
                component: <LocationTypePage />,
            },
        ],
    },

    {
        type: "collapse",
        name: "report",
        key: "report",
        icon: "assessment",
        authorities: ["reportSave"],
        collapse: [
            {
                name: "manager",
                key: "manager",
                route: "/report/manager",
                authorities: ["reportSave"],
                component: <ReportManagerPage />,
            },
            {
                name: "design",
                key: "design",
                route: "/report/design",
                authorities: ["reportSave"],
                component: <ReportDesignerPage />,
            },
        ],
    },

    {
        type: "collapse",
        name: "language",
        key: "languages",
        icon: "language",
        authorities: ["languagesSave"],
        collapse: [
            {
                name: "language",
                key: "lang",
                route: "/languages/lang",
                authorities: ["languagesSave"],
                component: <LangPage />,
            },
            {
                name: "message",
                key: "lang-message",
                route: "/languages/lang-message",
                authorities: ["languageMessagesSave"],
                component: <LangMessagePage />,
            },
        ],
    },

    {
        type: "link",
        name: "generalSettings",
        key: "general-settings",
        icon: "settings",
        route: "/general-settings",
        authorities: ["generalSettingsSave"],
        noCollapse: true,
        component: <GeneralSettingsPage />,
    },
];

export default routes;
