import AuthProvider from "../../../providers/AuthProvider";
import FileSaver from "file-saver";
import {getFileNameFromResponseContentDisposition} from "../../../providers/UtilsProvider";

const authProvider = AuthProvider();
const basePath = "document/";
function DocumentService() {
    const findOne = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
    };

    const findAll = async (loadOptions) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(loadOptions),
        };
        return authProvider.authFetch(basePath + "findAll", requestOptions);
    };

    const findAllPublic = async () => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findAllPublic", requestOptions);
    };

    const save = async (data) => {
        // console.log('save', data)
        const file = data.image;
        const thumbnailFile: File = data.thumbnail;
        const formData: FormData = new FormData();
        const blobData = new Blob([JSON.stringify(data)], {type: "application/json"});
        formData.append("data", blobData);
        if (file && file.name) {
            formData.append("file", file, file.name);
        }
        if (thumbnailFile && thumbnailFile.name) {
            formData.append("thumbnailFile", thumbnailFile, thumbnailFile.name);
        }
        const requestOptions = {
            method: "POST",
            body: formData,
        };
        return authProvider.authFetch(basePath + "save", requestOptions);
    };

    const update = (data) => {
        const file = data.image;
        const thumbnailFile: File = data.thumbnail;
        const formData: FormData = new FormData();
        const blobData = new Blob([JSON.stringify(data)], {type: "application/json"});
        formData.append("data", blobData);
        if (file && file.name) {
            formData.append("file", file, file.name);
        }
        if (thumbnailFile && thumbnailFile.name) {
            formData.append("thumbnailFile", thumbnailFile, thumbnailFile.name);
        }
        const requestOptions = {
            method: "POST",
            body: formData,
        };
        return authProvider.authFetch(basePath + "update", requestOptions);
    };

    const deleteOne = async (id) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: id,
        };
        return authProvider.authFetch(basePath + "delete", requestOptions);
    };

    const download = async (key) => {
        let fileName = "sample";
        const token = await authProvider.getToken();
        const input = process.env.REACT_APP_API_URL + basePath;
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`},
            responseType: "blob",
            observe: "response",
        };
        return fetch(input + "downloadDocument/" + key, requestOptions)
            .then((response) => {
                fileName = getFileNameFromResponseContentDisposition(response);
                return response.blob();
            })
            .then((blob) => {
                FileSaver.saveAs(blob, fileName);
            });
    };

    return {
        findOne,
        findAll,
        findAllPublic,
        save,
        update,
        deleteOne,
        download,
    };
}

export default DocumentService;
