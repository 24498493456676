import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/CoverLayout";

// Images
import LibrefLogo from "assets/images/login/libref-icon.svg";
import LibrefLoginRight from "assets/images/login/libref-login-right.svg";
import LibrefLoginLeft from "assets/images/login/libref-login-left.svg";

import {useFormik} from "formik";
import {LoadPanel} from "devextreme-react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthService from "../AuthService";
import {useTranslation} from "react-i18next";

function LoginPage() {
    const {t} = useTranslation();
    const [showLoadPanel, setShowLoadPanel] = useState(false);
    const navigate = useNavigate();

    const authService = AuthService();

    useEffect(() => {
        authService.clearAuthData();
    }, []);

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = t("{{key, capitalize}}", {
                key: "username.required",
            });
        }

        if (!values.password) {
            errors.password = t("{{key, capitalize}}", {
                key: "password.required",
            });
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            username: null,
            password: null,
        },
        validate,
        onSubmit: (values, actions) => {
            setShowLoadPanel(true);
            actions.setSubmitting(false);
            authService
                .login(values)
                .then((result) => {
                    setShowLoadPanel(false);
                    navigate("/dashboards/main-dashboard");
                })
                .catch((err) => {
                    setShowLoadPanel(false);
                });
        },
    });

    return (
        <CoverLayout
            icon={LibrefLogo}
            // title={"Libref"}
            description={t("{{key, capitalize}}", {
                key: "usernamePasswordRequired",
            })}
            imageRight={LibrefLoginRight}
            imageLeft={LibrefLoginLeft}>
            <form onSubmit={formik.handleSubmit}>
                <SuiBox role="form">
                    <SuiBox
                        mb={{xs: 1, sm: 2, md: 3}}
                        mt={{xs: -1, sm: 0, md: 1}}
                        lineHeight={1.25}>
                        <SuiBox mb={{xs: 0, sm: 0, md: 1}} mt={{xs: 0, md: 2}} ml={0.5}>
                            <SuiTypography
                                textTransform="capitalize"
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                fontSize="large">
                                {t("username")}
                            </SuiTypography>
                        </SuiBox>
                        <SuiInput
                            inputProps={{type: "text"}}
                            placeholder={t("username")}
                            onChange={({currentTarget}) => {
                                formik.values.username = currentTarget.value;
                            }}
                            defaultValue={formik.values.username}
                            error={!!formik.errors.username}
                        />
                    </SuiBox>
                    <SuiBox mb={{xs: 1, sm: 2, md: 3}} lineHeight={1.25}>
                        <SuiBox mb={{xs: 0, sm: 0, md: 1}} mt={{xs: 0, md: 2}} ml={0.5}>
                            <SuiTypography
                                textTransform="capitalize"
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                fontSize="large">
                                {t("password")}
                            </SuiTypography>
                        </SuiBox>
                        <SuiInput
                            type="password"
                            placeholder={t("password")}
                            inputProps={{minLength: 3}}
                            defaultValue={formik.values.password}
                            onChange={({currentTarget}) => {
                                formik.values.password = currentTarget.value;
                            }}
                            error={!!formik.errors.password}
                        />
                    </SuiBox>
                    <SuiBox mt={{xs: 2, sm: 2, lg: 3}} pt={{xs: 2, sm: 2, lg: 3}}>
                        <SuiButton type="submit" variant="gradient" color="dark" fullWidth>
                            {t("enter")}
                        </SuiButton>
                    </SuiBox>
                </SuiBox>
            </form>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={showLoadPanel}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={true}
            />
        </CoverLayout>
    );
}

export default LoginPage;
