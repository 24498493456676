import AuthProvider from "../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "reader/";
function ReaderService() {
    const findOne = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
    };

    const findAll = async (loadOptions) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(loadOptions),
        };
        return authProvider.authFetch(basePath + "findAll", requestOptions);
    };

    const save = async (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "save", requestOptions);
    };

    const update = (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "update", requestOptions);
    };

    const deleteOne = async (id) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: id,
        };
        return authProvider.authFetch(basePath + "delete", requestOptions);
    };

    return {
        findOne,
        findAll,
        save,
        update,
        deleteOne,
    };
}

export default ReaderService;
