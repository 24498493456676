import React, {useEffect, useState} from "react";
import {FileUploader, LoadPanel} from "devextreme-react";
import {useTranslation} from "react-i18next";
import ChangePassword from "../components/ChangePassword";
import DashboardLayout from "../../../layouts/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import Grid from "@mui/material/Grid";
import SuiTypography from "../../../components/SuiTypography";
import SuiAvatar from "../../../components/SuiAvatar";
import ProfileService from "./ProfileService";
import avatar from "assets/images/faces/avatar.png";

function ProfilePage() {
    const profileService = ProfileService();
    const {t} = useTranslation();

    useEffect(() => {}, []);
    const [showLoadPanel, setShowLoadPanel] = useState(false);
    const [data, setData] = useState();
    const [imageSrc, setImageSrc] = useState();

    useEffect(() => {
        profileService.findOne().then((result) => {
            setData(result);
            if (result.personAvatarId) {
                setImageSrc(
                    process.env.REACT_APP_API_URL +
                        "binaryContents/getPublicImage/" +
                        result.personAvatarId +
                        "?timestamp=" +
                        new Date()
                );
            } else {
                setImageSrc(avatar);
            }
        });
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("document") + " " + t("lists")}
                icon="filter_none"
                fontSize={"medium"}
            />
            <SuiBox p={2} shadow="none" bgColor="transparent">
                <Grid container spacing={3} alignItems="center" sx={{pl: 1}}>
                    <Grid item sx={{ml: 1}}>
                        <SuiAvatar
                            src={imageSrc}
                            alt="profile-image"
                            variant="rounded"
                            size="xl"
                            shadow="sm"
                        />
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                        <SuiBox height="100%" mt={0.5} lineHeight={1}>
                            <SuiTypography variant="h5" fontWeight="medium">
                                {data ? data.name + " " + data.surName : ""}
                            </SuiTypography>
                        </SuiBox>
                    </Grid>

                    <Grid item xs={8} md={8} lg={8}>
                        {ChangePassword()}
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <FileUploader
                            selectButtonText={t("{{key, capitalize}}", {key: "select.image"})}
                            labelText={t("drop.image")}
                            accept="image/*"
                            uploadMode="useForm"
                            onValueChanged={(val) => {
                                setShowLoadPanel(true);
                                profileService.update({avatar: val.value[0]}).then(
                                    () => {
                                        profileService.findOne().then((result) => {
                                            setData(result);
                                            if (result.personAvatarId) {
                                                setImageSrc(
                                                    process.env.REACT_APP_API_URL +
                                                        "binaryContents/getPublicImage/" +
                                                        result.personAvatarId +
                                                        "?timestamp=" +
                                                        new Date()
                                                );
                                            } else {
                                                setImageSrc(avatar);
                                            }
                                        });
                                        setShowLoadPanel(false);
                                    },
                                    () => {
                                        setShowLoadPanel(false);
                                    }
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </SuiBox>
            <Footer />

            <LoadPanel
                visible={showLoadPanel}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={true}
            />
        </DashboardLayout>
    );
}

export default ProfilePage;
