import React, {useEffect, useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Form,
    Toolbar,
    Item,
    ColumnChooser,
    LoadPanel,
    RequiredRule,
    FilterRow,
    MasterDetail,
    Lookup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import {useTranslation} from "react-i18next";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import AuthProvider from "../../../providers/AuthProvider";
import ProcessorService from "./ProcessorService";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DepartmentTreeList from "../../share/departments/departmentTreeList";
import ReaderService from "../reader/ReaderService";
import {TagBox} from "devextreme-react";
import {EReaderType} from "../../../providers/AppConstantProvider";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Dialog, DialogTitle, Icon, ListItemIcon} from "@mui/material";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import SshViewer from "../ssh/SshViewer";
import {styled} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "devextreme-react/popup";
import {GroupItem, Tab, TabbedItem, TabPanelOptions} from "devextreme-react/form";
import jsObjects from "dropzone";

const authProvider = AuthProvider();
const processorService = ProcessorService();
const readerService = ReaderService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return processorService.findAll(loadOptions).then(
                (data) => ({
                    data: data.items,
                    totalCount: data.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return processorService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            return processorService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return processorService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return processorService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

const readerDataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return readerService.findAll(loadOptions).then(
                (data) => ({
                    data: data.items,
                    totalCount: data.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return readerService.findOne(key).then((response) => {
                return response;
            });
        },
    }),
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

let loginTypeData = [];

const getLoginTypeDataSource = (updateItem) => {
    console.log(loginTypeData);
    if (updateItem.value) {
        //console.log("updateItem.value");
        return updateItem.value;
    }
    //console.log("getAuthoritiesDataSource", authoritiesData);
    return loginTypeData;
};

function ProcessorPage() {
    const [dataGrid, setDataGrid] = useState();
    const {t} = useTranslation();

    // useEffect(() => {
    //     processorService.findAllLoginType().then((response) => {
    //         response.forEach((item) => {
    //             console.log(item);
    //             loginTypeData.push({
    //                 selected: false,
    //                 authority: item,
    //             });
    //         });
    //     });
    // }, []);

    const [isLdapDisatble, setIsLdapDisatble] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [processorItem, setProcessorItem] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onReaderInsertedUpdated = (d, val) => {
        let value = [];
        val.forEach((i) => {
            value.push({id: i});
        });
        d.setValue(value);
    };

    const onGridInsertedUpdated = (d, val) => {
        console.log("onGridInsertedUpdated", d, val);
        if (!d.value) {
            d.setValue([val.data]);
        } else {
            d.setValue(d.value);
        }
    };

    const editGridReaderCell = (d) => {
        const value = d.value ? d.value : [];
        return (
            <TagBox
                dataSource={readerDataSource}
                multiline={true}
                displayExpr="serialNumber"
                valueExpr="id"
                defaultValue={value.map((m) => m.id)}
                maxDisplayedTags={6}
                searchEnabled={true}
                onValueChange={(e) => {
                    onReaderInsertedUpdated(d, e);
                }}
                // hideSelectedItems={true}
            />
        );
    };

    const Rowkey = (d) => {
        if (d != null) {
            // let result = dataSource.items().find((obj) => {
            //     return obj.id === d;
            // });
            // console.log(result);
            // let result2 = result.loginTypes.find((obj) => {
            let result2 = d.data.loginTypes.find((obj) => {
                return obj.loginType === "ldap";
            });
            console.log(result2);
            setIsLdapDisatble(result2.isActive);
            console.log(isLdapDisatble);
            // let result = jsObjects.find(dataList)=> {
            //     return dataList.id === 3;
            // });
            // isLdapDisatble = result.items().find((obj) => {
            //     return obj.id === d;
            // });
        }
    };

    const rowKeyUpdate = (d) => {
        setIsLdapDisatble(d.data.ldapEnable);
    };

    const renderLdapSetting = (a) => {
        console.log(a);
    };

    const editLoginTypeCell = (d) => {
        if (!d.value) {
            d.setValue(loginTypeData);
        }
        return (
            <DataGrid
                dataSource={getLoginTypeDataSource(d)}
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
                onRowUpdated={(val) => {
                    onGridInsertedUpdated(d, val);
                }}>
                <Editing mode="cell" allowUpdating={true} />
                <FilterRow visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                <Toolbar>
                    <Item name="searchPanel" />
                </Toolbar>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

                <Column
                    caption={t("{{key, capitalize}}", {key: "state"})}
                    width={150}
                    dataField="isActive"
                    dataType="boolean"
                    setCellValue={(newData, value) => {
                        newData.isActive = value;
                    }}
                />
                <Column
                    caption={t("{{key, capitalize}}", {key: "description"})}
                    dataField="loginType"
                    dataType="string"
                    allowEditing={false}
                />
            </DataGrid>
        );
    };

    const detailTemplate = (d) => {
        return (
            <DataGrid
                dataSource={d.row.data.readerList}
                allowSearch={false}
                allowFiltering={false}
                allowSorting={false}>
                <Toolbar>
                    <Item location="before">{t("{{key, capitalize}}", {key: "reader.list"})}</Item>
                </Toolbar>
                <Column
                    caption={t("{{key, capitalize}}", {key: "reader.type"})}
                    dataField="readerType"
                    width="12%">
                    <Lookup
                        dataSource={EReaderType}
                        displayExpr={(row) => {
                            return t("{{key, capitalize}}", {
                                key: row.value,
                            });
                        }}
                        valueExpr="value"
                    />
                </Column>
                <Column
                    caption={t("{{key, capitalize}}", {key: "serial.number"})}
                    dataField="serialNumber"
                    dataType="text"
                />
            </DataGrid>
        );
    };

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("processor") + " " + t("lists")}
                icon="corporate_fare"
                fontSize={"medium"}
            />
            <SuiBox mt={2} p={3} color="red" shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    onEditingStart={Rowkey}
                    showBorders={false}
                    remoteOperations={true}
                    rowAlternationEnabled={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Editing
                        mode="popup"
                        allowUpdating={authProvider.isAuth("processorUpdate")}
                        allowAdding={authProvider.isAuth("processorSave")}
                        allowDeleting={false}
                        // onChangesChange={Rowkey}
                        // onEditRowKeyChange={Rowkey}
                        // editRowKey={Rowkey}
                    >
                        <Form>
                            <GroupItem
                                itemType="group"
                                caption={t("{{key, capitalize}}", {
                                    key: "general.information",
                                })}>
                                <Item dataField="department" />
                                <Item dataField="code" />
                            </GroupItem>

                            <GroupItem
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "reader.information"})}>
                                <Item dataField="readerList" />
                            </GroupItem>

                            <GroupItem
                                colSpan={2}
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "sip.information"})}>
                                <Item dataField="loginTypes" />
                            </GroupItem>

                            <GroupItem
                                colSpan={2}
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "sip.information"})}>
                                <TabbedItem>
                                    <Tab
                                        colCount={3}
                                        title={t("{{key, capitalize}}", {key: "read.point"})}>
                                        <Item dataField="sipSettings.ip" />
                                        <Item dataField="sipSettings.port" />
                                        <Item dataField="sipSettings.username" />
                                        <Item dataField="sipSettings.password" />
                                        <Item dataField="sipSettings.connectionTimeout" />
                                        <Item dataField="sipSettings.idleTimeout" />
                                        <Item dataField="sipSettings.retryAttempts" />
                                        <Item dataField="sipSettings.retryWait" />
                                        <Item dataField="sipSettings.loginActive" />
                                    </Tab>

                                    <Tab
                                        colCount={2}
                                        title={t("{{key, capitalize}}", {
                                            key: "processor.settings",
                                        })}>
                                        <Item dataField="processorSettings.selfcheckIdleTime" />
                                    </Tab>

                                    <Tab
                                        disabled={isLdapDisatble == false}
                                        colCount={3}
                                        title={t("{{key, capitalize}}", {key: "ldap.information"})}>
                                        <Item dataField="ldapSetting.ldapServer" />
                                        <Item dataField="ldapSetting.ldapBaseDn" />
                                        <Item dataField="ldapSetting.ldapUsername" />
                                        <Item dataField="ldapSetting.ldapPassword" />
                                    </Tab>

                                    {/*<Tab*/}
                                    {/*    // tabRender={renderLdapSetting}*/}
                                    {/*    render={renderLdapSetting}*/}
                                    {/*    disabled={isLdapDisatble == false}*/}
                                    {/*    title={t("{{key, capitalize}}", {key: "read.point"})}*/}
                                    {/*/>*/}
                                    {/*//     <Item render={render} />*/}
                                    {/*//     <Item dataField="sipSettings.port" />*/}
                                    {/*//     <Item dataField="sipSettings.username" />*/}
                                    {/*//     <Item dataField="sipSettings.password" />*/}
                                    {/*//     <Item dataField="sipSettings.connectionTimeout" />*/}
                                    {/*//     <Item dataField="sipSettings.idleTimeout" />*/}
                                    {/*//     <Item dataField="sipSettings.retryAttempts" />*/}
                                    {/*//     <Item dataField="sipSettings.retryWait" />*/}
                                    {/*// </Tab>*/}
                                </TabbedItem>
                            </GroupItem>
                        </Form>
                    </Editing>
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />
                    <Column
                        allowFiltering={false}
                        caption=""
                        dataField="id"
                        dataType="string"
                        width="80"
                        cellRender={(item) => {
                            return (
                                <>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? "long-menu" : undefined}
                                        aria-expanded={open ? "true" : undefined}
                                        variant="contained"
                                        aria-haspopup="true"
                                        onClick={(evt) => {
                                            setProcessorItem(item.data);
                                            handleClick(evt);
                                        }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}>
                                        <MenuItem
                                            onClick={() => {
                                                setOpenDialog(true);
                                                handleClose();
                                            }}>
                                            <ListItemIcon>
                                                <Icon fontSize="small">terminal</Icon>
                                            </ListItemIcon>
                                            <Typography variant="inherit">ssh</Typography>
                                        </MenuItem>
                                    </Menu>
                                </>
                            );
                        }}
                    />

                    <Column caption={t("{{key, capitalize}}", {key: "processor.information"})}>
                        <Column
                            caption={t("{{key, capitalize}}", {key: "department"})}
                            dataField="department"
                            cellRender={(cr) => {
                                return <div>{cr.data.department.name}</div>;
                            }}
                            editCellRender={(ecr) => {
                                return (
                                    <DepartmentTreeList
                                        value={ecr.value && ecr.value.id ? ecr.value.id : null}
                                        onValueChanged={(e) => {
                                            ecr.setValue(e);
                                        }}
                                    />
                                );
                            }}>
                            <RequiredRule />
                        </Column>

                        <Column
                            caption={t("{{key, capitalize}}", {key: "code"})}
                            dataField="code"
                            dataType="string">
                            <RequiredRule />
                        </Column>
                    </Column>
                    <Column caption={t("{{key, capitalize}}", {key: "sip.information"})}>
                        <Column
                            caption={t("{{key, capitalize}}", {key: "ip"})}
                            dataField="sipSettings.ip"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "port"})}
                            dataField="sipSettings.port"
                            dataType="string"
                        />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "username"})}
                        dataField="sipSettings.username"
                        dataType="string"
                        visible={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "password"})}
                        dataField="sipSettings.password"
                        dataType="string"
                        visible={false}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "connection.timeout"})}
                        dataField="sipSettings.connectionTimeout"
                        dataType="string"
                        visible={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "idle.timeout"})}
                        dataField="sipSettings.idleTimeout"
                        dataType="string"
                        visible={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "retry.attempts"})}
                        dataField="sipSettings.retryAttempts"
                        dataType="string"
                        visible={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "retry.wait"})}
                        dataField="sipSettings.retryWait"
                        dataType="string"
                        visible={false}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "login.active"})}
                        dataField="sipSettings.loginActive"
                        dataType="boolean"
                        width={120}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "selfcheck.idletime"})}
                        dataField="processorSettings.selfcheckIdleTime"
                        dataType="string"
                        visible={false}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "ldap.server"})}
                        dataField="ldapSetting.ldapServer"
                        dataType="string"
                        visible={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "ldap.basedn"})}
                        dataField="ldapSetting.ldapBaseDn"
                        dataType="string"
                        visible={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "ldap.username"})}
                        dataField="ldapSetting.ldapUsername"
                        dataType="string"
                        visible={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "ldap.password"})}
                        dataField="ldapSetting.ldapPassword"
                        dataType="string"
                        visible={false}
                    />

                    <Column
                        caption=" "
                        visible={false}
                        allowSearch={false}
                        allowFiltering={false}
                        dataField="readerList"
                        editCellRender={editGridReaderCell}
                    />

                    <Column
                        caption=" "
                        visible={false}
                        allowSearch={false}
                        allowFiltering={false}
                        dataField="loginTypes"
                        editCellRender={editLoginTypeCell}
                    />

                    <MasterDetail enabled={true} render={detailTemplate} />
                </DataGrid>
            </SuiBox>
            <Footer />

            {/*<Popup*/}
            {/*    visible={openDialog}*/}
            {/*    showCloseButton={true}*/}
            {/*    showTitle={true}*/}
            {/*    title={processorItem ? processorItem.name : ""}*/}
            {/*    closeOnOutsideClick={false}*/}
            {/*    fullScreen={true}*/}
            {/*    onHiding={(e) => {*/}
            {/*        setOpenDialog(false);*/}
            {/*    }}>*/}
            {/*    {openDialog ? <SshViewer item={processorItem} /> : null}*/}
            {/*</Popup>*/}

            <BootstrapDialog
                fullWidth={true}
                maxWidth="xl"
                onClose={() => {
                    // setReportItem(null);
                }}
                aria-labelledby="customized-dialog-title"
                open={openDialog}>
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setOpenDialog(false);
                    }}>
                    {processorItem ? processorItem.name : ""}
                </BootstrapDialogTitle>
                <DialogContent sx={{mt: 1.5}}>
                    <SshViewer item={processorItem} />
                </DialogContent>
            </BootstrapDialog>
        </DashboardLayout>
    );
}

export default ProcessorPage;
