import React, {useEffect, useState} from "react";
import {XTerm} from "xterm-for-react";
import {FitAddon} from "xterm-addon-fit";
import {WebLinksAddon} from "xterm-addon-web-links";
import {SearchAddon} from "xterm-addon-search";
import {
    closeWebSocket,
    initWebsocket,
    sendWebSocketMessage,
} from "../../../providers/WebSocketProvider";
import {useTranslation} from "react-i18next";
import SuiSnackbar from "../../../components/SuiSnackbar";

let timeOutId;

function SshViewer({item}) {
    const {t} = useTranslation();

    const fitAddon = new FitAddon();
    const webLinksAddon = new WebLinksAddon();
    const searchAddon = new SearchAddon();

    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
    const [snackbarContent, setSnackbarContent] = useState("");

    const sshReturnFunction = (e) => {
        const data = e.detail;
        switch (data.command) {
            case "open":
                data.command = "offer";
                sendWebSocketMessage(data);
                timeOutId = setTimeout(() => {
                    setSnackbarContent(t("sure.device.turn.on"));
                    setShowSnackbar(true);
                    closeWebSocket();
                }, 5000);
                break;
            case "answer":
                if (timeOutId) {
                    clearTimeout(timeOutId);
                }
                if (xtermRef.current && xtermRef.current.terminal) {
                    fitAddon.fit();
                    const terminalData = {
                        cols: xtermRef.current.terminal.cols,
                        rows: xtermRef.current.terminal.rows,
                    };
                    sendData("sshConnect", terminalData);
                }
                break;
            case "sshData":
                if (xtermRef.current && xtermRef.current.terminal)
                    xtermRef.current.terminal.write(data.data);
                break;
            default:
        }
    };

    useEffect(() => {
        xtermRef.current.terminal.writeln("waiting");
        initWebsocket(item.code, "ssh");
        window.addEventListener("socket", sshReturnFunction);
        return () => {
            sendData("sshClose", null);
            closeWebSocket();
        };
    }, []);

    const xtermRef = React.useRef(null);

    const sendData = (evt, value) => {
        const msg = {
            code: item.code,
            command: evt,
            data: value,
        };
        sendWebSocketMessage(msg);
    };

    return (
        <>
            <XTerm
                addons={[searchAddon, webLinksAddon, fitAddon]}
                ref={xtermRef}
                onData={(val) => {
                    sendData("sshData", val);
                }}
                onResize={(evt) => {
                    const terminalData = {
                        cols: evt.cols,
                        rows: evt.rows,
                    };
                    sendData("sshResize", terminalData);
                }}
            />
            <SuiSnackbar
                sx={{maxWidth: 400}}
                color="error"
                icon="error"
                title={t("{{key, capitalize}}", {key: "error"})}
                content={snackbarContent}
                dateTime=""
                autoHideDuration={10000}
                open={showSnackbar}
                onClose={toggleSnackbar}
                close={toggleSnackbar}
            />
        </>
    );
}

export default SshViewer;
