import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import React, {useState} from "react";
import avatar from "assets/images/faces/logo.png";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Form,
    Toolbar,
    Item,
    ColumnChooser,
    LoadPanel,
    Lookup,
    RequiredRule,
    FilterRow,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import PersonService from "../PersonService";
import {FileUploader} from "devextreme-react";
import AuthProvider from "../../../../providers/AuthProvider";
import {EContactType} from "../../../../providers/ConstantProvider";
import {useTranslation} from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const personService = PersonService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return personService.findAll(loadOptions).then(
                (data) => ({
                    data: data.items,
                    totalCount: data.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return personService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            values.personType = "LegalEntity";
            return personService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return personService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return personService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
    filter: ["personType", "=", "LegalEntity"],
});

function LegalEntityPage() {
    // useEffect(()=>{},[])
    const [dataGrid, setDataGrid] = useState();
    const {t} = useTranslation();

    const renderGridPhotoCell = (val) => {
        const d = val.data ? val.data : val;
        return (
            <img
                width="60"
                src={
                    d.value
                        ? `${process.env.REACT_APP_API_URL}binaryContents/getPublicImage/` + d.value
                        : avatar
                }
                alt="Person"
            />
        );
        // return <img width="60" src={d.value ? baseUrl + 'binaryContents/getPublicImage/' + d.value :  require('assets/images/faces/avatar.png')} alt="Person Photo"/>;
    };

    const editGridPhotoCell = (d) => {
        return (
            <FileUploader
                selectButtonText={t("{{key, capitalize}}", {key: "select.image"})}
                labelText={t("drop.image")}
                accept="image/*"
                uploadMode="useForm"
                onValueChanged={(val) => {
                    d.setValue(val.value[0]);
                }}
            />
        );
    };

    const onContactInsertedUpdated = (d, val) => {
        if (!d.value) {
            d.setValue([val.data]);
        } else {
            d.setValue(d.value);
        }
    };

    const editGridContactCell = (d) => {
        const value = d.value ? d.value : [];
        return (
            <DataGrid
                dataSource={value}
                allowSearch={false}
                allowFiltering={false}
                allowSorting={false}
                onRowInserted={(val) => {
                    onContactInsertedUpdated(d, val);
                }}
                onRowUpdated={(val) => {
                    onContactInsertedUpdated(d, val);
                }}
                onRowRemoved={(val) => {
                    onContactInsertedUpdated(d, val);
                }}>
                <Editing mode="form" allowUpdating={true} allowAdding={true} allowDeleting={true} />
                <Column
                    caption={t("{{key, capitalize}}", {key: "contact.type"})}
                    dataField="contactType"
                    width={200}>
                    <Lookup
                        dataSource={EContactType}
                        displayExpr={(row) => {
                            return t("{{key, capitalize}}", {
                                key: row.value,
                            });
                        }}
                        valueExpr="value"
                    />
                </Column>
                <Column
                    caption={t("{{key, capitalize}}", {key: "value"})}
                    dataField="label"
                    dataType="string"
                />
            </DataGrid>
        );
    };

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("legalEntity") + " " + t("lists")}
                icon="corporate_fare"
                fontSize={"medium"}
            />
            <SuiBox
                mt={2}
                p={3}
                color="red"
                shadow="md"
                bgColor="white"
                sx={{borderRadius: 2}}
                // sx={{ borderRadius: 3, minHeight: "calc(100vh - 230px)"}}
                // borderRadius="lg"
                // height="calc(100vh - 230px)"
            >
                <DataGrid
                    dataSource={dataSource}
                    showBorders={false}
                    remoteOperations={true}
                    rowAlternationEnabled={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}
                    // ref={getRef}
                >
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Editing
                        mode="form"
                        allowUpdating={authProvider.isAuth("personUpdate")}
                        allowAdding={authProvider.isAuth("personSave")}
                        allowDeleting={false}>
                        <Form>
                            <Item
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "general.information"})}>
                                <Item dataField="identityNumber" />
                                <Item dataField="name" />
                                <Item dataField="image" />
                            </Item>
                            <Item
                                itemType="group"
                                caption={t("{{key, capitalize}}", {key: "contact.information"})}>
                                <Item dataField="contacts" />
                            </Item>
                        </Form>
                    </Editing>
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "image"})}
                        dataField="avatarId"
                        dataType="string"
                        width={90}
                        cellComponent={renderGridPhotoCell}
                        allowSearch={false}
                        allowFiltering={false}
                        allowSorting={false}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "tax.number"})}
                        dataField="identityNumber"
                        dataType="number"
                        width="150">
                        <RequiredRule />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "title"})}
                        dataField="name"
                        dataType="string"
                        sortOrder="asc">
                        <RequiredRule />
                    </Column>
                    <Column
                        dataField="image"
                        caption={t("{{key, capitalize}}", {key: "image"})}
                        visible={false}
                        editCellRender={editGridPhotoCell}
                        allowSearch={false}
                        allowFiltering={false}
                    />
                    <Column
                        caption=" "
                        visible={false}
                        allowSearch={false}
                        allowFiltering={false}
                        dataField="contacts"
                        editCellRender={editGridContactCell}
                    />
                </DataGrid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default LegalEntityPage;
