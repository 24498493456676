// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Soft UI Dashboard PRO React helper functions
// import pxToRem from "assets/theme/functions/pxToRem";

const {size} = typography;
const {text} = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            fontSize: size.md,
            color: text.main,
        },
    },
};
