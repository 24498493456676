import AuthProvider from "../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "processor/";
function ProcessorService() {
    const findOne = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
    };

    const findAll = async (loadOptions) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(loadOptions),
        };
        return authProvider.authFetch(basePath + "findAll", requestOptions);
    };

    const save = async (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "save", requestOptions);
    };

    const update = (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "update", requestOptions);
    };

    const deleteOne = async (id) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: id,
        };
        return authProvider.authFetch(basePath + "delete", requestOptions);
    };

    const findAllAntenna = async (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "findAllAntenna", requestOptions);
    };

    const findOneAntenna = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOneAntenna/" + id, requestOptions);
    };

    const findAllReader = async (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "findAllReader", requestOptions);
    };

    const findOneReader = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOneReader/" + id, requestOptions);
    };

    const findAllLoginType = async () => {
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
        };
        return authProvider.authFetch("logintype/findAllLoginType", requestOptions);
    };

    return {
        findOne,
        findAll,
        save,
        update,
        deleteOne,
        findAllAntenna,
        findOneAntenna,
        findAllReader,
        findOneReader,
        findAllLoginType,
    };
}

export default ProcessorService;
