// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import PageLayout from "../PageLayout";
import Footer from "../../examples/Footer";
import {useEffect, useState} from "react";
import {getLangLocalStorage, setLangLocalStorage} from "../../providers/LanguageProvider";
import SuiSelect from "../../components/SuiSelect";
// import {QRCodeSVG} from "qrcode.react";
// import ScrollView from "devextreme-react/scroll-view";

function CoverLayout({
    // color,
    header,
    // title,
    description,
    imageLeft,
    imageRight,
    icon,
    // top,
    children,
    qrcode,
}) {
    const [lang, setLang] = useState();
    const [languages, setLanguages] = useState();
    const getLanguages = () => {
        if (localStorage.getItem("langs")) {
            const options = JSON.parse(localStorage.getItem("langs")).map((item) => ({
                value: item.lang,
                label: item.title,
            }));
            setLanguages(options);
            getLangLocalStorage().then((lng) => {
                setLang(lng);
            });
        } else {
            setTimeout(() => getLanguages(), 400);
        }
    };

    useEffect(() => {
        getLanguages();
    }, []);

    return (
        <PageLayout background="#F2F2F2">
            <Grid
                container
                sx={{
                    height: "100%",
                    width: "100%",
                    margin: 0,
                }}>
                <Grid item xs={12} sm={0} md={4} xl={4}>
                    <SuiBox height="100%" display={{xs: "none", sm: "none", md: "block"}}>
                        <SuiBox
                            mt={18.2}
                            width="auto"
                            height="100%"
                            alignItems="right"
                            display="flex"
                            sx={{
                                backgroundImage: `url(${imageLeft})`,
                                backgroundSize: {md: "16rem", lg: "27rem"},
                                backgroundPosition: "right",
                                backgroundRepeat: "no-repeat",
                            }}
                        />
                    </SuiBox>
                </Grid>
                <Grid
                    xs={12}
                    sm={12}
                    md={4}
                    xl={4}
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch">
                    <SuiBox
                        height="100%"
                        width="100%"
                        bgColor="white"
                        mt={{xs: 0, sm: 0, md: 4, lg: 10}}
                        borderRadius="lg"
                        boxShadow="3">
                        <Grid>
                            <SuiBox height="100%" display={{sm: "block", md: "none"}}>
                                <SuiBox
                                    display={{sm: "block", md: "none"}}
                                    height="1%"
                                    color="transparent"
                                    width="100%"
                                    sx={{justifyContent: "flex-end"}}
                                    zIndex={3}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-end">
                                        <SuiBox
                                            height="100%"
                                            color="transparent"
                                            width="6rem"
                                            pr={2}
                                            mr={0}>
                                            {lang ? (
                                                <SuiSelect
                                                    isLight={false}
                                                    size="large"
                                                    defaultValue={{
                                                        value: lang,
                                                        label: lang.toUpperCase(),
                                                    }}
                                                    options={languages}
                                                    onChange={(e) => {
                                                        setLangLocalStorage(e.value);
                                                    }}
                                                />
                                            ) : null}
                                        </SuiBox>
                                    </Grid>
                                </SuiBox>
                            </SuiBox>
                            <SuiBox
                                pt={{xs: 0, sm: 3}}
                                px={3}
                                direction="column"
                                justifyContent="space-between"
                                alignItems="center">
                                <SuiBox mb={{xs: 1, sm: 2, md: 4, lg: 5}}>
                                    <img src={icon} alt="Libref Logo" style={{width: "8rem"}} />
                                </SuiBox>
                                <SuiBox display={{xs: "none", md: "block"}}>
                                    <SuiTypography variant="h2" fontWeight="bold" color="dark">
                                        {description}
                                    </SuiTypography>
                                </SuiBox>
                            </SuiBox>
                            <SuiBox mb={3} mt={{xs: 0, sm: 2, md: 0}} p={3}>
                                {children}
                            </SuiBox>
                        </Grid>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} sm={0} md={4} xl={4}>
                    <SuiBox height="100%" display={{xs: "none", sm: "none", md: "block"}}>
                        <SuiBox
                            height="1%"
                            color="transparent"
                            width="100%"
                            sx={{justifyContent: "flex-end"}}
                            zIndex={3}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-end">
                                <SuiBox
                                    height="100%"
                                    color="transparent"
                                    width="6rem"
                                    pr={2}
                                    style={{marginRight: qrcode ? 64 : 0}}>
                                    {lang ? (
                                        <SuiSelect
                                            isLight={false}
                                            size="large"
                                            defaultValue={{
                                                value: lang,
                                                label: lang.toUpperCase(),
                                            }}
                                            options={languages}
                                            onChange={(e) => {
                                                setLangLocalStorage(e.value);
                                            }}
                                        />
                                    ) : null}
                                </SuiBox>
                                {/* {qrcode && (
                                    <QRCodeSVG
                                        value={qrcode}
                                        size={128}
                                        style={{marginRight: 64}}
                                    />
                                )} */}
                            </Grid>
                        </SuiBox>
                        <SuiBox
                            mt={{xs: 0, md: 14.4, lg: 12.1}}
                            width="auto"
                            height="100%"
                            display={{xs: "none", sm: "none", md: "block"}}
                            sx={{
                                backgroundImage: `url(${imageRight})`,
                                backgroundSize: {md: "16rem", lg: "27rem"},
                                backgroundPosition: "left",
                                backgroundRepeat: "no-repeat",
                            }}></SuiBox>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Footer />
                </Grid>
            </Grid>
        </PageLayout>
    );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
    header: "",
    title: "",
    description: "",
    color: "librefLogin",
    // top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "librefLogin",
    ]),
    header: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    imageLeft: PropTypes.string.isRequired,
    imageRight: PropTypes.string.isRequired,
    // top: PropTypes.number,
    children: PropTypes.node.isRequired,
};

export default CoverLayout;
