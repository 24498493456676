import AuthProvider from "../../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "gate/";
function GateService() {
    const sendSta = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "sendSta/" + id, requestOptions);
    };
    return {
        sendSta,
    };
}

export default GateService;
