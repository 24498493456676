import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {CardActions, CardHeader} from "@mui/material";
import {useTranslation} from "react-i18next";
import {confirm} from "devextreme/ui/dialog";
import DeviceService from "../../device/DeviceService";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import {LoadPanel} from "devextreme-react";
import Form, {GroupItem, RequiredRule, SimpleItem} from "devextreme-react/form";
import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    Item,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    Toolbar,
    TotalItem,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import Button from "devextreme-react/button";
import {
    closeWebSocket,
    initWebsocket,
    sendWebSocketMessage,
} from "../../../../providers/WebSocketProvider";
import SuiSnackbar from "../../../../components/SuiSnackbar";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import Avatar from "@mui/material/Avatar";
import notify from "devextreme/ui/notify";
import {DoNotDisturbAlt, Edit} from "@mui/icons-material";
import colors from "../../../../assets/theme/base/colors";
import Footer from "../../../../examples/Footer";

const deviceService = DeviceService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return deviceService.findAllBy(loadOptions).then(
                (data) => ({
                    data: data.items,
                    totalCount: data.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return deviceService.findOne(key).then((response) => {
                return response;
            });
        },
    }),
    filter: ["product.id", "=", 1],
});

let timeOutId;

function MatchPage() {
    const {t} = useTranslation();
    const [gridData, setGridData] = useState();
    const deviceDataRef = useRef();
    const [dataGrid, setDataGrid] = useState();
    const [form, setForm] = useState();
    const [itemInfo, setItemInfo] = useState("");
    const [saveButton, setSaveButton] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [readData, setReadData] = useState([]);

    const [fixture, setFixture] = useState({});

    const [showLoadPanel, setShowLoadPanel] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
    const [snackbarContent, setSnackbarContent] = useState("");

    const returnFunction = (e) => {
        const data = e.detail;
        switch (data.command) {
            case "open":
                data.command = "offer";
                setShowLoadPanel(true);
                sendWebSocketMessage(data);
                if (timeOutId) {
                    clearTimeout(timeOutId);
                }
                timeOutId = setTimeout(() => {
                    setSnackbarContent(t("sure.device.turn.on"));
                    setShowSnackbar(true);
                    closeWebSocket();
                    setShowLoadPanel(false);
                }, 10000);
                return;
            case "answer":
                data.command = "read";
                data.data = deviceDataRef.current;
                setReadData([]);
                sendWebSocketMessage(data);

                setTimeout(() => {
                    setShowModal(true);
                }, 100);
                setShowLoadPanel(false);
                break;
            case "itemInfo":
                setItemInfo(data.data);
                if (data.data && data.data["titleIdentifier"]) {
                    setSaveButton(true);
                }

                setTimeout(() => {
                    setShowModal(true);
                }, 100);
                setShowLoadPanel(false);
                break;
            case "read":
                setReadData(data.data);
                return;
            case "write":
                setShowLoadPanel(false);
                notify({
                    position: "top center",
                    message: t("{{key, capitalize}}", {key: "tag.match.success"}),
                    type: "success",
                    displayTime: 3000,
                    height: 50,
                    shading: false,
                });
                setFixture({});
                setItemInfo("");
                setTimeout(() => {
                    setShowLoadPanel(false);
                }, 1000);

                break;
            case "clean":
                setShowLoadPanel(false);
                notify({
                    position: "top center",
                    message: t("{{key, capitalize}}", {key: "tag.clean.success"}),
                    type: "success",
                    displayTime: 3000,
                    height: 50,
                    shading: false,
                });
                setFixture({});
                setItemInfo("");
                setTimeout(() => {
                    setShowLoadPanel(false);
                }, 1000);

                break;
            case "kill":
                setShowLoadPanel(false);
                notify({
                    position: "top center",
                    message: t("{{key, capitalize}}", {key: "tag.kill.success"}),
                    type: "success",
                    displayTime: 3000,
                    height: 50,
                    shading: false,
                });
                setTimeout(() => {
                    setShowLoadPanel(false);
                }, 1000);
                break;
            case "error":
                setShowLoadPanel(false);
                setShowSnackbar(true);
                setSnackbarContent(t("{{key, capitalize}}", {key: data.data}));
                break;
            default:
        }
        if (timeOutId) {
            clearTimeout(timeOutId);
        }
    };

    useEffect(() => {
        window.addEventListener("socket", returnFunction);
        return () => {
            setSaveButton(false);
            closeWebSocket();
            window.removeEventListener("socket", returnFunction);
            if (timeOutId) {
                clearTimeout(timeOutId);
            }
        };
    }, []);

    const writeEPCValue = (data) => {
        setShowLoadPanel(true);
        timeOutId = setTimeout(() => {
            setSnackbarContent(
                t("{{key, capitalize}}", {
                    key: "sure.device.turn.on",
                })
            );
            setShowSnackbar(true);
            setShowLoadPanel(false);
        }, 10000);
        sendWebSocketMessage(data);
    };

    const clean = (data) => {
        setShowLoadPanel(true);
        timeOutId = setTimeout(() => {
            setSnackbarContent(
                t("{{key, capitalize}}", {
                    key: "sure.device.turn.on",
                })
            );
            setShowSnackbar(true);
            setShowLoadPanel(false);
        }, 10000);
        sendWebSocketMessage(data);
    };

    const kill = (data) => {
        setShowLoadPanel(true);
        timeOutId = setTimeout(() => {
            setSnackbarContent(
                t("{{key, capitalize}}", {
                    key: "sure.device.turn.on",
                })
            );
            setShowSnackbar(true);
            setShowLoadPanel(false);
        }, 10000);
        sendWebSocketMessage(data);
    };

    const getItemInfo = () => {
        if (form.instance.validate().isValid) {
            let data = {
                command: "itemInfo",
                data: {
                    itemIdentifier: fixture.fixtureNumber,
                },
            };
            setShowLoadPanel(true);
            timeOutId = setTimeout(() => {
                setSnackbarContent(
                    t("{{key, capitalize}}", {
                        key: "sure.device.turn.on",
                    })
                );
                setShowSnackbar(true);
                setShowLoadPanel(false);
            }, 10000);
            sendWebSocketMessage(data);
        }
    };
    const {light, dark} = colors;
    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("device") + " " + t("lists")}
                icon="devices"
                fontSize="medium"
                onChangeSearch={(e) => {
                    dataGrid.instance.searchByText(e);
                }}
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    className="hidePanelDataGrid"
                    dataSource={dataSource}
                    allowSearch={false}
                    allowFiltering={false}
                    allowSorting={true}
                    remoteOperations={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}
                    onContentReady={(e) => {
                        setGridData(e.component.getDataSource().items());
                    }}>
                    <FilterRow visible={false} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Paging defaultPageSize={5} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "name"})}
                        dataField="name"
                        dataType="string"
                        sortOrder="asc"
                    />
                </DataGrid>

                <Grid container mt={2} spacing={0} bgcolor="transparent">
                    {gridData &&
                        gridData.map((val) => {
                            return (
                                <Grid
                                    component={Card}
                                    item
                                    key={val.id}
                                    ml={1}
                                    mr={2}
                                    mb={1}
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={3}>
                                    <Card
                                        sx={{
                                            width: "100%",
                                            cursor: "pointer",
                                            bgcolor: "#f2f2f2",
                                        }}
                                        onClick={() => {
                                            timeOutId = setTimeout(() => {
                                                setSnackbarContent(
                                                    t("{{key, capitalize}}", {
                                                        key: "sure.device.turn.on",
                                                    })
                                                );
                                                setShowSnackbar(true);
                                                setShowLoadPanel(false);
                                            }, 10000);

                                            setShowLoadPanel(true);
                                            deviceDataRef.current = val;
                                            initWebsocket(val.id);
                                        }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    sx={{bgcolor: dark.main}}
                                                    aria-label="recipe">
                                                    {t("{{key, capitalize}}", {
                                                        key: "match.m",
                                                    })}
                                                </Avatar>
                                            }
                                            title={<b style={{fontSize: "20px"}}>{val.name}</b>}
                                        />
                                        <CardMedia
                                            sx={{padding: 2}}
                                            component="img"
                                            width="100%"
                                            image={
                                                process.env.REACT_APP_API_URL +
                                                "binaryContents/getPublicImage/" +
                                                val.product.contentId
                                            }
                                            alt="thumbnail"
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                style={{textAlign: "center", marginTop: "15px"}}>
                                                {val.processor.department.name}
                                            </Typography>
                                        </CardContent>
                                        <CardActions
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    color:
                                                        val.deviceStatus === "idle"
                                                            ? "green"
                                                            : "red",
                                                }}
                                                component="div">
                                                {t("{{key, capitalize}}", {key: val.deviceStatus})}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>

                <LoadPanel
                    visible={showLoadPanel}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={true}
                />

                <SuiSnackbar
                    sx={{maxWidth: 400, zIndex: 10001}}
                    color="error"
                    icon="error"
                    title={t("{{key, capitalize}}", {key: "error"})}
                    content={snackbarContent}
                    dateTime=""
                    autoHideDuration={10000}
                    open={showSnackbar}
                    onClose={toggleSnackbar}
                    close={toggleSnackbar}
                />
            </SuiBox>

            <Popup
                visible={showModal}
                showCloseButton={true}
                showTitle={true}
                fullScreen={true}
                title={deviceDataRef && deviceDataRef.current ? deviceDataRef.current.name : null}
                closeOnOutsideClick={false}
                onHiding={() => {
                    setShowModal(false);
                    setSaveButton(false);
                    setItemInfo("");
                    setFixture({});
                }}>
                <ScrollView width="100%" height="100%">
                    <Form
                        id="form"
                        labelMode="static"
                        formData={fixture}
                        showValidationSummary={false}
                        visible={readData.length > 0}
                        onEditorEnterKey={(key) => {
                            getItemInfo();
                        }}
                        ref={(ref) => {
                            setForm(ref);
                        }}>
                        <GroupItem
                            colCount={12}
                            caption={t("{{key, capitalize}}", {key: "fixture.information"})}>
                            <SimpleItem
                                colSpan={10}
                                label={{text: t("{{key, capitalize}}", {key: "fixture.number"})}}
                                dataField="fixtureNumber">
                                <RequiredRule />
                            </SimpleItem>
                            <SimpleItem
                                colSpan={2}
                                editorType="dxButton"
                                editorOptions={{
                                    width: "100%",
                                    alignItems: "flex-end",
                                    text: t("find"),
                                    type: "default",
                                    icon: "search",
                                    onClick: () => {
                                        getItemInfo();
                                    },
                                }}></SimpleItem>
                            {itemInfo !== "" && itemInfo.titleIdentifier !== "" && (
                                <SimpleItem colSpan={12}>
                                    <table
                                        className="table"
                                        style={{
                                            width: "100%",
                                            textAlign: "left",
                                            fontSize: "16px",
                                        }}>
                                        <thead className={"dx-row dx-header-row"}>
                                            <tr>
                                                <th>
                                                    {" "}
                                                    {t("{{key, capitalize}}", {
                                                        key: "book.name",
                                                    })}
                                                </th>
                                                <th>
                                                    {t("{{key, capitalize}}", {
                                                        key: "book.location",
                                                    })}
                                                </th>
                                                <th>
                                                    {t("{{key, capitalize}}", {
                                                        key: "permanent.location",
                                                    })}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={"dx-datagrid dx-row"}>
                                            <tr>
                                                <td>{itemInfo["titleIdentifier"]}</td>
                                                <td>{itemInfo["callNumber"]}</td>
                                                <td>{itemInfo["permanentLocation"]}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleItem>
                            )}
                        </GroupItem>
                    </Form>
                    <DataGrid
                        noDataText={t("{{key, capitalize}}", {key: "match.nodatatext"})}
                        dataSource={readData}
                        allowSearch={false}
                        allowFiltering={false}
                        allowSorting={false}
                        remoteOperations={false}>
                        <FilterRow visible={false} />
                        <ColumnChooser enabled={false} />
                        <LoadPanel enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                        />

                        <Column
                            allowFiltering={false}
                            visible={true}
                            caption=""
                            dataField="write"
                            dataType="string"
                            width="150"
                            cellRender={(item) => {
                                return (
                                    <>
                                        <Button
                                            hint={t("{{key, capitalize}}", {
                                                key: "tag.write",
                                            })}
                                            visible={
                                                saveButton && readData.length > 0 && itemInfo !== ""
                                            }
                                            onClick={(event) => {
                                                if (item.data.itemIdentifier === undefined) {
                                                    let data = {
                                                        command: "write",
                                                        data: {
                                                            fixtureNumber:
                                                                form.props.formData.fixtureNumber,
                                                            collectDataItem: item.data,
                                                            writeType: "EpcUser",
                                                            userData: "0000",
                                                        },
                                                    };
                                                    writeEPCValue(data);
                                                } else {
                                                    let confirmMessageText = t(
                                                        "{{key, capitalize}}",
                                                        {
                                                            key: "match.overwrite.confirm.message",
                                                        }
                                                    );
                                                    let confirmMessageTitle = t(
                                                        "{{key, capitalize}}",
                                                        {
                                                            key: "match.overwrite.confirm.title",
                                                        }
                                                    );
                                                    let result = confirm(
                                                        confirmMessageText,
                                                        confirmMessageTitle
                                                    );
                                                    result.then((dialogResult) => {
                                                        if (dialogResult) {
                                                            let data = {
                                                                command: "write",
                                                                data: {
                                                                    fixtureNumber:
                                                                        form.props.formData
                                                                            .fixtureNumber,
                                                                    collectDataItem: item.data,
                                                                    writeType: "EpcUser",
                                                                    userData: "0000",
                                                                },
                                                            };
                                                            writeEPCValue(data);
                                                        }
                                                    });
                                                }
                                            }}>
                                            <Edit fontSize="medium" color="success" />
                                        </Button>
                                    </>
                                );
                            }}
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "item.identifier"})}
                            dataField="itemIdentifier"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "title.identifier"})}
                            dataField="titleIdentifier"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "permanent.location"})}
                            dataField="permanentLocation"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "current.location"})}
                            dataField="currentLocation"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "call.number"})}
                            dataField="callNumber"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "epc"})}
                            dataField="epc"
                            dataType="string"
                        />
                        <Column
                            allowFiltering={false}
                            visible={true}
                            caption=""
                            dataField="kill"
                            dataType="string"
                            width="150"
                            cellRender={(item) => {
                                return (
                                    <>
                                        <Button
                                            hint={t("{{key, capitalize}}", {
                                                key: "tag.kill",
                                            })}
                                            visible={true}
                                            onClick={(evt) => {
                                                let confirmMessageText = t("{{key, capitalize}}", {
                                                    key: "tag.kill.confirm.message",
                                                });
                                                let confirmMessageTitle = t("{{key, capitalize}}", {
                                                    key: "tag.kill.confirm.title",
                                                });

                                                let result = confirm(
                                                    confirmMessageText,
                                                    confirmMessageTitle
                                                );
                                                result.then((dialogResult) => {
                                                    if (dialogResult) {
                                                        let data = {
                                                            command: "kill",
                                                            data: {
                                                                fixtureNumber:
                                                                    form.props.formData
                                                                        .fixtureNumber,
                                                                collectDataItem: item.data,
                                                                writeType: "EpcUser",
                                                                userData: "0000",
                                                            },
                                                        };
                                                        kill(data);
                                                    }
                                                });
                                            }}>
                                            <DoNotDisturbAlt fontSize="medium" color="error" />
                                        </Button>
                                    </>
                                );
                            }}
                        />
                        <Summary>
                            <TotalItem column="rssi" summaryType="count" />
                        </Summary>
                    </DataGrid>
                </ScrollView>
            </Popup>
            <Footer />
        </DashboardLayout>
    );
}

export default MatchPage;
