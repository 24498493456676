import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import {FileUploader, LoadPanel} from "devextreme-react";
import Form, {
    RequiredRule,
    SimpleItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
} from "devextreme-react/form";
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    FilterRow,
    Item,
    Lookup,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import {Save} from "@mui/icons-material";
import ItemCountingService from "./ItemCountingService";
import AuthProvider from "../../../../providers/AuthProvider";
import * as XLSX from "xlsx";
import {
    EmediaItemCountingDetailState,
    EMediaItemCountingState,
} from "../../../../providers/AppConstantProvider";
import SuiBox from "../../../../components/SuiBox";
import ItemInfoService from "./ItemInfoService";
import ItemCountingDetailService from "./itemCountingDetailService";
import Footer from "../../../../examples/Footer";

const itemCountingDetailService = ItemCountingDetailService();
const itemCountingService = ItemCountingService();
const authProvider = AuthProvider();
const itemInfoService = ItemInfoService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return itemCountingService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return itemCountingService.findOne(key).then((response) => {
                return response;
            });
        },
        update: (key: any, values: any) => {
            values.id = key;
            return itemCountingService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return itemCountingService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

let itemLoadOptions;
const itemInfoDataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            itemLoadOptions = loadOptions;
            return itemInfoService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

const textToJSON = (data: any) => {
    let header = null;
    if (data.length > 0) {
        header = data[0];
        header = header.map((val) => {
            switch (val) {
                case "itemIdentifier":
                    val = "itemIdentifier";
                    break;
                case "titleIdentifier":
                    val = "titleIdentifier";
                    break;
            }
            return "itemIdentifier"; // val;
        });
    }
    if (data.length <= 1 || header === null) return;
    else {
        const dataWithoutHeader = data.slice(1);
        let arr = dataWithoutHeader.map((val) => {
            let obj = {};
            for (let [index, headerVal] of header.entries()) {
                obj[headerVal] = val[index];
            }
            return obj;
        });
        return arr;
    }
};

function ItemCountingPage() {
    const {t} = useTranslation();
    const [dataGrid, setDataGrid] = useState();
    const [form, setForm] = useState();
    let fileRef, itemDataGrid, shelfDataGrid, detailDataGrid;
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [sheetDataList, setSheetDataList] = useState([]);
    const [detailId, setDetailId] = useState(null);
    const [detailName, setDetailName] = useState(null);

    const [showLoadPanel, setShowLoadPanel] = useState(false);

    const [foundItemCount, setFoundItemCount] = useState(0);
    const [notFoundItemCount, setNotFoundItemCount] = useState(0);
    const [unlistedItemCount, setUnlistedItemCount] = useState(0);
    const [unknownItemCount, setUnknownItemCount] = useState(0);

    const [openDialog, setOpenDialog] = useState(false);
    const [countingDetailDataSource, setCountingDetailDataSource] = useState(null);
    const [filter, setFilter] = useState();

    const itemInfoDataSourceDetail = new DataSource({
        store: new CustomStore({
            key: "id",
            load(loadOptions: any) {
                return itemCountingDetailService.findAll(loadOptions).then(
                    (response) => ({
                        data: response.items,
                        totalCount: response.totalCount,
                    }),
                    (err) => {
                        return err.json().then((r) => {
                            throw r.errorMessage ? r.errorMessage : r.warningMessage;
                        });
                    }
                );
            },
        }),
        filter: ["mediaItemCounting.id", "=", detailId],
    });

    const onBatchDataChanged = (value) => {
        if (value.value.length === 0) {
            setSheetDataList([]);
            return;
        }

        const excelFile = value.value[0];
        const reader: FileReader = new FileReader();
        let sheetArray = [];
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, {
                type: "binary",
                cellDates: true,
                cellNF: false,
                cellText: false,
            });

            wb.SheetNames.forEach((item) => {
                const ws: XLSX.WorkSheet = wb.Sheets[item];
                const data = XLSX.utils.sheet_to_json(ws, {
                    header: 1,
                    dateNF: "dd/MM/yyyy;@",
                    raw: false,
                });
                sheetArray.push({
                    sheetName: item,
                    sheetData: textToJSON(data),
                });
            });
            setSheetDataList(sheetArray[0].sheetData);
        };
        reader.readAsBinaryString(excelFile);
    };

    const openDetail = (val) => {
        setDetailId(val.key.toString());
        setDetailName(val.data.name);
        let parameter = {
            id: val.key,
        };
        itemCountingService
            .summary(parameter)
            .then((response) => setCountingDetailDataSource(response));

        setOpenDialog(true);
    };

    useEffect(() => {
        if (countingDetailDataSource !== null) {
            countingDetailDataSource.forEach((item) => {
                if (item.mediaItemCountingDetailState === "found") {
                    setFoundItemCount(item.count);
                }
                if (item.mediaItemCountingDetailState === "notFound") {
                    setNotFoundItemCount(item.count);
                }
                if (item.mediaItemCountingDetailState === "unlisted") {
                    setUnlistedItemCount(item.count);
                }
                if (item.mediaItemCountingDetailState === "unknown") {
                    setUnknownItemCount(item.count);
                }
            });
        }
    }, [countingDetailDataSource]);

    const hiddenPopup = () => setOpenDialog(false);

    useEffect(() => {
        if (openDialog === false) {
            setFoundItemCount(0);
            setNotFoundItemCount(0);
            setUnlistedItemCount(0);
            setUnknownItemCount(0);
        }
    }, [openDialog]);

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("media.item.counting") + " " + t("lists")}
                icon="devices"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    remoteOperations={true}
                    onRowClick={openDetail}
                    rowAlternationEnabled={true}
                    onRowPrepared={(rowInfo) => {
                        if (rowInfo.rowType === "data") {
                            rowInfo.rowElement.style.cursor = "pointer";
                        }
                    }}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item>
                            <Button
                                icon="add"
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            />
                        </Item>
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />
                    <Editing
                        mode="form"
                        allowUpdating={(e) => {
                            return (
                                e.row.data.mediaItemCountingState !== "completed" &&
                                authProvider.isAuth("mediaItemCountingUpdate")
                            );
                        }}
                        allowAdding={false}
                        allowDeleting={(e) => {
                            return (
                                e.row.data.mediaItemCountingState !== "completed" &&
                                authProvider.isAuth("mediaItemCountingDelete")
                            );
                        }}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "state"})}
                        dataField="mediaItemCountingState"
                        width={200}>
                        <Lookup
                            dataSource={EMediaItemCountingState}
                            displayExpr={(row) => {
                                return t("{{key, capitalize}}", {
                                    key: row.value,
                                });
                            }}
                            valueExpr="value"
                        />
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "start.date"})}
                        dataField="startDate"
                        dataType="date"
                        sortOrder="desc"
                        width="150"
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "name"})}
                        dataField="name"
                        dataType="string"
                    />
                </DataGrid>
            </SuiBox>

            <LoadPanel
                visible={showLoadPanel}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={true}
            />

            <Popup
                visible={showModal}
                showCloseButton={true}
                showTitle={true}
                fullScreen={true}
                closeOnOutsideClick={false}
                onHiding={() => {
                    if (itemDataGrid != undefined) {
                        itemDataGrid.instance.clearFilter();
                    }
                    shelfDataGrid.instance.clearFilter();
                    fileRef.instance.reset();
                    setSheetDataList([]);
                    setShowModal(false);
                    setFormData({});
                    dataGrid.instance.refresh();
                }}>
                <ScrollView width="100%" height="100%">
                    <Form
                        id="form"
                        labelMode="static"
                        formData={formData}
                        showValidationSummary={false}
                        ref={(ref) => {
                            setForm(ref);
                        }}
                        colCount={4}>
                        <SimpleItem
                            colSpan={2}
                            label={{text: t("{{key, capitalize}}", {key: "name"})}}
                            dataField="name">
                            <RequiredRule />
                        </SimpleItem>
                        <SimpleItem
                            colSpan={2}
                            label={{text: t("{{key, capitalize}}", {key: "start.date"})}}
                            dataField="startDate"
                            editorType="dxDateBox"
                            editorOptions={{
                                openOnFieldClick: true,
                            }}>
                            <RequiredRule />
                        </SimpleItem>
                    </Form>
                    <Form>
                        <TabbedItem colSpan={4}>
                            <TabPanelOptions deferRendering={true} />
                            <Tab title={t("{{key, capitalize}}", {key: "shelf.list"})}>
                                <div className="fileuploader-container">
                                    <FileUploader
                                        showFileList={true}
                                        ref={(ref) => {
                                            fileRef = ref;
                                        }}
                                        selectButtonText={
                                            t("{{key, capitalize}}", {key: "shelf.list"}) +
                                            " " +
                                            t("{{key, capitalize}}", {key: "upload"})
                                        }
                                        allowCanceling={true}
                                        labelText={t("{{key, capitalize}}", {key: "drop.file"})}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        uploadMode="useButtons"
                                        onValueChanged={onBatchDataChanged}
                                    />
                                </div>
                                <DataGrid
                                    dataSource={sheetDataList}
                                    allowSearch={true}
                                    allowFiltering={true}
                                    ref={(ref) => {
                                        shelfDataGrid = ref;
                                    }}
                                    allowSorting={true}
                                    remoteOperations={false}>
                                    <FilterRow visible={true} />
                                    <ColumnChooser enabled={false} />
                                    <LoadPanel enabled={true} />

                                    <Toolbar>
                                        <Item location="after">
                                            <Button
                                                icon={"save"}
                                                onClick={() => {
                                                    if (form.instance.validate().isValid) {
                                                        setShowLoadPanel(true);
                                                        let data = {
                                                            name: form.props.formData.name,
                                                            startDate:
                                                                form.props.formData.startDate,
                                                            mediaItemCountingDetailList:
                                                                sheetDataList,
                                                        };
                                                        itemCountingService.save(data).then(
                                                            () => {
                                                                setShowModal(false);
                                                                setShowLoadPanel(false);
                                                                setSheetDataList(null);
                                                            },
                                                            (err) => {
                                                                setShowLoadPanel(false);
                                                                return err.json().then((r) => {
                                                                    throw r.errorMessage
                                                                        ? r.errorMessage
                                                                        : r.warningMessage;
                                                                });
                                                            }
                                                        );
                                                    }
                                                }}>
                                                <Save fontSize="medium" color="success" />
                                            </Button>
                                        </Item>

                                        <Item name="searchPanel" />
                                    </Toolbar>
                                    <SearchPanel
                                        visible={true}
                                        highlightCaseSensitive={true}
                                        width={240}
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                                    />
                                    <Column
                                        caption={t("{{key, capitalize}}", {key: "item.identifier"})}
                                        dataField="itemIdentifier"
                                        dataType="string"
                                    />
                                    <Editing
                                        mode="row"
                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}
                                    />
                                </DataGrid>
                            </Tab>
                            <Tab title={t("{{key, capitalize}}", {key: "item"})}>
                                <DataGrid
                                    dataSource={itemInfoDataSource}
                                    allowSorting={true}
                                    remoteOperations={true}
                                    rowAlternationEnabled={true}
                                    allowFiltering={true}
                                    ref={(ref) => {
                                        itemDataGrid = ref;
                                    }}>
                                    <FilterRow visible={true} />
                                    <Toolbar>
                                        <Item location="after">
                                            <Button
                                                icon={"save"}
                                                onClick={() => {
                                                    if (form.instance.validate().isValid) {
                                                        setShowLoadPanel(true);
                                                        let data = {
                                                            name: form.props.formData.name,
                                                            startDate:
                                                                form.props.formData.startDate,
                                                            loadOptions: itemLoadOptions,
                                                        };
                                                        itemCountingService
                                                            .filterMediaItemSave(data)
                                                            .then(
                                                                () => {
                                                                    setShowModal(false);
                                                                    setShowLoadPanel(false);
                                                                    setSheetDataList(null);
                                                                },
                                                                (err) => {
                                                                    setShowLoadPanel(false);
                                                                    return err.json().then((r) => {
                                                                        throw r.errorMessage
                                                                            ? r.errorMessage
                                                                            : r.warningMessage;
                                                                    });
                                                                }
                                                            );
                                                    }
                                                }}>
                                                <Save fontSize="medium" color="success" />
                                            </Button>
                                        </Item>

                                        <Item name="searchPanel" />
                                    </Toolbar>
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                                    />
                                    <Column
                                        caption={t("{{key, capitalize}}", {key: "item.identifier"})}
                                        dataField="mediaInfo.itemIdentifier"
                                        dataType="string"
                                    />
                                    <Column
                                        caption={t("{{key, capitalize}}", {
                                            key: "title.identifier",
                                        })}
                                        dataField={"mediaInfo.titleIdentifier"}
                                        dataType="string"
                                    />
                                    <Column
                                        caption={t("{{key, capitalize}}", {
                                            key: "permanent.location",
                                        })}
                                        dataField="mediaInfo.permanentLocation"
                                        dataType="string"
                                    />
                                    <Column
                                        caption={t("{{key, capitalize}}", {
                                            key: "current.location",
                                        })}
                                        dataField="mediaInfo.currentLocation"
                                        dataType="string"
                                    />
                                    <Column
                                        caption={t("{{key, capitalize}}", {
                                            key: "call.number",
                                        })}
                                        dataField="mediaInfo.callNumber"
                                        dataType="string"
                                    />
                                </DataGrid>
                            </Tab>
                        </TabbedItem>
                    </Form>
                </ScrollView>
            </Popup>
            <LoadPanel
                visible={showLoadPanel}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={true}
            />

            <Popup
                onHiding={hiddenPopup}
                showTitle={true}
                fullScreen={true}
                title={detailName}
                hideOnOutsideClick={true}
                visible={openDialog}
                showCloseButton={true}
                onHidden={(ref) => {
                    detailDataGrid.instance.clearFilter();
                    hiddenPopup();
                }}>
                <ScrollView width="100%" height="100%">
                    <DataGrid
                        dataSource={itemInfoDataSourceDetail}
                        allowSorting={true}
                        remoteOperations={true}
                        rowAlternationEnabled={true}
                        allowFiltering={true}
                        ref={(ref) => {
                            detailDataGrid = ref;
                        }}>
                        <FilterRow visible={true} />
                        <ColumnChooser enabled={true} />
                        <LoadPanel enabled={true} />

                        <Toolbar>
                            <Item location="center">
                                <div
                                    className="informer"
                                    style={{backgroundColor: "green", color: "white"}}>
                                    <span
                                        className="name"
                                        onClick={() => {
                                            setFilter("found");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {t("{{key, capitalize}}", {
                                            key: "item.counting.found",
                                        })}
                                    </span>
                                    <h2
                                        className="count"
                                        onClick={() => {
                                            setFilter("found");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {foundItemCount}
                                    </h2>
                                </div>
                            </Item>
                            <Item location="center">
                                <div
                                    className="informer"
                                    style={{backgroundColor: "red", color: "white"}}>
                                    <span
                                        className="name"
                                        onClick={() => {
                                            setFilter("notFound");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {t("{{key, capitalize}}", {
                                            key: "item.counting.notFound",
                                        })}
                                    </span>
                                    <h2
                                        className="count"
                                        onClick={() => {
                                            setFilter("notFound");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {notFoundItemCount}
                                    </h2>
                                </div>
                            </Item>

                            <Item location="center">
                                <div
                                    className="informer"
                                    style={{backgroundColor: "blue", color: "white"}}>
                                    <span
                                        className="name"
                                        onClick={() => {
                                            setFilter("unknown");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {t("{{key, capitalize}}", {
                                            key: "item.counting.unknown",
                                        })}
                                    </span>
                                    <h2
                                        className="count"
                                        onClick={() => {
                                            setFilter("unknown");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {unknownItemCount}
                                    </h2>
                                </div>
                            </Item>
                            <Item location="center">
                                <div
                                    className="informer"
                                    style={{backgroundColor: "orange", color: "white"}}>
                                    <span
                                        className="name"
                                        onClick={() => {
                                            setFilter("unlisted");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {t("{{key, capitalize}}", {
                                            key: "item.counting.unListed",
                                        })}
                                    </span>
                                    <h2
                                        className="count"
                                        onClick={() => {
                                            setFilter("unlisted");
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = "normal";
                                        }}>
                                        {unlistedItemCount}
                                    </h2>
                                </div>
                            </Item>
                        </Toolbar>

                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                        />

                        <Column
                            caption={t("{{key, capitalize}}", {key: "epc"})}
                            dataField="epc"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "item.identifier"})}
                            dataField="itemIdentifier"
                            dataType="string"
                            sortOrder="desc"
                        />

                        <Column
                            caption={t("{{key, capitalize}}", {
                                key: "title.identifier",
                            })}
                            dataField={"mediaInfo.titleIdentifier"}
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {
                                key: "permanent.location",
                            })}
                            dataField="mediaInfo.permanentLocation"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {
                                key: "current.location",
                            })}
                            dataField="mediaInfo.currentLocation"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {
                                key: "call.number",
                            })}
                            dataField="mediaInfo.callNumber"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {
                                key: "counting.state",
                            })}
                            dataField="mediaItemCountingDetailState"
                            filterValue={filter}
                            dataType="string">
                            <Lookup
                                dataSource={EmediaItemCountingDetailState}
                                displayExpr={(row) => {
                                    return t("{{key, capitalize}}", {
                                        key: row.value,
                                    });
                                }}
                                valueExpr="value"
                            />
                        </Column>
                    </DataGrid>
                </ScrollView>
            </Popup>
            <Footer />
        </DashboardLayout>
    );
}

export default ItemCountingPage;
