import React, {useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Form,
    Toolbar,
    Item,
    ColumnChooser,
    LoadPanel,
    RequiredRule,
    FilterRow,
    Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import ProductService from "./ProductService";
import {useTranslation} from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import {FileUploader} from "devextreme-react";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

import avatar from "assets/images/faces/logo.png";

const authProvider = AuthProvider();
const productService = ProductService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return productService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return productService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            values.personType = "Person";
            return productService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return productService.update(values).then(
                () => {
                    // window.location.reload()
                },
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return productService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

function ProductPage() {
    const renderGridPhotoCell = (val) => {
        const d = val.data ? val.data : val;
        return (
            <img
                width="70"
                src={
                    d.value
                        ? `${process.env.REACT_APP_API_URL}binaryContents/getPublicImage/` + d.value
                        : avatar
                }
                alt="Person"
            />
        );
    };

    const [dataGrid, setDataGrid] = useState();
    const {t} = useTranslation();

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("product") + " " + t("lists")}
                icon="products"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    showBorders={false}
                    remoteOperations={true}
                    rowAlternationEnabled={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Editing
                        mode="popup"
                        allowUpdating={authProvider.isAuth("productUpdate")}
                        allowAdding={false}
                        allowDeleting={false}>
                        <Popup
                            title={t("{{key, capitalize}}", {key: "product.information"})}
                            showTitle={true}
                            showCloseButton={true}
                        />
                        <Form>
                            <Item dataField="name" colSpan={2} />
                            <Item dataField="collectTime" />
                            <Item dataField="removeTime" />
                            <Item dataField="image" colSpan={2} />
                        </Form>
                    </Editing>
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "image"})}
                        dataField="contentId"
                        dataType="string"
                        width={120}
                        cellComponent={renderGridPhotoCell}
                        allowSearch={false}
                        allowFiltering={false}
                        allowSorting={false}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "name"})}
                        dataField="name"
                        dataType="string">
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "collect.time"})}
                        dataField="collectTime"
                        dataType="number">
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "remove.time"})}
                        dataField="removeTime"
                        dataType="number">
                        <RequiredRule />
                    </Column>

                    <Column
                        dataField="image"
                        caption={t("{{key, capitalize}}", {key: "image"})}
                        visible={false}
                        editCellRender={(d) => {
                            d.setValue(null);
                            return (
                                <FileUploader
                                    selectButtonText={t("{{key, capitalize}}", {
                                        key: "select.image",
                                    })}
                                    labelText={t("drop.image")}
                                    uploadMode="useForm"
                                    onValueChanged={(val) => {
                                        d.setValue(val.value[0]);
                                    }}
                                />
                            );
                        }}
                        allowSearch={false}
                        allowFiltering={false}
                    />
                </DataGrid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ProductPage;
