import React, {useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Form,
    Toolbar,
    Item,
    ColumnChooser,
    LoadPanel,
    RequiredRule,
    FilterRow,
    Lookup,
    Popup,
    MasterDetail,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import AnnouncementService from "./AnnouncementService";
import {useTranslation} from "react-i18next";
import DashboardLayout from "../../../layouts/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import {FileUploader, HtmlEditor} from "devextreme-react";
import {EAnnouncementType, EDocumentType} from "../../../providers/ConstantProvider";
import AuthProvider from "../../../providers/AuthProvider";
import {MediaResizing} from "devextreme-react/html-editor";

const authProvider = AuthProvider();
const announcementService = AnnouncementService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return announcementService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return announcementService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            return announcementService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return announcementService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return announcementService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

function AnnouncementPage() {
    const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    const fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
    ];
    const headerValues = [false, 1, 2, 3, 4, 5];
    const {t} = useTranslation();
    const [dataGrid, setDataGrid] = useState();
    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("announcement") + " " + t("lists")}
                icon="announcement"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    showBorders={false}
                    remoteOperations={true}
                    rowAlternationEnabled={true}
                    wordWrapEnabled={true}
                    columnHidingEnabled={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Editing
                        mode="popup"
                        allowUpdating={authProvider.isAuth("announcementUpdate")}
                        allowAdding={authProvider.isAuth("announcementSave")}
                        allowDeleting={authProvider.isAuth("announcementDelete")}>
                        <Popup
                            title={t("{{key, capitalize}}", {key: "announcement.information"})}
                            showTitle={true}
                        />
                        <Form>
                            <Item dataField="announcementType" />
                            <Item dataField="title" />
                            <Item colSpan={2} dataField="body" />
                            <Item dataField="releaseDate" />
                            <Item dataField="expireDate" />
                            <Item colSpan={2} dataField="image" />
                        </Form>
                    </Editing>
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />
                    <Column
                        caption={t("{{key, capitalize}}", {key: "announcement.type"})}
                        dataField="announcementType"
                        width={200}>
                        <Lookup
                            dataSource={EAnnouncementType}
                            displayExpr={(row) => {
                                return t("{{key, capitalize}}", {
                                    key: row.value,
                                });
                            }}
                            valueExpr="value"
                        />
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "title"})}
                        dataField="title"
                        dataType="string"
                        visible={true}>
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "content"})}
                        dataField="body"
                        dataType="string"
                        visible={false}
                        editCellRender={(d) => {
                            return (
                                <HtmlEditor
                                    height={300}
                                    defaultValue={d.data.body}
                                    onValueChanged={(val) => {
                                        d.setValue(val.value);
                                    }}>
                                    <MediaResizing enabled={true} />
                                    <Toolbar multiline={true}>
                                        <Item name="undo" />
                                        <Item name="redo" />
                                        <Item name="separator" />
                                        <Item name="size" acceptedValues={sizeValues} />
                                        <Item name="font" acceptedValues={fontValues} />
                                        <Item name="separator" />
                                        <Item name="bold" />
                                        <Item name="italic" />
                                        <Item name="strike" />
                                        <Item name="underline" />
                                        <Item name="separator" />
                                        <Item name="alignLeft" />
                                        <Item name="alignCenter" />
                                        <Item name="alignRight" />
                                        <Item name="alignJustify" />
                                        <Item name="separator" />
                                        <Item name="orderedList" />
                                        <Item name="bulletList" />
                                        <Item name="separator" />
                                        <Item name="header" acceptedValues={headerValues} />
                                        <Item name="separator" />
                                        <Item name="color" />
                                        <Item name="background" />
                                        <Item name="separator" />
                                        <Item name="link" />
                                        <Item name="image" />
                                        <Item name="separator" />
                                        <Item name="clear" />
                                        <Item name="codeBlock" />
                                        <Item name="blockquote" />
                                        <Item name="separator" />
                                        <Item name="insertTable" />
                                        <Item name="deleteTable" />
                                        <Item name="insertRowAbove" />
                                        <Item name="insertRowBelow" />
                                        <Item name="deleteRow" />
                                        <Item name="insertColumnLeft" />
                                        <Item name="insertColumnRight" />
                                        <Item name="deleteColumn" />
                                    </Toolbar>
                                </HtmlEditor>
                            );
                        }}>
                        >
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "release.date"})}
                        dataField="releaseDate"
                        dataType="date"
                        format={"dd.MM.yyyy"}
                        width={150}
                        allowSearch={false}
                        allowFiltering={false}
                        allowSorting={false}
                        sortOrder="desc">
                        <RequiredRule />
                    </Column>
                    <Column
                        caption={t("{{key, capitalize}}", {key: "expire.date"})}
                        dataField="expireDate"
                        dataType="date"
                        format={"dd.MM.yyyy"}
                        width={150}
                        allowSearch={false}
                        allowFiltering={false}
                        allowSorting={false}>
                        <RequiredRule />
                    </Column>

                    <Column
                        dataField="image"
                        caption={t("{{key, capitalize}}", {key: "icon"})}
                        accept="image/*"
                        visible={false}
                        editCellRender={(d) => {
                            return (
                                <FileUploader
                                    uploadMode="useForm"
                                    onValueChanged={(val) => {
                                        d.setValue(val.value[0]);
                                    }}
                                />
                            );
                        }}
                        allowSearch={false}
                        allowFiltering={false}
                    />
                    <MasterDetail
                        enabled={true}
                        autoExpandAll={true}
                        component={(item) => {
                            return (
                                <div className="master-detail-caption">
                                    <div
                                        className="dx-textarea dx-textbox dx-texteditor dx-editor-standard dx-state-readonly dx-widget dx-visibility-change-handler"
                                        dangerouslySetInnerHTML={{
                                            __html: item.data.data.body,
                                        }}></div>
                                </div>
                            );
                        }}
                    />
                </DataGrid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AnnouncementPage;
