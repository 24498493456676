import AuthProvider from "../../../providers/AuthProvider";

function AuthService() {
    const authProvider = AuthProvider();
    const login = async (values) => {
        authProvider.setAuthData(null);
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(values),
        };
        return authProvider
            .authFetch("auth/login", requestOptions)
            .then((data) => {
                authProvider.setAuthData(data);
                return Promise.resolve(data);
            })
            .catch((err) => {
                authProvider.setAuthData(null);
                return Promise.reject(err);
            });
    };

    const logout = async () => {
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
        };
        return authProvider
            .authFetch("auth/logout", requestOptions)
            .then((data) => {
                authProvider.setAuthData(null);
            })
            .catch((err) => {
                authProvider.setAuthData(null);
                return Promise.reject(err);
            });
    };

    const clearAuthData = () => {
        return authProvider.clearAuthData();
    };

    return {
        login,
        logout,
        clearAuthData,
    };
}

export default AuthService;

// export const createAuthService = () => {
//   return {
//     login,
//     logout,
//     clearAuthData
//   }
// };
