import MediaService from "./MediaService";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import FileManager, {
    Column,
    Details,
    FileSelectionItem,
    Item,
    ItemView,
    Notifications,
    Permissions,
    Toolbar,
    Upload,
} from "devextreme-react/file-manager";
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";
import {useTranslation} from "react-i18next";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import React from "react";
// import FileSystemError from "devextreme/file_management/error";

const authProvider = AuthProvider();
const mediaService = MediaService();

function MediaPage() {
    const {t} = useTranslation();

    const fileSystemProvider = new CustomFileSystemProvider({
        getItems: (fileItem) => {
            return mediaService.getItems(fileItem).then((r) => {
                return r;
            });
        },
        createDirectory: (fileItem, fileName) => {
            return mediaService
                .createDirectory({
                    path: fileItem.path,
                    name: fileName,
                })
                .then((r) => {
                    return r;
                });
        },

        renameItem: (fileItem, fileName) => {
            return mediaService
                .renameItem({
                    item: fileItem,
                    name: fileName,
                })
                .then((r) => {
                    return r;
                });
        },
        deleteItem: (fileItem) => {
            return mediaService.deleteItem({item: fileItem}).then((r) => {
                return r;
            });
        },
        copyItem: (fileItem, destinationDirectory) => {
            return mediaService
                .copyItem({item: fileItem, destinationPath: destinationDirectory.path})
                .then((r) => {
                    return r;
                });
        },
        moveItem: (fileItem, destinationDirectory) => {
            return mediaService
                .moveItem({
                    item: fileItem,
                    destinationPath: destinationDirectory.path,
                })
                .then((r) => {
                    return r;
                });
        },
        uploadFileChunk: (fileData, uploadInfo, destinationDirectory) => {
            return mediaService
                .uploadFileChunk(
                    {
                        name: fileData.name,
                        path: destinationDirectory.path,
                    },
                    uploadInfo.chunkBlob
                )
                .then((r) => {
                    return r;
                });
        },
        downloadItems: (items) => {
            const item = items[0];
            return mediaService.downloadItem({
                path: item.path,
            });
        },
    });

    const customizeThumbnail = (fileData) => {
        if (fileData.isDirectory) {
            return "images/thumbnails/folder.svg";
        }

        let link;
        // if (fileData.dataItem && fileData.dataItem.path) {
        //     link = fileData.dataItem.path.replace('/opt/app/fonri/', process.env.REACT_APP_API_URL);
        // }
        const fileExtension = fileData.getFileExtension();
        switch (fileExtension) {
            case ".txt":
                return "images/thumbnails/txt.svg";
            case ".rtf":
                return "images/thumbnails/rtf.svg";
            case ".xml":
                return "images/thumbnails/xml.svg";
            case ".doc":
                return "images/thumbnails/doc.svg";
            case ".docx":
                return "images/thumbnails/docx.svg";
            case ".md":
                return "images/thumbnails/md.svg";
            case ".xls":
                return "images/thumbnails/xls.svg";
            case ".xlsx":
                return "images/thumbnails/xlsx.svg";
            case ".svg":
                return link ? link : "images/thumbnails/svg.svg";
            case ".csv":
                return "images/thumbnails/csv.svg";
            case ".pdf":
                return "images/thumbnails/pdf.svg";
            case ".mp4":
                return "images/thumbnails/mp4.svg";
            case ".js":
                return "images/thumbnails/js.svg";
            case ".css":
                return "images/thumbnails/css.svg";
            case ".htm":
            case ".html":
                return "images/thumbnails/html.svg";
            case ".zip":
                return "images/thumbnails/zip.svg";
            case ".jpg":
            case ".jpeg":
                return link ? link : "images/thumbnails/jpg.svg";
            case ".json":
                return "images/thumbnails/json.svg";
            case ".png":
                return link ? link : "images/thumbnails/png.svg";
            case ".exe":
                return "images/thumbnails/exe.svg";
            case ".jar":
                return "images/thumbnails/jar.png";
            case ".sh":
                return "images/thumbnails/terminal.svg";
            default:
                return "images/thumbnails/file.svg";
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("file") + " " + t("explorer")}
                icon="file_copy"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <FileManager
                    selectionMode="single"
                    fileSystemProvider={fileSystemProvider}
                    // onSelectionChanged={onSelectionChanged}
                    customizeThumbnail={customizeThumbnail}>
                    <Upload chunkSize={200000000} maxFileSize={200000000} />
                    <Permissions
                        create={authProvider.isAuth("mediaCreateDirectory")}
                        copy={authProvider.isAuth("mediaCopyItem")}
                        move={authProvider.isAuth("mediaMoveItem")}
                        delete={authProvider.isAuth("mediaDeleteItem")}
                        rename={authProvider.isAuth("mediaRenameItem")}
                        upload={authProvider.isAuth("mediaFileUpload")}
                        download={authProvider.isAuth("mediaDownloadItem")}
                    />
                    <Toolbar>
                        <Item name="showNavPane" />
                        <Item name="separator" />
                        <Item name="create" />
                        <Item name="upload" />
                        <Item name="refresh" />
                        <Item name="separator" />
                        {/*<Item name="switchView"/>*/}

                        <FileSelectionItem name="download" />
                        <FileSelectionItem name="move" />
                        <FileSelectionItem name="rename" />
                        <FileSelectionItem name="copy" />
                        <FileSelectionItem name="delete" />
                        <FileSelectionItem name="refresh" />
                        <FileSelectionItem name="separator" />
                        {/*<FileSelectionItem visible={editButtonShow}*/}
                        {/*    widget="dxButton"*/}
                        {/*    location="before"*/}
                        {/*    options={*/}
                        {/*        {*/}
                        {/*            text: 'LİNK',*/}
                        {/*            icon: 'link',*/}
                        {/*            onClick: () => {*/}
                        {/*                console.log('onClick');*/}
                        {/*            }*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*/>*/}
                        <FileSelectionItem name="clearSelection" />
                    </Toolbar>
                    <ItemView mode="thumbnails" showParentFolder={true}>
                        <Details>
                            <Column dataField="thumbnail" />
                            <Column dataField="name" />
                        </Details>
                    </ItemView>
                    <Notifications showPopup={true} showPanel={true} />
                </FileManager>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default MediaPage;
