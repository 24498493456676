import {useMemo} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {Bar} from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import colors from "assets/theme/base/colors";

// ThinBarChart configuration
import configs from "examples/Charts/BarCharts/ThinBarChart/configs";

// Media Query
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

function ThinBarChart({color, title, chart}) {
    const {data, options} = configs(color, chart.labels || [], chart.datasets || {});
    const {light, dark} = colors;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("xxl"));
    const renderChart = (
        <SuiBox bgColor={light.main}>
            {title && (
                <SuiBox mb={1} color={light.main} bgColor={"dark"} pt={2.25} pb={2.25} pl={3}>
                    <SuiTypography variant="h6" color={"light"}>
                        {title}
                    </SuiTypography>
                </SuiBox>
            )}
            {matches && (
                <SuiBox height="17.62rem" pt={0.5} pr={0.2} bgColor={"light"}>
                    <Bar data={data} options={options} />
                </SuiBox>
            )}
            {!matches && (
                <SuiBox height="30.3rem" pt={0.5} pr={0.2} bgColor={"light"}>
                    <Bar data={data} options={options} />
                </SuiBox>
            )}
            {/* {useMemo(
                () => (
                    <SuiBox height={height} pt={2} bgColor={light.main}>
                        <Bar data={data} options={options} />
                    </SuiBox>
                ),
                [chart, height]
                        )} */}
        </SuiBox>
    );

    return title ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of ThinBarChart
ThinBarChart.defaultProps = {
    color: "dark",
    title: "",
    // height: "12.5rem",
};

// Typechecking props for the ThinBarChart
ThinBarChart.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "librefLogin",
        "light",
    ]),
    title: PropTypes.string,
    chart: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        datasets: PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
        ).isRequired,
    }).isRequired,
    // height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ThinBarChart;
