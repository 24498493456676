import React, {useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import LocationService from "./LocationService";
import TreeList, {
    Column,
    FilterRow,
    SearchPanel,
    Lookup,
    Scrolling,
    RequiredRule,
    RemoteOperations,
    Editing,
    Toolbar,
    Item,
    LoadPanel,
} from "devextreme-react/tree-list";
import Button from "devextreme-react/button";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import LocationTypeService from "../locationType/LocationTypeService";
import {useTranslation} from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const locationTypeService = LocationTypeService();
const locationService = LocationService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return locationService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return locationService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            values.personType = "Person";
            return locationService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return locationService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return locationService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

const locationTypeDataSource = new CustomStore({
    key: "id",
    load(loadOptions) {
        return locationTypeService
            .findAll({
                sort: [
                    {
                        selector: "id",
                        desc: false,
                    },
                ],
            })
            .then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
    },
    byKey: (key: any) => {
        return locationTypeService.findOne(key).then((response) => {
            return response;
        });
    },
});

function LocationPage() {
    const [treeList, setTreeList] = useState();
    const {t} = useTranslation();

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("location") + " " + t("lists")}
                icon="edit_location"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <TreeList
                    dataSource={dataSource}
                    keyExpr="id"
                    parentIdExpr="parent.id"
                    hasItemsExpr="hasItems"
                    rowAlternationEnabled={true}
                    rootValue={null}
                    ref={(ref) => {
                        setTreeList(ref);
                    }}>
                    <Editing
                        allowUpdating={authProvider.isAuth("locationUpdate")}
                        allowDeleting={authProvider.isAuth("locationDelete")}
                        allowAdding={authProvider.isAuth("locationSave")}
                        mode="row"
                    />
                    <SearchPanel visible={true} width={250} />
                    <Scrolling mode="standard" />
                    <RemoteOperations filtering={true} sorting={true} />
                    <FilterRow visible={true} />
                    <LoadPanel enabled={true} />

                    <Toolbar visible={true}>
                        <Item location="after">
                            <Button
                                visible={true}
                                icon="refresh"
                                onClick={() => {
                                    // console.log('treeList', treeList.instance);
                                    treeList.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" visible={true} />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <Column
                        sortOrder="asc"
                        dataField="code"
                        caption={t("{{key, capitalize}}", {key: "code"})}
                        width={200}>
                        <RequiredRule />
                    </Column>

                    <Column
                        dataField="locationType.id"
                        caption={t("{{key, capitalize}}", {key: "type"})}
                        width={200}>
                        <Lookup
                            dataSource={locationTypeDataSource}
                            valueExpr="id"
                            displayExpr="name"
                        />
                        <RequiredRule />
                    </Column>

                    <Column dataField="name" caption={t("{{key, capitalize}}", {key: "name"})}>
                        <RequiredRule />
                    </Column>
                </TreeList>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default LocationPage;
