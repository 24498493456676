import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {CardActions, CardHeader} from "@mui/material";
import {red} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import DeviceService from "../../device/DeviceService";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import loanReturnImage from "../../../../assets/images/loanReturn.png";
import {LoadPanel} from "devextreme-react";
import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    Item,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import Button from "devextreme-react/button";
import {
    closeWebSocket,
    initWebsocket,
    sendWebSocketMessage,
} from "../../../../providers/WebSocketProvider";
import SuiSnackbar from "../../../../components/SuiSnackbar";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import Avatar from "@mui/material/Avatar";
import notify from "devextreme/ui/notify";
import Form, {GroupItem, RequiredRule, SimpleItem} from "devextreme-react/form";
import {AssignmentInd, AssignmentReturned, MoreTime} from "@mui/icons-material";
import colors from "../../../../assets/theme/base/colors";
import controllerCardIcons from "../../../share/dashboards/main-dashboard/data/controllerCardIcons";
import Footer from "../../../../examples/Footer";

const deviceService = DeviceService();
const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return deviceService.findAllBy(loadOptions).then(
                (data) => ({
                    data: data.items,
                    totalCount: data.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return deviceService.findOne(key).then((response) => {
                return response;
            });
        },
    }),
    filter: ["product.id", "=", 3],
});

let timeOutId;
let localWs: WebSocket;

// let selfCheckData;

function LoanReturnPage() {
    const {t} = useTranslation();
    const [gridData, setGridData] = useState();
    const [deviceData, setDeviceData] = useState();
    const deviceDataRef = useRef();
    let dataGridFilter;
    const [dataGrid, setDataGrid] = useState();
    const [showModal, setShowModal] = useState(false);
    const [readData, setReadData] = useState([]);
    const readerData = useRef();

    const [showLoadPanel, setShowLoadPanel] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
    const [snackbarContent, setSnackbarContent] = useState("");

    const [patronInfo, setPatronInfo] = useState({});
    const [form, setForm] = useState();

    const uniqueArray = (data) => {
        return data.reduce((a, d) => {
            const items = a.filter((f) => f.itemIdentifier === d.itemIdentifier);
            if (items.length === 0) {
                a.push({
                    itemIdentifier: d.itemIdentifier,
                    callNumber: d.callNumber,
                    circulationStatus: d.circulationStatus,
                    currentLocation: d.currentLocation,
                    decode: d.decode,
                    deviceId: d.deviceId,
                    dueDate: d.dueDate,
                    epc: d.epc,
                    pcBitHex: d.pcBitHex,
                    pcBits: d.pcBits,
                    permanentLocation: d.permanentLocation,
                    rssi: d.rssi,
                    serialNumber: d.serialNumber,
                    timeStamp: d.timeStamp,
                    titleIdentifier: d.titleIdentifier,
                    userMemory: d.userMemory,
                    tagList: [
                        {
                            tid: d.tid,
                            pcBitHex: d.pcBitHex,
                            pcBits: d.pcBits,
                            userMemory: d.userMemory,
                            antennaPort: d.antennaPort,
                        },
                    ],
                });
            } else {
                const item = items[0];
                item.tagList.push({
                    tid: d.tid,
                    pcBitHex: d.pcBitHex,
                    pcBits: d.pcBits,
                    userMemory: d.userMemory,
                    antennaPort: d.antennaPort,
                });
            }
            return a;
        }, []);
    };

    const selfCheck = (data) => {
        setShowLoadPanel(true);
        timeOutId = setTimeout(() => {
            setSnackbarContent(
                t("{{key, capitalize}}", {
                    key: "sure.device.turn.on",
                })
            );
            setShowSnackbar(true);
            setShowLoadPanel(false);
        }, 10000);
        sendWebSocketMessage(data);
    };

    const returnFunction = (e) => {
        const data = e.detail;
        //console.log("returnFunction", data);
        switch (data.command) {
            case "open":
                data.command = "offer";
                setShowLoadPanel(true);
                sendWebSocketMessage(data);
                if (timeOutId) {
                    clearTimeout(timeOutId);
                }
                timeOutId = setTimeout(() => {
                    setSnackbarContent(t("sure.device.turn.on"));
                    setShowSnackbar(true);
                    closeWebSocket();
                    setShowLoadPanel(false);
                }, 10000);
                return;
            case "answer":
                data.command = "circulationRead";
                data.data = deviceDataRef.current;
                setReadData(null);
                readerData.current = null;
                sendWebSocketMessage(data);
                setTimeout(() => {
                    setShowModal(true);
                }, 100);
                setShowLoadPanel(false);
                break;
            case "circulationRead":
                const items = data.data;
                const uniqueData = uniqueArray(items);
                setReadData(uniqueData);
                // setReadData(items);
                readerData.current = uniqueData;
                return;
            case "itemCheckIn":
            case "itemCheckout":
                setShowLoadPanel(false);
                notify({
                    position: "top center",
                    message: t("{{key, capitalize}}", {key: data.data}),
                    type: "success",
                    displayTime: 3000,
                    height: 50,
                    shading: false,
                });
                break;
            case "patronInfo":
                setPatronInfo(data.data);
                // console.log(data.data);
                setTimeout(() => {
                    setShowModal(true);
                }, 100);
                setShowLoadPanel(false);
                break;
            case "success":
                setShowLoadPanel(false);
                notify({
                    position: "top center",
                    message: t("{{key, capitalize}}", {key: data.data}),
                    type: "success",
                    displayTime: 3000,
                    height: 50,
                    shading: false,
                });
                break;
            case "error":
                setShowLoadPanel(false);
                setShowSnackbar(true);
                setSnackbarContent(
                    t("{{key, capitalize}}", {
                        key: data.data,
                    })
                );
                break;
            default:
        }
        if (timeOutId) {
            clearTimeout(timeOutId);
        }
    };

    const initLocalWebsocket = () => {
        closeLocalWebSocket();
        const localUrl = "ws://127.0.0.1:8081/signal";
        localWs = new WebSocket(localUrl);
        localWs.onopen = () => {
            // console.log("Open Local WebSocket");
            // onMessage();
        };
        localWs.onclose = (e) => {
            //console.log("Close Local WebSocket", e);
            if (e.code !== 4002) {
                setTimeout(() => {
                    initLocalWebsocket();
                }, 5000);
            }
        };
        localWs.onmessage = (event) => {
            const message = event.data;
            const d = JSON.parse(message);
            //console.log("onmessage", d);
            // eslint-disable-next-line default-case
            switch (d.command) {
                case "open":
                    break;
                case "read":
                    d.data = readerData.current;
                    sendLocalWebSocketMessage(d);
                    break;
            }
            //console.log("Local WebSocket Data: ", d);
        };
    };

    const closeLocalWebSocket = () => {
        if (localWs) {
            localWs.close(4002, "app_local");
        }
    };

    const sendLocalWebSocketMessage = (msg) => {
        if (localWs && localWs.readyState === 1) {
            localWs.send(JSON.stringify(msg));
        }
    };

    const getPatronInfo = () => {
        if (form.instance.validate().isValid) {
            let data = {
                command: "patronInfo",
                data: {
                    patronIdentifier: patronInfo.patronIdentifier,
                    patronPassword: patronInfo.patronIdentifier,
                },
            };
            setShowLoadPanel(true);
            timeOutId = setTimeout(() => {
                setSnackbarContent(
                    t("{{key, capitalize}}", {
                        key: "sure.device.turn.on",
                    })
                );
                setShowSnackbar(true);
                setShowLoadPanel(false);
            }, 10000);
            sendWebSocketMessage(data);
        }
    };

    useEffect(() => {
        window.addEventListener("socket", returnFunction);
        return () => {
            closeWebSocket();
            window.removeEventListener("socket", returnFunction);
            if (timeOutId) {
                clearTimeout(timeOutId);
            }
        };
    }, []);

    const {light, dark} = colors;
    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("device") + " " + t("lists")}
                icon="devices"
                fontSize="medium"
                onChangeSearch={(e) => {
                    dataGrid.instance.searchByText(e);
                }}
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    className="hidePanelDataGrid"
                    dataSource={dataSource}
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    remoteOperations={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}
                    onContentReady={(e) => {
                        setGridData(e.component.getDataSource().items());
                    }}>
                    <FilterRow visible={false} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <Paging defaultPageSize={20} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "name"})}
                        dataField="name"
                        dataType="string"
                        sortOrder="asc"
                    />
                </DataGrid>

                <Grid container mt={2} spacing={0} bgcolor="transparent">
                    {gridData &&
                        gridData.map((val) => {
                            return (
                                <Grid
                                    component={Card}
                                    item
                                    key={val.id}
                                    ml={1}
                                    mr={2}
                                    mb={1}
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={3}>
                                    <Card
                                        sx={{
                                            width: "100%",
                                            cursor: "pointer",
                                            bgcolor: "#f2f2f2",
                                        }}
                                        onClick={() => {
                                            timeOutId = setTimeout(() => {
                                                setSnackbarContent(t("sure.device.turn.on"));
                                                setShowSnackbar(true);
                                                setShowLoadPanel(false);
                                            }, 10000);

                                            setShowLoadPanel(true);
                                            setDeviceData(val);
                                            deviceDataRef.current = val;
                                            initWebsocket(val.id);
                                        }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    sx={{bgcolor: dark.main}}
                                                    aria-label="recipe">
                                                    {t("{{key, capitalize}}", {
                                                        key: "loan.return.lr",
                                                    })}
                                                </Avatar>
                                            }
                                            title={<b style={{fontSize: "20px"}}>{val.name}</b>}
                                            // subheader="September 14, 2016"
                                        />
                                        <CardMedia
                                            sx={{padding: 2}}
                                            component="img"
                                            width="100%"
                                            image={
                                                process.env.REACT_APP_API_URL +
                                                "binaryContents/getPublicImage/" +
                                                val.product.contentId
                                            }
                                            alt="thumbnail"
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                style={{textAlign: "center", marginTop: "15px"}}>
                                                {val.processor.department.name}
                                            </Typography>
                                        </CardContent>
                                        <CardActions
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    color:
                                                        val.deviceStatus === "idle"
                                                            ? "green"
                                                            : "red",
                                                }}
                                                component="div">
                                                {t("{{key, capitalize}}", {key: val.deviceStatus})}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>

                <LoadPanel
                    visible={showLoadPanel}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={true}
                />
                <SuiSnackbar
                    sx={{maxWidth: 400, zIndex: 10001}}
                    color="error"
                    icon="error"
                    title={t("{{key, capitalize}}", {key: "error"})}
                    content={snackbarContent}
                    dateTime=""
                    autoHideDuration={10000}
                    open={showSnackbar}
                    onClose={toggleSnackbar}
                    close={toggleSnackbar}
                />
            </SuiBox>

            <Popup
                visible={showModal}
                showCloseButton={true}
                showTitle={true}
                title={
                    deviceData
                        ? deviceData.name
                        : t("{{key, capitalize}}", {key: "fixture.information"})
                }
                closeOnOutsideClick={false}
                fullScreen={true}
                onHiding={() => {
                    dataGridFilter.instance.clearFilter();
                    setShowModal(false);
                    setPatronInfo({});
                    closeLocalWebSocket();
                }}>
                <ScrollView width="100%" height="100%">
                    <div className="long-title">
                        <h3>{t("{{key, capitalize}}", {key: "user.search"})}</h3>
                    </div>
                    <Form
                        id="form"
                        labelMode="static"
                        formData={patronInfo}
                        showValidationSummary={false}
                        onEditorEnterKey={(key) => {
                            getPatronInfo();
                        }}
                        ref={(ref) => {
                            setForm(ref);
                        }}>
                        <GroupItem colCount={12}>
                            <SimpleItem
                                colSpan={10}
                                label={{text: t("{{key, capitalize}}", {key: "patronIdentifier"})}}
                                dataField="patronIdentifier">
                                <RequiredRule />
                            </SimpleItem>
                            <SimpleItem
                                colSpan={2}
                                editorType="dxButton"
                                editorOptions={{
                                    width: "100%",
                                    alignItems: "flex-end",
                                    text: t("find"),
                                    type: "default",
                                    icon: "search",
                                    onClick: () => {
                                        getPatronInfo();
                                    },
                                }}></SimpleItem>
                            {patronInfo &&
                                patronInfo !== "" &&
                                patronInfo.personelName !== "" &&
                                patronInfo.validPatron === true && (
                                    <SimpleItem colSpan={12}>
                                        <table
                                            className="table"
                                            style={{
                                                width: "100%",
                                                textAlign: "left",
                                                fontSize: "16px",
                                            }}>
                                            <thead className={"dx-row dx-header-row"}>
                                                <tr>
                                                    <th>
                                                        {" "}
                                                        {t("{{key, capitalize}}", {
                                                            key: "patron.name",
                                                        })}
                                                    </th>
                                                    <th>
                                                        {t("{{key, capitalize}}", {
                                                            key: "patron.chargedItemsCount",
                                                        })}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className={"dx-datagrid dx-row"}>
                                                <tr>
                                                    <td>{patronInfo["personalName"]}</td>
                                                    <td>{patronInfo["chargedItemsCount"]}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </SimpleItem>
                                )}
                        </GroupItem>
                    </Form>
                    <DataGrid
                        noDataText={t("{{key, capitalize}}", {
                            key: "loan.return.nodatatext",
                        })}
                        dataSource={readData}
                        allowSearch={false}
                        allowFiltering={false}
                        allowSorting={false}
                        remoteOperations={false}
                        rowAlternationEnabled={false}
                        onRowPrepared={(rowInfo) => {
                            if (rowInfo.rowType === "data") {
                                if (rowInfo.rowIndex % 2 !== 0) {
                                    rowInfo.rowElement.bgColor = "#f3f3f3";
                                }
                                if (
                                    rowInfo.data.circulationStatus !== "CHARGED" &&
                                    rowInfo.data.userMemory === "FFFF"
                                ) {
                                    rowInfo.rowElement.bgColor = "#ffe6e6";
                                } else if (
                                    rowInfo.data.circulationStatus === "CHARGED" &&
                                    rowInfo.data.userMemory === "0000"
                                ) {
                                    rowInfo.rowElement.bgColor = "#ffe6e6";
                                }
                            }
                        }}
                        ref={(ref) => {
                            dataGridFilter = ref;
                        }}>
                        <FilterRow visible={true} />
                        <ColumnChooser enabled={false} />
                        <LoadPanel enabled={true} />
                        <Toolbar>
                            <Item location="before">
                                {t("{{key, capitalize}}", {key: "fixture.information"})}
                            </Item>
                        </Toolbar>
                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                        />
                        <Column
                            allowFiltering={false}
                            visible={true}
                            caption=""
                            dataField="id"
                            dataType="string"
                            width="120"
                            cellRender={(item) => {
                                return (
                                    <>
                                        <Button
                                            visible={
                                                patronInfo !== {} &&
                                                patronInfo.validPatron === true &&
                                                item.data.circulationStatus !== "CHARGED"
                                            }
                                            onClick={(event) => {
                                                let data = {
                                                    command: "itemCheckout",
                                                    data: {
                                                        patronIdentifier:
                                                            patronInfo.patronIdentifier,
                                                        itemIdentifier: item.data.itemIdentifier,
                                                        epc: item.data.epc,
                                                        tid: item.data.tid,
                                                        serialNumber: item.data.serialNumber,
                                                        antennaPort: item.data.antennaPort,
                                                        tagList: item.data.tagList,
                                                    },
                                                };
                                                selfCheck(data);
                                            }}>
                                            <AssignmentInd
                                                fontSize="medium"
                                                color="success"
                                                titleAccess={t("{{key, capitalize}}", {
                                                    key: "loan",
                                                })}
                                            />
                                        </Button>
                                        &nbsp;
                                        <Button
                                            visible={item.data.circulationStatus === "CHARGED"}
                                            onClick={(evt) => {
                                                let data = {
                                                    command: "itemCheckIn",
                                                    data: {
                                                        patronIdentifier:
                                                            patronInfo.patronIdentifier,
                                                        itemIdentifier: item.data.itemIdentifier,
                                                        epc: item.data.epc,
                                                        tid: item.data.tid,
                                                        serialNumber: item.data.serialNumber,
                                                        antennaPort: item.data.antennaPort,
                                                        tagList: item.data.tagList,
                                                    },
                                                };
                                                selfCheck(data);
                                            }}>
                                            <AssignmentReturned
                                                fontSize="medium"
                                                color="error"
                                                titleAccess={t("{{key, capitalize}}", {
                                                    key: "return",
                                                })}
                                            />
                                        </Button>
                                        &nbsp;
                                        <Button
                                            visible={
                                                patronInfo !== {} &&
                                                patronInfo.validPatron === true &&
                                                item.data.circulationStatus === "CHARGED"
                                            }
                                            onClick={(event) => {
                                                let data = {
                                                    command: "renew",
                                                    data: {
                                                        patronIdentifier:
                                                            patronInfo.patronIdentifier,
                                                        itemIdentifier: item.data.itemIdentifier,
                                                        epc: item.data.epc,
                                                        tid: item.data.tid,
                                                        tagList: item.data.tagList,
                                                    },
                                                };
                                                selfCheck(data);
                                            }}>
                                            <MoreTime
                                                fontSize="medium"
                                                color="info"
                                                titleAccess={t("{{key, capitalize}}", {
                                                    key: "renew",
                                                })}
                                            />
                                        </Button>
                                    </>
                                );
                            }}
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "item.identifier"})}
                            dataField="itemIdentifier"
                            dataType="string"
                            calculateCellValue={(rowData) => {
                                return (
                                    rowData.itemIdentifier +
                                    (rowData.tagList && rowData.tagList.length > 1
                                        ? " (" + rowData.tagList.length + ")"
                                        : "")
                                );
                            }}
                        />

                        <Column
                            caption={t("{{key, capitalize}}", {key: "title.identifier"})}
                            dataField="titleIdentifier"
                            dataType="string"
                        />

                        <Column
                            caption={t("{{key, capitalize}}", {key: "permanent.location"})}
                            dataField="permanentLocation"
                            dataType="string"
                        />

                        <Column
                            caption={t("{{key, capitalize}}", {key: "current.location"})}
                            dataField="currentLocation"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "call.number"})}
                            dataField="callNumber"
                            dataType="string"
                        />
                        <Column
                            allowFiltering={false}
                            allowSorting={false}
                            caption={t("{{key, capitalize}}", {key: "due.date"})}
                            dataField="dueDate"
                            dataType="string"
                        />
                    </DataGrid>
                </ScrollView>
            </Popup>
            <Footer />
        </DashboardLayout>
    );
}

export default LoanReturnPage;
