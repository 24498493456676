import AuthProvider from "../../../providers/AuthProvider";
import FileSaver from "file-saver";
import {getFileNameFromResponseContentDisposition} from "../../../providers/UtilsProvider";

const authProvider = AuthProvider();
const basePath = "media/";
function MediaService() {
    const getItems = async (fileItem) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(fileItem),
        };
        return authProvider.authFetch(basePath + "getItems", requestOptions);
    };

    const createDirectory = async (fileItem) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(fileItem),
        };
        return authProvider.authFetch(basePath + "createDirectory", requestOptions);
    };

    const deleteItem = async (fileItem) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(fileItem),
        };
        return authProvider.authFetch(basePath + "deleteItem", requestOptions);
    };

    const renameItem = async (fileItem) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(fileItem),
        };
        return authProvider.authFetch(basePath + "renameItem", requestOptions);
    };

    const copyItem = async (fileItem) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(fileItem),
        };
        return authProvider.authFetch(basePath + "copyItem", requestOptions);
    };

    const moveItem = async (fileItem) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(fileItem),
        };
        return authProvider.authFetch(basePath + "moveItem", requestOptions);
    };

    const downloadItem = async (fileItem) => {
        let fileName = "sample";
        const token = await authProvider.getToken();
        const input = process.env.REACT_APP_API_URL + basePath;
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`},
            body: JSON.stringify(fileItem),
            responseType: "blob",
            observe: "response",
        };
        return fetch(input + "downloadItem", requestOptions)
            .then((response) => {
                fileName = getFileNameFromResponseContentDisposition(response);
                return response.blob();
            })
            .then((blob) => {
                // const filename = fileItem.path.replace(/^.*[\\\/]/, '')
                FileSaver.saveAs(blob, fileName);
            });
    };

    const uploadFileChunk = async (fileRequest, file) => {
        const formData: FormData = new FormData();
        const blobData = new Blob([JSON.stringify(fileRequest)], {type: "application/json"});
        formData.append("data", blobData);
        formData.append("file", file, fileRequest.name);
        const requestOptions = {
            method: "POST",
            body: formData,
        };
        return authProvider.authFetch(basePath + "fileUpload", requestOptions);
    };

    return {
        getItems,
        uploadFileChunk,
        createDirectory,
        deleteItem,
        renameItem,
        copyItem,
        moveItem,
        downloadItem,
    };
}

export default MediaService;
