import AuthProvider from "../../../providers/AuthProvider";
import FileSaver from "file-saver";
import {getFileNameFromResponseContentDisposition} from "../../../providers/UtilsProvider";

const authProvider = AuthProvider();
const basePath = "device/";
function DeviceService() {
    const findOne = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
    };

    const findAll = async (loadOptions) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(loadOptions),
        };
        return authProvider.authFetch(basePath + "findAll", requestOptions);
    };

    const findAllBy = async (loadOptions) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(loadOptions),
        };
        return authProvider.authFetch(basePath + "findAllBy", requestOptions);
    };

    const save = async (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "save", requestOptions);
    };

    const update = (data) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data),
        };
        return authProvider.authFetch(basePath + "update", requestOptions);
    };

    const deleteOne = async (id) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: id,
        };
        return authProvider.authFetch(basePath + "delete", requestOptions);
    };

    const downloadApp = async (key) => {
        let fileName = "sample";
        const token = await authProvider.getToken();
        const input = process.env.REACT_APP_API_URL + basePath;
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`},
            responseType: "blob",
            observe: "response",
        };
        return fetch(input + "cert/" + key, requestOptions)
            .then((response) => {
                fileName = getFileNameFromResponseContentDisposition(response);
                return response.blob();
            })
            .then((blob) => {
                // console.log(blob.type);
                // const file = new File([blob], "test", {type: blob.type});
                FileSaver.saveAs(blob, fileName);
            });
    };

    return {
        findOne,
        findAll,
        findAllBy,
        save,
        update,
        deleteOne,
        downloadApp,
    };
}

export default DeviceService;
