import {useState, useEffect} from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React context
import {
    useSoftUIController,
    setOpenConfigurator,
    setTransparentSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
} from "context";
import ConfiguratorRoot from "./ConfiguratorRoot";

function Configurator() {
    const [controller, dispatch] = useSoftUIController();
    const {openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor} =
        controller;
    const [disabled, setDisabled] = useState(false);
    const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

    // Use the useEffect hook to change the button state for the sidenav type based on window size.
    useEffect(() => {
        // A function that sets the disabled state of the buttons for the sidenav type.
        function handleDisabled() {
            return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
        }

        // The event listener that's calling the handleDisabled function when resizing the window.
        window.addEventListener("resize", handleDisabled);

        // Call the handleDisabled function to set the state with the initial value.
        handleDisabled();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleDisabled);
    }, []);

    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
    const handleTransparentSidenav = () => setTransparentSidenav(dispatch, true);
    const handleWhiteSidenav = () => setTransparentSidenav(dispatch, false);
    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);

    // sidenav type buttons styles
    const sidenavTypeButtonsStyles = ({functions: {pxToRem}, boxShadows: {buttonBoxShadow}}) => ({
        height: pxToRem(42),
        boxShadow: buttonBoxShadow.main,

        "&:hover, &:focus": {
            opacity: 1,
        },
    });

    return (
        <ConfiguratorRoot variant="permanent" ownerState={{openConfigurator}}>
            <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}>
                <SuiBox>
                    <SuiTypography variant="h5">Görsel Ayarlar</SuiTypography>
                    <SuiTypography variant="body2" color="text">
                        Butonları kullanarak Seçiminizi yapınız
                    </SuiTypography>
                </SuiBox>

                <Icon
                    sx={({typography: {size, fontWeightBold}, palette: {dark}}) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={handleCloseConfigurator}>
                    close
                </Icon>
            </SuiBox>

            <Divider />

            <SuiBox pt={1.25} pb={3} px={3}>
                <SuiBox>
                    <SuiTypography variant="h6">Menü Rengi</SuiTypography>

                    <SuiBox mb={0.5}>
                        {sidenavColors.map((color) => (
                            <IconButton
                                key={color}
                                sx={({
                                    borders: {borderWidth},
                                    palette: {white, dark},
                                    transitions,
                                }) => ({
                                    width: "24px",
                                    height: "24px",
                                    padding: 0,
                                    border: `${borderWidth[1]} solid ${white.main}`,
                                    borderColor: sidenavColor === color && dark.main,
                                    transition: transitions.create("border-color", {
                                        easing: transitions.easing.sharp,
                                        duration: transitions.duration.shorter,
                                    }),
                                    backgroundImage: ({
                                        functions: {linearGradient},
                                        palette: {gradients},
                                    }) =>
                                        linearGradient(
                                            gradients[color].main,
                                            gradients[color].state
                                        ),

                                    "&:not(:last-child)": {
                                        mr: 1,
                                    },

                                    "&:hover, &:focus, &:active": {
                                        borderColor: dark.main,
                                    },
                                })}
                                onClick={() => setSidenavColor(dispatch, color)}
                            />
                        ))}
                    </SuiBox>
                </SuiBox>

                <SuiBox mt={3} lineHeight={1}>
                    <SuiTypography variant="h6">Menü Tipi</SuiTypography>
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                        Lütfen iki tipden birisini seçiniz.
                    </SuiTypography>

                    <SuiBox
                        sx={{
                            display: "flex",
                            mt: 2,
                        }}>
                        <SuiButton
                            color="info"
                            variant={transparentSidenav ? "gradient" : "outlined"}
                            onClick={handleTransparentSidenav}
                            disabled={disabled}
                            fullWidth
                            sx={{
                                mr: 1,
                                ...sidenavTypeButtonsStyles,
                            }}>
                            Renksiz
                        </SuiButton>
                        <SuiButton
                            color="info"
                            variant={transparentSidenav ? "outlined" : "gradient"}
                            onClick={handleWhiteSidenav}
                            disabled={disabled}
                            fullWidth
                            sx={sidenavTypeButtonsStyles}>
                            Beyaz
                        </SuiButton>
                    </SuiBox>
                </SuiBox>
                <SuiBox mt={3} mb={2} lineHeight={1}>
                    <SuiTypography variant="h6">Üst menü göster</SuiTypography>

                    <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
                </SuiBox>

                <Divider />

                <SuiBox mt={2} mb={3} lineHeight={1}>
                    <SuiTypography variant="h6">Menüyü küçült</SuiTypography>

                    <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
                </SuiBox>

                <Divider />
            </SuiBox>
        </ConfiguratorRoot>
    );
}

export default Configurator;
