// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {useTranslation} from "react-i18next";

function Footer() {
    const {t} = useTranslation();
    const date = new Date().getFullYear().toString();
    return (
        <SuiBox component="footer" py={6}>
            <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                    <SuiBox display="flex" justifyContent="center" mt={1} mb={3}>
                        <SuiBox mr={3}>
                            {/*<SuiSocialButton color="facebook" circular iconOnly size="small">*/}
                            {/*  <FacebookIcon />*/}
                            {/*</SuiSocialButton>*/}
                            <FacebookIcon fontSize="small" />
                        </SuiBox>
                        <SuiBox mr={3}>
                            {/*<SuiSocialButton color="twitter" circular iconOnly>*/}
                            {/*  <TwitterIcon />*/}
                            {/*</SuiSocialButton>*/}
                            <TwitterIcon fontSize="small" />
                        </SuiBox>
                        <SuiBox mr={3}>
                            {/*<SuiSocialButton color="instagram" circular iconOnly>*/}
                            {/*  <InstagramIcon />*/}
                            {/*</SuiSocialButton>*/}
                            <InstagramIcon fontSize="small" />
                        </SuiBox>
                        <SuiBox mr={3}>
                            {/*<SuiSocialButton color="pinterest" circular iconOnly>*/}
                            {/*  <PinterestIcon />*/}
                            {/*</SuiSocialButton>*/}
                            <PinterestIcon fontSize="small" />
                        </SuiBox>
                        <SuiBox>
                            {/*<SuiSocialButton color="linkedin" circular iconOnly>*/}
                            {/*  <LinkedInIcon />*/}
                            {/*</SuiSocialButton>*/}
                            <LinkedInIcon fontSize="small" />
                        </SuiBox>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} lg={10} sx={{textAlign: "center"}}>
                    <SuiTypography variant="body2" color="secondary">
                        {t("rights") + " " + date + " " + t("firm")}
                    </SuiTypography>
                </Grid>
            </Grid>
        </SuiBox>
    );
}

export default Footer;
