import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const getAccessToken = () => {
    const auth = sessionStorage.getItem("auth") ? JSON.parse(sessionStorage.getItem("auth")) : null;
    if (auth) {
        return auth.access_token;
    }
    return null;
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        preload: ["en", "tr"],
        saveMissing: true,
        saveMissingTo: "all",
        missingKeyHandler: (lng, ns, key) => {
            if (!key.startsWith("{{")) {
                console.log(`lng: ${lng}, ns: ${ns}, key: ${key}`);
                if (getAccessToken()) {
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", `Bearer ${getAccessToken()}`);
                    fetch(`${process.env.REACT_APP_API_URL}languageMessages/save`, {
                        method: "POST",
                        headers: myHeaders,
                        body: JSON.stringify({
                            languages: {
                                lang: lng[0],
                            },
                            key: key,
                            content: key,
                        }),
                    }).then((response) => {
                        console.log(response);
                    });
                }
            }
        },
        backend: {
            // customHeaders: {
            //     Authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : null,
            // },
            loadPath: `${process.env.REACT_APP_API_URL}languageMessages/locales/{{lng}}`,
            // addPath: `${process.env.REACT_APP_API_URL}languageMessages/locales/add/{{lng}}`,
            type: "backend",
            crossDomain: false,
            allowMultiLoading: true,
        },
        load: "languageOnly",
        // ns: ["translations"],
        // defaultNS: "translations",
        // fallbackLng: "tr",
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function (value, format, lng) {
                if (format === "uppercase") {
                    value = i18n.t(value);
                    return value.toUpperCase();
                }
                if (format === "lowercase") {
                    value = i18n.t(value);
                    return value.toLowerCase();
                }
                if (format === "capitalize") {
                    value = i18n.t(value);
                    return value
                        .split(" ")
                        .map((word) => {
                            return word.charAt(0).toLocaleUpperCase(i18n.language) + word.slice(1);
                        })
                        .join(" ");
                    // return value.charAt(0).toLocaleUpperCase(i18n.language) + value.slice(1);
                    // return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
                }
                if (format === "firstLetterCapitalize") {
                    value = i18n.t(value);
                    return value.charAt(0).toLocaleUpperCase(i18n.language) + value.slice(1);
                    // return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
                }
                return value;
            },
        },
    });

export default i18n;
