import React, {useState} from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    Toolbar,
    Item,
    ColumnChooser,
    LoadPanel,
    RequiredRule,
    Lookup,
    FilterRow,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import LangMessageService from "./LangMessageService";
import LangService from "../lang/LangService";
import {useTranslation} from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import i18next from "i18next";

const authProvider = AuthProvider();
const langMessageService = LangMessageService();
const langService = LangService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return langMessageService.findAll(loadOptions).then(
                (response) => ({
                    data: response.items,
                    totalCount: response.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return langMessageService.findOne(key).then((response) => {
                return response;
            });
        },
        insert: (values: any) => {
            return langMessageService.save(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        update: (key: any, values: any) => {
            values.id = key;
            return langMessageService.update(values).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        remove: (key: any) => {
            return langMessageService.deleteOne(key).then(
                () => {},
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
    }),
});

const langDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
        return langService.findAll(loadOptions).then(
            (response) => ({
                data: response.items,
                totalCount: response.totalCount,
            }),
            (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            }
        );
    },
    byKey: (key: any) => {
        return langService.findOne(key).then((response) => {
            return response;
        });
    },
});

function LangMessagePage() {
    const {t} = useTranslation();
    // i18next.format(t("key:settings"), "capitalize");
    const [dataGrid, setDataGrid] = useState();

    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("language") + " " + t("message") + " " + t("lists")}
                icon="language"
                fontSize="medium"
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    dataSource={dataSource}
                    showBorders={false}
                    remoteOperations={true}
                    rowAlternationEnabled={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}>
                    <FilterRow visible={true} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="searchPanel" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Editing
                        mode="cell"
                        allowUpdating={authProvider.isAuth("languageMessagesUpdate")}
                        allowAdding={authProvider.isAuth("languageMessagesSave")}
                        allowDeleting={false}
                    />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption=" "
                        dataField="id"
                        dataType="number"
                        visible={false}
                        sortOrder="desc"
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "language"})}
                        dataField="languages.id"
                        width={200}>
                        <Lookup dataSource={langDataSource} displayExpr="title" valueExpr="id" />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "key"})}
                        dataField="key"
                        dataType="string">
                        <RequiredRule />
                    </Column>

                    <Column
                        caption={t("{{key, capitalize}}", {key: "content"})}
                        dataField="content"
                        dataType="string">
                        <RequiredRule />
                    </Column>
                </DataGrid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default LangMessagePage;
