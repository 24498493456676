// Soft UI Dashboard PRO React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            marginTop: 0,
            marginBottom: 0,
            padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`,
        },
    },
};
