import AuthProvider from "../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "profile/";
function ProfileService() {
    const findOne = async () => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOne", requestOptions);
    };

    const update = (data) => {
        const file = data.avatar;
        const formData: FormData = new FormData();
        const blobData = new Blob([JSON.stringify(data)], {type: "application/json"});
        formData.append("data", blobData);
        if (file && file.name) {
            formData.append("photo", file, file.name);
        }
        const requestOptions = {
            method: "POST",
            body: formData,
        };
        return authProvider.authFetch(basePath + "update", requestOptions);
    };

    return {
        findOne,
        update,
    };
}

export default ProfileService;
