export const EReaderType: any = [
    {label: "ImpinjReader", value: "ImpinjReader"},
    {label: "KathreinReader", value: "KathreinReader"},
];

export const EMediaItemCountingState: any = [
    {label: "Başladı", value: "start"},
    {label: "Devam Ediyor", value: "continues"},
    {label: "Tamamlandı", value: "completed"},
];

export const EmediaItemCountingDetailState: any = [
    {label: "Bulunan", value: "found"},
    {label: "Bulunamayan", value: "notFound"},
    {label: "Bilinmeyen", value: "unknown"},
    {label: "Liste Dışı", value: "unlisted"},
];
