import AuthProvider from "./AuthProvider";

const currentPath = [];
function depthFirstTraversal(o, fn) {
    currentPath.push(o);
    if (o.collapse) {
        for (let i = 0, len = o.collapse.length; i < len; i++) {
            depthFirstTraversal(o.collapse[i], fn);
        }
    }
    fn.call(null, o, currentPath);
    currentPath.pop();
}

function shallowCopy(o) {
    const result = {};
    for (const k in o) {
        if (o.hasOwnProperty(k)) {
            result[k] = o[k];
        }
    }
    return result;
}

function copyNode(node) {
    const n = shallowCopy(node);
    if (n.collapse) {
        n.collapse = [];
    }
    return n;
}

function filterTree(root) {
    const authProvider = AuthProvider();
    root.copied = copyNode(root);
    const filteredResult = root.copied;
    depthFirstTraversal(root, function (node, branch) {
        if (authProvider.isAuth(node.authorities)) {
            for (let i = 0, len = branch.length; i < len; i++) {
                if (branch[i].copied) {
                    continue;
                }
                branch[i].copied = copyNode(branch[i]);
                branch[i - 1].copied.collapse.push(branch[i].copied);
            }
        }
    });

    depthFirstTraversal(root, function (node, branch) {
        delete node.copied;
    });

    return filteredResult;
}

export const filterTreeList = (list) => {
    const authProvider = AuthProvider();
    if (authProvider.getAuthData() == null) {
        return list;
    }
    const filteredList = [];
    for (let i = 0, len = list.length; i < len; i++) {
        if (
            list[i].authorities == null ||
            list[i].authorities.length === 0 ||
            authProvider.isAuth(list[i].authorities)
        ) {
            filteredList.push(filterTree(list[i]));
        }
    }
    return filteredList;
};

export const shallowClone = (data) => {
    return shallowCopy(data);
};
