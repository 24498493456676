// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

const {borderRadius} = borders;
const {xxl} = boxShadows;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        paper: {
            borderRadius: borderRadius.lg,
            boxShadow: xxl,
        },

        paperFullScreen: {
            borderRadius: 0,
        },
    },
};
