export const mergeEquallyLabeledTypes = (item, parents) => {
    if (item.name) {
        parents.push(item.name);
    }
    if (item.parent) {
        mergeEquallyLabeledTypes(item.parent, parents);
    }
    return parents.join(" / ");
};

export const getFileNameFromResponseContentDisposition = (res: Response) => {
    const contentDisposition = res.headers.get("content-disposition") || "";
    const matches = /filename=([^;]+)/gi.exec(contentDisposition);
    return (matches[1] || "untitled").trim();
};

export const formatDate = (date, lang) => {
    let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (lang === "tr") return [day, month, year].join("-");
    else return [year, month, day].join("-");
};

export const betweenDate = (date) => {
    const today = new Date();
    const diffMs = today - date; // milliseconds between now & Date
    // switch (format) {
    //     case "days":
    //         return Math.floor(diffMs / 86400000);
    //     case "hours":
    //         return Math.floor((diffMs % 86400000) / 3600000);
    //     case "minutes":
    //         return Math.round(((diffMs % 86400000) % 3600000) / 60000);
    //     default:
    //         return Math.floor(diffMs / 86400000);
    // }
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minu
    return {
        days: diffDays,
        hours: diffHrs,
        minutes: diffMins,
    };
    // diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes";
};
