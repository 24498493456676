// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Soft UI Dashboard PRO React helper functions
import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";

const {white} = colors;
const {borderRadius} = borders;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            width: pxToRem(250),
            whiteSpace: "nowrap",
            border: "none",
        },

        paper: {
            width: pxToRem(250),
            backgroundColor: rgba(white.main, 0.8),
            backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
            height: `calc(100vh - ${pxToRem(32)})`,
            margin: pxToRem(16),
            borderRadius: borderRadius.xl,
            border: "none",
        },

        paperAnchorDockedLeft: {
            borderRight: "none",
        },
    },
};
