import React from "react";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import ko from "knockout";
import "devexpress-reporting/dx-reportdesigner";
import "devexpress-reporting/dx-webdocumentviewer";
import {ajaxSetup} from "@devexpress/analytics-core/analytics-utils";
import "devextreme/dist/css/dx.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";
import themes from "devextreme/ui/themes";
import i18next from "../../../../i18n";
import {getLangLocalStorage} from "../../../../providers/LanguageProvider";
import messagesReportingTR from "../../../../assets/i18n/devextreme/dx-reporting.tr.json";
import messagesAnalyticsTR from "../../../../assets/i18n/devextreme/dx-analytics-core.tr.json";
import messagesAnalyticsEN from "../../../../assets/i18n/devextreme/dx-analytics-core.en.json";
import messagesReportingEN from "../../../../assets/i18n/devextreme/dx-reporting.en.json";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
const authProvider = AuthProvider();

class ReportDesignerPage extends React.Component {
    constructor(props) {
        super(props);
        this.divRef = React.createRef();
        this.requestOptions = {
            host: process.env.REACT_APP_REPORT_URL,
            getDesignerModelAction: "api/ReportDesigner/GetReportDesignerModel",
        };

        ajaxSetup.ajaxSettings = {
            headers: {
                ProjectUrl: process.env.REACT_APP_API_URL,
                Authorization: "Bearer " + authProvider.getAuthData().access_token,
            },
        };
    }

    render() {
        themes.current("generic.light");
        const style = {
            height: "calc(100vh - 130px)",
        };
        return (
            <DashboardLayout>
                <DashboardNavbar
                    title={i18next.t("report") + " " + i18next.t("designer")}
                    icon="assessment"
                    fontSize="medium"
                />
                <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                    <div
                        style={style}
                        ref={(ref) => {
                            this.divRef = ref;
                        }}
                        data-bind="dxReportDesigner: $data"
                    />
                </SuiBox>
                <Footer />
            </DashboardLayout>
        );
    }

    componentDidMount() {
        ko.applyBindings(
            {
                reportUrl: "",
                requestOptions: this.requestOptions,
                callbacks: {
                    CustomizeLocalization: async (s, e) => {
                        const lang = await getLangLocalStorage();
                        //console.log("CustomizeLocalization", lang);
                        if (lang === "tr") {
                            e.LoadMessages(messagesReportingTR);
                            e.LoadMessages(messagesAnalyticsTR);
                        } else {
                            e.LoadMessages(messagesAnalyticsEN);
                            e.LoadMessages(messagesReportingEN);
                        }
                    },
                },
            },
            this.divRef
        );
    }
    componentWillUnmount() {
        ko.cleanNode(this.divRef);
        themes.current("material.blue.light");
    }
}

export default ReportDesignerPage;
