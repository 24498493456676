/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// @mui Divider API
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {NavLink} from "react-router-dom";
import avatar from "../../../assets/images/faces/logo.png";
import React from "react";
// import {color} from "@mui/system";
// import {light} from "@mui/material/styles/createPalette";

function ControllerCard({icon, title, description, to}) {
    return (
        <Card
            sx={{
                height: "100%",
                width: "100%",
                cursor: "pointer",
                borderRadius: "5px",
                backgroundColor: "light.main",
                "&:hover": {
                    transform: "scale(1.1)",
                    transition: "0.5s ease",
                    backgroundColor: "librefDashboard.main",
                    zIndex: "10",
                },
            }}>
            <NavLink to={to}>
                <SuiBox p={3} height="100%">
                    <SuiBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        lineHeight={1}>
                        <SuiBox width="100%" display="flex"></SuiBox>
                        <SuiBox mr={1}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="27"
                                viewBox="0 0 27 27">
                                <g transform="translate(-9 -9)">
                                    <path
                                        d="M10.5,36a1.5,1.5,0,0,1-1.061-2.561l24-24a1.5,1.5,0,0,1,2.121,2.121l-24,24A1.5,1.5,0,0,1,10.5,36Z"
                                        transform="translate(0 0)"
                                        fill="#000000"
                                    />
                                    <path
                                        d="M34.5,36A1.5,1.5,0,0,1,33,34.5V12H10.5a1.5,1.5,0,0,1,0-3h24A1.5,1.5,0,0,1,36,10.5v24A1.5,1.5,0,0,1,34.5,36Z"
                                        transform="translate(0 0)"
                                        fill="#000000"
                                    />
                                </g>
                            </svg>
                        </SuiBox>
                    </SuiBox>
                    <SuiBox height="100px">
                        <img
                            width="70"
                            src={
                                `${process.env.REACT_APP_API_URL}binaryContents/getPublicImage/` +
                                icon
                            }
                            alt="Person"
                        />
                    </SuiBox>
                    <SuiBox>
                        <Divider variant="inset" />
                    </SuiBox>
                    <SuiBox mt={0.3} mb={1} lineHeight={1}>
                        <SuiTypography
                            sx={{color: "black.main"}}
                            variant="h5"
                            textTransform="capitalize"
                            alignItems="left"
                            fontWeight="bold">
                            {title}
                        </SuiTypography>
                        <SuiTypography sx={{color: "black.main"}} variant="body2">
                            {description}
                        </SuiTypography>
                    </SuiBox>
                </SuiBox>
            </NavLink>
        </Card>
    );
}

// Setting default values for the props of ControllerCard
ControllerCard.defaultProps = {
    color: "librefLogin",
    description: "",
};

// Typechecking props for the ControllerCard
ControllerCard.propTypes = {
    // color: PropTypes.oneOf([
    //     "primary",
    //     "secondary",
    //     "info",
    //     "success",
    //     "warning",
    //     "error",
    //     "dark",
    //     "librefLogin",
    //     "white.main",
    // ]),
    // state: PropTypes.bool,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    // onChange: PropTypes.func.isRequired,
};

export default ControllerCard;
