import AuthProvider from "./AuthProvider";
import {getLangLocalStorage} from "./LanguageProvider";

const authProvider = AuthProvider();

let ws: WebSocket;
export const initWebsocket = (code, uri = "signal") => {
    closeWebSocket();
    const baseUrl = process.env.REACT_APP_API_URL.replace("http", "ws").replace(
        "/api/",
        `/${uri}/` + code
    );
    const url = baseUrl + "?access_token=" + authProvider.getAuthData().access_token;
    ws = new WebSocket(url);
    ws.onopen = () => {
        onMessage();
    };
    ws.onclose = (e) => {
        if (e.code !== 4001) {
            setTimeout(() => {
                initWebsocket(code);
            }, 5000);
            window.dispatchEvent(
                new CustomEvent("socket", {
                    detail: {
                        code: "close",
                    },
                })
            );
        }
    };
};

const onMessage = () => {
    ws.onmessage = (event) => {
        const message = event.data;
        const d = JSON.parse(message);
        window.dispatchEvent(new CustomEvent("socket", {detail: d}));
    };
};
export const closeWebSocket = () => {
    if (ws) {
        ws.close(4001, "app_custom");
    }
};

export const sendWebSocketMessage = (msg) => {
    if (ws && ws.readyState === 1) {
        getLangLocalStorage().then((language) => {
            msg.lang = language;
            ws.send(JSON.stringify(msg));
        });
    }
};
