// Soft UI Dashboard PRO React Base Styles
import colors from "assets/theme/base/colors";

const {transparent} = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            "&:hover": {
                backgroundColor: transparent.main,
            },
        },
    },
};
