import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {CardActions, CardHeader} from "@mui/material";
import controlPointImage from "../../../../assets/images/controlPoint.png";
import {red} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import DeviceService from "../../device/DeviceService";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import {LoadPanel} from "devextreme-react";
import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    Item,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import Button from "devextreme-react/button";
import {
    closeWebSocket,
    initWebsocket,
    sendWebSocketMessage,
} from "../../../../providers/WebSocketProvider";
import SuiSnackbar from "../../../../components/SuiSnackbar";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import Avatar from "@mui/material/Avatar";
import GateService from "./GateService";
import colors from "../../../../assets/theme/base/colors";
import Footer from "../../../../examples/Footer";

const deviceService = DeviceService();

const gateService = GateService();

const dataSource = new DataSource({
    store: new CustomStore({
        key: "id",
        load(loadOptions: any) {
            return deviceService.findAllBy(loadOptions).then(
                (data) => ({
                    data: data.items,
                    totalCount: data.totalCount,
                }),
                (err) => {
                    return err.json().then((r) => {
                        throw r.errorMessage ? r.errorMessage : r.warningMessage;
                    });
                }
            );
        },
        byKey: (key: any) => {
            return deviceService.findOne(key).then((response) => {
                return response;
            });
        },
    }),
    filter: ["product.id", "=", 2],
});

let timeOutId;

let readDataArray = [];

// let fixture = {};

function ControlPointPage() {
    const {t} = useTranslation();
    const [gridData, setGridData] = useState();
    const [deviceData, setDeviceData] = useState();
    const deviceDataRef = useRef();
    const [dataGrid, setDataGrid] = useState();
    let dataGridFilter;
    const [showModal, setShowModal] = useState(false);
    const [readData, setReadData] = useState();

    const [showLoadPanel, setShowLoadPanel] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
    const [snackbarContent, setSnackbarContent] = useState("");

    const [enterPerson, setEnterPerson] = useState(0);
    const [exitPerson, setExitPerson] = useState(0);

    const [forbiddenCount, setForbiddenCount] = useState(0);
    const [noForbiddenCount, setNoForbiddenCount] = useState(0);

    const returnFunction = (e) => {
        const data = e.detail;
        switch (data.command) {
            case "open":
                data.command = "offer";
                setShowLoadPanel(true);

                sendWebSocketMessage(data);
                if (timeOutId) {
                    clearTimeout(timeOutId);
                }
                timeOutId = setTimeout(() => {
                    setSnackbarContent(t("sure.device.turn.on"));
                    setShowSnackbar(true);
                    closeWebSocket();
                    setShowLoadPanel(false);
                }, 5000);
                return;
            case "answer":
                data.command = "read";
                data.data = deviceDataRef.current;
                readDataArray = [];
                sendWebSocketMessage(data);
                setTimeout(() => {
                    setShowModal(true);
                }, 100);
                setShowLoadPanel(false);
                gateService.sendSta(data.data.id).then();
                break;
            case "visitor":
                const enterAllCount = data.data.filter((f) => f.direction === "enter");
                const exitAllCount = data.data.filter((f) => f.direction === "exit");
                if (enterAllCount && enterAllCount.length > 0) {
                    const enterCount = enterAllCount[0].visitorCount;
                    setEnterPerson(enterCount);
                }
                if (exitAllCount && exitAllCount.length > 0) {
                    const exitCount = exitAllCount[0].visitorCount;
                    setExitPerson(exitCount);
                }
                return;
            case "controlPoint":
                const forbiddenControlPointCount = data.data.filter((f) => f.forbiddenPass);
                const notForbiddenControlPointCount = data.data.filter((f) => !f.forbiddenPass);

                if (forbiddenControlPointCount && forbiddenControlPointCount.length > 0) {
                    const forbiddenCount = forbiddenControlPointCount[0].controlPointCount;
                    setForbiddenCount(forbiddenCount);
                }
                if (notForbiddenControlPointCount && notForbiddenControlPointCount.length > 0) {
                    const noForbiddenCount = notForbiddenControlPointCount[0].controlPointCount;
                    setNoForbiddenCount(noForbiddenCount);
                }
                return;
            case "read":
                let gateItems = data.data;
                if (gateItems.length === 0) {
                    return;
                }
                if (readDataArray && readDataArray.length > 0 && readDataArray.length < 100) {
                    readDataArray = [...readDataArray, gateItems];
                } else if (readDataArray && readDataArray.length === 100) {
                    readDataArray = [...readDataArray.slice(1, 100), gateItems];
                } else {
                    readDataArray = [gateItems];
                }
                setReadData(uniqueArray(readDataArray));

                return;
            case "error":
                setShowLoadPanel(false);
                setShowSnackbar(true);
                setSnackbarContent(t(data.data));
                break;
            default:
        }
        if (timeOutId) {
            clearTimeout(timeOutId);
        }
    };

    const uniqueArray = (data) => {
        return data.reduce((a, d) => {
            const items = a.filter((f) => f.itemIdentifier === d.itemIdentifier);
            // console.log("uniqueArray", a, d, items);
            if (items.length === 0) {
                a.push({
                    itemIdentifier: d.itemIdentifier,
                    antennaPort: d.antennaPort,
                    callNumber: d.callNumber,
                    circulationStatus: d.circulationStatus,
                    currentLocation: d.currentLocation,
                    decode: d.decode,
                    deviceId: d.deviceId,
                    dueDate: d.dueDate,
                    epc: d.epc,
                    pcBitHex: d.pcBitHex,
                    pcBits: d.pcBits,
                    permanentLocation: d.permanentLocation,
                    rssi: d.rssi,
                    serialNumber: d.serialNumber,
                    timeStamp: d.timeStamp,
                    titleIdentifier: d.titleIdentifier,
                    userMemory: d.userMemory,
                    passDate: d.passDate,
                    forbiddenPass: d.forbiddenPass,
                });
            } else {
                const item = items[0];
                item.passDate = d.passDate;
                item.forbiddenPass = d.forbiddenPass;
            }
            return a;
        }, []);
    };

    useEffect(() => {
        window.addEventListener("socket", returnFunction);
        return () => {
            closeWebSocket();
            window.removeEventListener("socket", returnFunction);
            if (timeOutId) {
                clearTimeout(timeOutId);
            }
        };
    }, []);
    const {light, dark} = colors;
    return (
        <DashboardLayout>
            <DashboardNavbar
                title={t("device") + " " + t("lists")}
                icon="devices"
                fontSize="medium"
                onChangeSearch={(e) => {
                    dataGrid.instance.searchByText(e);
                }}
            />
            <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
                <DataGrid
                    className="hidePanelDataGrid"
                    dataSource={dataSource}
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    remoteOperations={true}
                    ref={(ref) => {
                        setDataGrid(ref);
                    }}
                    onContentReady={(e) => {
                        setGridData(e.component.getDataSource().items());
                    }}>
                    <FilterRow visible={false} />
                    <ColumnChooser enabled={false} />
                    <LoadPanel enabled={true} />
                    <Toolbar>
                        <Item location="after">
                            <Button
                                icon="refresh"
                                onClick={() => {
                                    dataGrid.instance.refresh();
                                }}
                            />
                        </Item>
                        <Item name="addRowButton" />
                        <Item name="columnChooserButton" />
                    </Toolbar>
                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                    <Paging defaultPageSize={20} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                    />

                    <Column
                        caption={t("{{key, capitalize}}", {key: "name"})}
                        dataField="name"
                        dataType="string"
                        sortOrder="asc"
                    />
                </DataGrid>

                <Grid container mt={2} spacing={0} bgcolor="transparent">
                    {gridData &&
                        gridData.map((val) => {
                            return (
                                <Grid
                                    component={Card}
                                    item
                                    key={val.id}
                                    ml={1}
                                    mr={2}
                                    mb={1}
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={3}>
                                    <Card
                                        sx={{
                                            width: "100%",
                                            cursor: "pointer",
                                            bgcolor: "#f2f2f2",
                                        }}
                                        onClick={() => {
                                            timeOutId = setTimeout(() => {
                                                setSnackbarContent(t("sure.device.turn.on"));
                                                setShowSnackbar(true);
                                                setShowLoadPanel(false);
                                            }, 5000);

                                            setShowLoadPanel(true);
                                            setDeviceData(val);
                                            deviceDataRef.current = val;
                                            initWebsocket(val.id);
                                        }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    sx={{bgcolor: dark.main}}
                                                    aria-label="recipe">
                                                    {t("{{key, capitalize}}", {
                                                        key: "control.point.cp",
                                                    })}
                                                </Avatar>
                                            }
                                            title={<b style={{fontSize: "20px"}}>{val.name}</b>}
                                            // subheader="September 14, 2016"
                                        />
                                        <CardMedia
                                            sx={{padding: 2}}
                                            component="img"
                                            width="100%"
                                            image={
                                                process.env.REACT_APP_API_URL +
                                                "binaryContents/getPublicImage/" +
                                                val.product.contentId
                                            }
                                            alt="thumbnail"
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                style={{textAlign: "center", marginTop: "15px"}}>
                                                {val.processor.department.name}
                                            </Typography>
                                        </CardContent>
                                        <CardActions
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    color:
                                                        val.deviceStatus === "idle"
                                                            ? "green"
                                                            : "red",
                                                }}
                                                component="div">
                                                {t("{{key, capitalize}}", {key: val.deviceStatus})}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>

                <LoadPanel
                    visible={showLoadPanel}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={true}
                />
                <SuiSnackbar
                    sx={{maxWidth: 400}}
                    color="error"
                    icon="error"
                    title={t("{{key, capitalize}}", {key: "error"})}
                    content={snackbarContent}
                    dateTime=""
                    autoHideDuration={10000}
                    open={showSnackbar}
                    onClose={toggleSnackbar}
                    close={toggleSnackbar}
                />
            </SuiBox>

            <Popup
                visible={showModal}
                showCloseButton={true}
                showTitle={true}
                title={
                    deviceData
                        ? deviceData.name
                        : t("{{key, capitalize}}", {key: "fixture.information"})
                }
                closeOnOutsideClick={false}
                fullScreen={true}
                onHiding={(e) => {
                    dataGridFilter.instance.clearFilter();
                    setShowModal(false);
                    readDataArray = [];
                    setReadData(readDataArray);
                }}>
                <ScrollView width="100%" height="100%">
                    <DataGrid
                        dataSource={readData}
                        allowSearch={false}
                        allowFiltering={false}
                        allowSorting={true}
                        remoteOperations={false}
                        rowAlternationEnabled={false}
                        ref={(ref) => {
                            dataGridFilter = ref;
                        }}
                        onRowPrepared={(rowInfo) => {
                            if (rowInfo.rowType === "data") {
                                if (rowInfo.data.forbiddenPass === true) {
                                    rowInfo.rowElement.bgColor = "#ffe6e6";
                                } else {
                                    rowInfo.rowElement.bgColor = "#D9F8C4";
                                }
                            }
                        }}>
                        <FilterRow visible={true} />
                        <ColumnChooser enabled={false} />
                        <LoadPanel enabled={true} />
                        <Toolbar>
                            <Item location="before">
                                <div
                                    className="informer"
                                    style={{backgroundColor: "green", color: "white"}}>
                                    <span className="name">
                                        {t("{{key, capitalize}}", {key: "no.forbidden.count"})}
                                    </span>
                                    <h2 className="count">{noForbiddenCount}</h2>
                                </div>
                            </Item>
                            <Item location="before">
                                <div
                                    className="informer"
                                    style={{backgroundColor: "red", color: "white"}}>
                                    <span className="name">
                                        {t("{{key, capitalize}}", {key: "forbidden.count"})}
                                    </span>
                                    <h2 className="count">{forbiddenCount}</h2>
                                </div>
                            </Item>

                            <Item location="after">
                                <div className="informer">
                                    <span className="name">
                                        {t("{{key, capitalize}}", {key: "visitor.enter.count"})}
                                    </span>
                                    <h2 className="count">{enterPerson}</h2>
                                </div>
                            </Item>
                            <Item location="after">
                                <div className="informer">
                                    <span className="name">
                                        {t("{{key, capitalize}}", {key: "visitor.exit.count"})}
                                    </span>
                                    <h2 className="count">{exitPerson}</h2>
                                </div>
                            </Item>
                        </Toolbar>
                        <SearchPanel visible={false} highlightCaseSensitive={true} width={240} />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                        />

                        <Column
                            caption={t("{{key, capitalize}}", {key: "transaction.date"})}
                            dataField="passDate"
                            dataType="date"
                            format={"dd.MM.yyyy HH:mm:ss"}
                            width={200}
                            allowSearch={false}
                            allowFiltering={false}
                            allowSorting={false}
                            sortOrder="desc"
                        />

                        <Column
                            caption={t("{{key, capitalize}}", {key: "fixture.number"})}
                            dataField="itemIdentifier"
                            dataType="string"
                            width={200}
                        />

                        <Column
                            visible={false}
                            // sortOrder="desc"
                            dataField="forbiddenPass"
                            dataType="boolean"
                            sortIndex={0}
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "book.name"})}
                            dataField="titleIdentifier"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "permanent.location"})}
                            dataField="permanentLocation"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "current.location"})}
                            dataField="currentLocation"
                            dataType="string"
                        />
                        <Column
                            caption={t("{{key, capitalize}}", {key: "call.number"})}
                            dataField="callNumber"
                            dataType="string"
                        />
                    </DataGrid>
                </ScrollView>
            </Popup>
            <Footer />
        </DashboardLayout>
    );
}

export default ControlPointPage;
