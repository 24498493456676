// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {borderRadius} = borders;
const {light} = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            height: pxToRem(3),
            borderRadius: borderRadius.md,
            overflow: "visible",
            position: "relative",
        },

        colorPrimary: {
            backgroundColor: light.main,
        },

        colorSecondary: {
            backgroundColor: light.main,
        },

        bar: {
            height: pxToRem(6),
            borderRadius: borderRadius.sm,
            position: "absolute",
            transform: `translate(0, ${pxToRem(-1.5)}) !important`,
            transition: "width 0.6s ease !important",
        },
    },
};
