// Soft UI Dashboard PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {
        baseClassName: "material-icons-round",
        fontSize: "inherit",
    },

    styleOverrides: {
        fontSizeInherit: {
            fontSize: "inherit !important",
        },

        fontSizeSmall: {
            fontSize: `${pxToRem(20)} !important`,
        },

        fontSizeLarge: {
            fontSize: `${pxToRem(36)} !important`,
        },
    },
};
