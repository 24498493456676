// eslint-disable-next-line import/no-anonymous-default-export
export default {
    values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
    },
};
