import React from "react";
import {BrowserRouter} from "react-router-dom";
// import { createRoot, hydrateRoot } from "react-dom/client";
// import * as ReactDOMClient from "react-dom/client";
import {render} from "react-dom";
import App from "App";

import "./i18n";

// Soft UI Context Provider
import {SoftUIControllerProvider} from "context";

// @mui Picker
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
// Moment Date Management Library
import MomentUtils from "@date-io/moment";
import Root from "assets/theme/components/button/root";

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
}

const container = document.getElementById("root");
render(
    <React.StrictMode>
        <BrowserRouter>
            <SoftUIControllerProvider>
                <App />
            </SoftUIControllerProvider>
        </BrowserRouter>
    </React.StrictMode>,
    container
);

// function AppPicker() {
//     return (
//         <MuiPickersUtilsProvider utils={MomentUtils}>
//             <Root />
//         </MuiPickersUtilsProvider>
//     );
// }

// render(<AppPicker />, document.querySelector("#app"));

// render(
//   <BrowserRouter>
//     <SoftUIControllerProvider>
//       <App />
//     </SoftUIControllerProvider>
//   </BrowserRouter>,
//   container
// );

// const root = hydrateRoot(
//   container,
//   <BrowserRouter>
//     <SoftUIControllerProvider>
//       <App />
//     </SoftUIControllerProvider>
//   </BrowserRouter>
// );

// const root = ReactDOMClient.createRoot(container);
//
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <SoftUIControllerProvider>
//         <App />
//       </SoftUIControllerProvider>
//     </BrowserRouter>
//   </React.StrictMode>
// );
