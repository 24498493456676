import AuthProvider from "../../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "report/";
function ReportListService() {
    const findOne = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
    };

    const findAll = async (loadOptions) => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(loadOptions),
        };
        return authProvider.authFetch(basePath + "findAll", requestOptions);
    };

    return {
        findOne,
        findAll,
    };
}

export default ReportListService;
