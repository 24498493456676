import AuthProvider from "../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "sta/";
function StaService() {
    const result = async (tableName) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "result/" + tableName, requestOptions);
    };
    return {
        result,
    };
}

export default StaService;
