// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {
        disableGutters: true,
    },

    styleOverrides: {
        root: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
};
