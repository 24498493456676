/* eslint-disable no-dupe-keys */
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

function configs(color, labels, datasets) {
    return {
        data: {
            labels,
            datasets: [
                {
                    label: datasets.label,
                    tension: 0.4,
                    borderWidth: 0,
                    borderRadius: 4,
                    borderSkipped: false,
                    backgroundColor: colors[color] ? colors[color].main : colors.dark.main,
                    data: datasets.data,
                    maxBarThickness: 8,
                },
            ],
        },

        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
            scales: {
                // y: {
                //   grid: {
                //     drawBorder: false,
                //     display: false,
                //     drawOnChartArea: false,
                //     drawTicks: false,
                //   },
                //   ticks: {
                //     display: false,
                //   },
                // },
                // x: {
                //   grid: {
                //     drawBorder: false,
                //     display: false,
                //     drawOnChartArea: false,
                //     drawTicks: false,
                //   },
                //   ticks: {
                //     beginAtZero: true,
                //     font: {
                //       size: 12,
                //       family: "Open Sans",
                //       style: "normal",
                //     },
                //     color: "#9ca2b7",
                //   },
                // },
                y: {
                    grid: {
                        drawBorder: false,
                        display: false,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        padding: 10,
                        color: "#9ca2b7",
                    },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        padding: 10,
                        color: "#0A2342",
                    },
                },
            },
        },
    };
}

export default configs;
